import { SyntheticEvent, useContext, useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Form,
  Input,
  message,
  Select,
  Modal,
  InputNumber,
  Spin,
  Checkbox,
  Upload,
  Radio,
  Progress,
  Menu,
  Dropdown,
  Alert,
  Popconfirm,
} from "antd";

import type { PopconfirmProps } from "antd";

import { Link, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import {
  Car,
  requestCarById,
  requestCreateCar,
  requestUpdateAllById,
  requestUpdateById,
  requestUpdateRepeatedCaseById,
  requestUpdateRootCauseById,
  requestUpdateWorkflowById,
  requestWorkflowUpdateById,
} from "../../../hooks/car";
import UserContext from "../../../contexts/UserContext";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Product, requestProducts } from "../../../hooks/product";
import {
  Customer,
  requestCustomerByCustomerCode,
  requestCustomers,
} from "../../../hooks/customer";
import { Orggroup, requestOrggroups } from "../../../hooks/orggroup";
import { Division, requestDivisions } from "../../../hooks/division";
import { Department, requestDepartments } from "../../../hooks/department";
import {
  Employee,
  requestEmployeeByEmployeeId,
  requestEmployees,
} from "../../../hooks/employee";
import { Company, requestCompanys } from "../../../hooks/company";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CheckCircleTwoTone from "@ant-design/icons/lib/icons/CheckCircleTwoTone";
import CarContext from "../../../contexts/CarContext";
import { Carstandard, requestCarstandards } from "../../../hooks/carstandard";
import { Cartype, requestCartypes } from "../../../hooks/cartype";
import {
  Divisiondirector,
  requestDivisiondirectors,
} from "../../../hooks/divisiondirector";
import { Country, requestCountrys } from "../../../hooks/country";
import { OptionProps } from "antd/lib/select";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined";
import { Carcategory, requestCarcategorys } from "../../../hooks/carcategory";
import {
  Customercontact,
  requestCustomercontactByCustomerId,
  requestCustomercontactByName,
  requestCustomercontacts,
} from "../../../hooks/customercontact";
import {
  Customerclass,
  requestCustClasses,
} from "../../../hooks/customerclass";
import {
  Carproblemtype,
  requestCarproblemtypes,
} from "../../../hooks/carproblemtype";
import { Currency, requestCurrencys } from "../../../hooks/currency";
import { requestSuppliers, Supplier } from "../../../hooks/supplier";
import {
  requestSupplierclasses,
  Supplierclass,
} from "../../../hooks/supplierclass";
import "./AddEdit.css";
import { Docstatus, requestDocStatuss } from "../../../hooks/docstatus";
import {
  requestWorkflowstatus,
  Workflowstatus,
} from "../../../hooks/workflowstatus";
import {
  Approverejectstatus,
  requestApproverejectstatus,
} from "../../../hooks/approverejectstatus";
import { requestVerifystatus, Verifystatus } from "../../../hooks/verifystatus";
import {
  Drive,
  requestDeleteFileByFileId,
  requestUploadFile,
} from "../../../hooks/drive";
import DriveList from "../../../components/DriveList";
import axios from "axios";
import DriveUpload from "../../../components/DriveUpload";
import {
  requestCustsatGetSurveyLines,
  SurveyLineResponse,
} from "../../../hooks/custsat";
import SurveyLineList from "../../../components/SurveyLineList";

import { CopyToClipboard } from "react-copy-to-clipboard";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Nctype, requestNctypes } from "../../../hooks/nctype";
import { Ncsubtype, requestNcsubtypes } from "../../../hooks/ncsubtype";
import { requestRootcauses, Rootcause } from "../../../hooks/rootcause";

const { REACT_APP_DRIVE_NAME } = process.env;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Bangkok");

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const JustCheckboxGroup = Checkbox.Group;
const StandardCheckboxGroup = Checkbox.Group;

const standardplainOptions = ["ISO9001", "ISO14001", "OHSAS18001", "ISO27001"];
const justplainOptions = [
  "Photograph",
  "Sampling",
  "Site visiting report",
  "Survey report",
  "Other",
];

//work flow style
let S1Style = {};
let S2Style = {};
let S3Style = {};
let S4Style = {};
let S5Style = {};
let S6Style = {};
let S7Style = {};
let S8Style = {};
let S9Style = {};

const styleReject = {
  color: "white",
  backgroundColor: "#FF6666",
};

interface DomainsArticlesAddEditParams {
  id?: string;
  wf_status_id?: string;
}

const DomainsCarsAddEdit = () => {
  const history = useHistory();

  //copy

  //end copy

  const [headerColor1, setHeaderColor1] = useState("#B4C6E7");
  const [headerColor2, setHeaderColor2] = useState("#FFD966");

  const { id, wf_status_id }: DomainsArticlesAddEditParams = useParams();
  const [closed, SetClosed] = useState(false);

  const [disableId, SetDisableId] = useState(false);
  const [disableFix, SetDisableFix] = useState(false);
  const [updateAll, SetUpdateAll] = useState(false);

  const [wf_status_idParm, setWf_status_idParm] = useState(wf_status_id);
  const [repeatedCase, setRepeatedCase] = useState(0);
  const [ncTypeName, setNcTypeName] = useState("");
  const [ncsubTypeName, setNcsubTypeName] = useState("");
  const [rootCauseName, setRootCauseName] = useState("");
  //Workflow Show
  const [wfApproveReject, SetWfApproveReject] = useState(0);
  const [submitAgain, SetSubmitAgain] = useState(0);
  const [carCategoryId, SetCarCategoryId] = useState("");
  const [issuerDeptMgrApprove, SetIssuerDeptMgrApprove] = useState(0);
  const [issuerDivMgrApprove, SetIssuerDivMgrApprove] = useState(0);

  const { accessToken, user, setDrives, drives, emp } = useContext(UserContext);
  const { needLoadCars, setNeedLoadCars } = useContext(CarContext);
  const [formCreateEdit] = Form.useForm(); //react hook form

  const [approveButtonName, SetApproveButtonName] = useState("Approve");
  //diable state
  const [AddEditStatus, setAddEditStatus] = useState(false);
  const [cardocid, setCarDocId] = useState("");

  //update workflow
  const [workflowStatusIdUpdate, setWorkflowStatusIdUpdate] = useState("");

  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [visibleUpdateWorkflowSuccess, setVisibleUpdateWorkflowSuccess] =
    useState(false);
  const [visibleUpdateRepeatedCase, setVisibleUpdateRepeatedCase] =
    useState(false);
  const [visibleUpdateRootCause, setVisibleUpdateRootCause] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={() => setVisibleUpdateWorkflowSuccess(true)}
        >
          Update Workflow
        </a>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="1">
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={() => setVisibleUpdateRepeatedCase(true)}
        >
          Update Repeated Case
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={() => setVisibleUpdateRootCause(true)}
        >
          Root Cause Analysis by QMR
        </a>
      </Menu.Item>

      {/* <Menu.Item key="1">
            <a  rel="noopener noreferrer" href="#">
            
            </a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="3" disabled>
            3rd menu item（disabled）
          </Menu.Item> */}
    </Menu>
  );
  const [newId, setNewId] = useState(0);
  const [cardocnewId, setCarDocNewId] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionName, setActionName] = useState("");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmLoadingWorkflowStatus, setConfirmLoadingWorkflowStatus] =
    useState(false);
  const [
    confirmLoadingRepeatedCaseStatus,
    setConfirmLoadingRepeatedCaseStatus,
  ] = useState(false);
  const [confirmLoadingRootCauseStatus, setConfirmLoadingRootCauseStatus] =
    useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [msgAlert, setMsgAlert] = useState("");
  const [modalText, setModalText] = useState("Content of the modal");

  //workflow

  const [wfStatusId, SetWkStatusId] = useState("");
  const [wfStatuses, setWkStatuses] = useState<Workflowstatus[] | undefined>(
    undefined
  );
  const [wfStatusesSelectedValue, setWkStatusesSelectedValue] = useState<
    Workflowstatus | undefined
  >(undefined);
  //Issuer

  const [docStatus, setDocStatus] = useState<Docstatus[] | undefined>(
    undefined
  );
  const [docStatusSelectedValue, setDocStatusSelectedValue] = useState<
    Docstatus | undefined
  >(undefined);

  const [carstandards, setCarstandards] = useState<Carstandard[] | undefined>(
    undefined
  );
  const [carstandardsSelectedValue, setCarstandardsSelectedValue] = useState<
    Carstandard | undefined
  >(undefined);
  const [carcategorys, setCarcategorys] = useState<Carcategory[] | undefined>(
    undefined
  );
  const [carcategorysSelectedValue, setCarcategorysSelectedValue] = useState<
    Carcategory | undefined
  >(undefined);

  const [cartypes, setCartypes] = useState<Cartype[] | undefined>(undefined);
  const [cartypesSelectedValue, setCartypesSelectedValue] = useState<
    Cartype | undefined
  >(undefined);
  const [issuers, setIssuers] = useState<Employee[] | undefined>(undefined);
  const [issuersSelectedValue, setIssuersSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [issuerCompanys, setIssuerCompanys] = useState<Company[] | undefined>(
    undefined
  );
  const [issuerCompanysSelectedValue, setIssuerCompanysSelectedValue] =
    useState<Company | undefined>(undefined);
  const [issuerDivisionDirectors, setIssuerDivisionDirectors] = useState<
    Divisiondirector[] | undefined
  >(undefined);
  const [
    issuerDivisionDirectorsSelectedValue,
    setIssuerDivisionDirectorsSelectedValue,
  ] = useState<Divisiondirector | undefined>(undefined);
  const [issuerDivisions, setIssuerDivisions] = useState<
    Division[] | undefined
  >(undefined);
  const [issuerDivisionsSelectedValue, setIssuerDivisionsSelectedValue] =
    useState<Division | undefined>(undefined);
  const [issuerDepartments, setIssuerDepartments] = useState<
    Department[] | undefined
  >(undefined);
  const [issuerDepartmentsSelectedValue, setIssuerDepartmentsSelectedValue] =
    useState<Department | undefined>(undefined);
  const [issuerCountrys, setIssuerCountrys] = useState<Country[] | undefined>(
    undefined
  );
  const [issuerCountrysSelectedValue, setIssuerCountrysSelectedValue] =
    useState<Country | undefined>(undefined);

  const [issuerDeptMgrs, setIssuerDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [issuerDeptMgrSelectedValue, setIssuerDeptMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [issuerDeptMgrApproves, setIssuerDeptMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [
    issuerDeptMgrApproveSelectedValue,
    setIssuerDeptMgrApproveSelectedValue,
  ] = useState<Approverejectstatus | undefined>(undefined);

  const [issuerDivMgrs, setIssuerDivMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [issuerDivMgrSelectedValue, setIssuerDivMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [issuerDivMgrApproves, setIssuerDivMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [
    issuerDivMgrApproveSelectedValue,
    setIssuerDivMgrApproveSelectedValue,
  ] = useState<Approverejectstatus | undefined>(undefined);

  //Part 1
  const [custCompanys, setCustCompanys] = useState<Customer[] | undefined>(
    undefined
  );
  const [custCompanysSelectedValue, setCustCompanysSelectedValue] = useState<
    Customer | undefined
  >(undefined);
  const [custContacts, setCustContacts] = useState<
    Customercontact[] | undefined
  >(undefined);
  const [custContactsSelectedValue, setCustContactsSelectedValue] = useState<
    Customercontact | undefined
  >(undefined);

  const [custClasses, setCustClasses] = useState<Customerclass[] | undefined>(
    undefined
  );
  const [custClassesSelectedValue, setCustClassesSelectedValue] = useState<
    Customerclass | undefined
  >(undefined);
  const [custCountrys, setCustCountrys] = useState<Country[] | undefined>(
    undefined
  );
  const [custCountrysSelectedValue, setCustCountrysSelectedValue] = useState<
    Country | undefined
  >(undefined);

  //Part 2
  const [probTypes, setProbTypes] = useState<Carproblemtype[] | undefined>(
    undefined
  );
  const [probTypesSelectedValue, setProbTypesSelectedValue] = useState<
    Carproblemtype | undefined
  >(undefined);
  const [probClaimCurrencys, setProbClaimCurrencys] = useState<
    Currency[] | undefined
  >(undefined);
  const [probClaimCurrencysSelectedValue, setProbClaimCurrencysSelectedValue] =
    useState<Currency | undefined>(undefined);

  //Part 3
  // const [justcheckedList, setJustCheckedList] = useState([]);
  const [standardcheckedList, setStandardCheckedList] = useState([]);
  const [prejustDocIdArray, setPrejustDocIdArray] = useState([]);
  const [prejustDocIdString, setPrejustDocIdString] = useState("");
  const [preDeptMgrs, setPreDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [preDeptMgrsSelectedValue, setPreDeptMgrsSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  //Part 4
  const [resolvers, setResolvers] = useState<Employee[] | undefined>(undefined);
  const [resolversSelectedValue, setResolversSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [resolverCompanys, setResolverCompanys] = useState<
    Company[] | undefined
  >(undefined);
  const [resolverCompanysSelectedValue, setResolverCompanysSelectedValue] =
    useState<Company | undefined>(undefined);
  const [resolverDivdirs, setResolverDivdirs] = useState<
    Divisiondirector[] | undefined
  >(undefined);
  const [resolverDivdirsSelectedValue, setResolverDivdirsSelectedValue] =
    useState<Divisiondirector | undefined>(undefined);
  const [resolverDivs, setResolverDivs] = useState<Division[] | undefined>(
    undefined
  );
  const [resolverDivsSelectedValue, setResolverDivsSelectedValue] = useState<
    Division | undefined
  >(undefined);
  const [resolverDepts, setResolverDepts] = useState<Department[] | undefined>(
    undefined
  );
  const [resolverDeptsSelectedValue, setResolverDeptsSelectedValue] = useState<
    Department | undefined
  >(undefined);
  const [resolverCountrys, setResolverCountrys] = useState<
    Country[] | undefined
  >(undefined);
  const [resolverCountrysSelectedValue, setResolverCountrysSelectedValue] =
    useState<Country | undefined>(undefined);

  //Part 5
  const [supCompanys, setSupCompanys] = useState<Supplier[] | undefined>(
    undefined
  );
  const [supCompanysSelectedValue, setSupCompanysSelectedValue] = useState<
    Supplier | undefined
  >(undefined);
  const [supClasses, setSupClasses] = useState<Supplierclass[] | undefined>(
    undefined
  );
  const [supClassesSelectedValue, setSupClassesSelectedValue] = useState<
    Supplierclass | undefined
  >(undefined);
  const [supSettleCurrencys, setSupSettleCurrencys] = useState<
    Currency[] | undefined
  >(undefined);
  const [supSettleCurrencysSelectedValue, setSupSettleCurrencysSelectedValue] =
    useState<Currency | undefined>(undefined);

  //Part 7
  const [rsvDeptMgrs, setRsvDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [rsvDeptMgrSelectedValue, setRsvDeptMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [rsvDeptMgrApproves, setRsvDeptMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [rsvDeptMgrApproveSelectedValue, setRsvDeptMgrApproveSelectedValue] =
    useState<Approverejectstatus | undefined>(undefined);

  const [rsvDivMgrs, setRsvDivMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [rsvDivMgrSelectedValue, setRsvDivMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [rsvDivMgrApproves, setRsvDivMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [rsvDivMgrApproveSelectedValue, setRsvDivMgrApproveSelectedValue] =
    useState<Approverejectstatus | undefined>(undefined);

  const [rsvQmrs, setRsvQmrs] = useState<Employee[] | undefined>(undefined);
  const [rsvQmrSelectedValue, setRsvQmrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [rsvQmrVerifys, setRsvQmrVerifys] = useState<
    Verifystatus[] | undefined
  >(undefined);
  const [rsvQmrVerifySelectedValue, setRsvQmrVerifySelectedValue] = useState<
    Verifystatus | undefined
  >(undefined);

  //Part 8
  const [auditors, setAuditors] = useState<Employee[] | undefined>(undefined);
  const [auditorsSelectedValue, setAuditorsSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [auditorAdequateStatus, SetAuditorAdequateStatus] = useState(1);

  //Part close
  const [closeQmrs, setCloseQmrs] = useState<Employee[] | undefined>(undefined);
  const [closeQmrSelectedValue, setCloseQmrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  //Hide&Visible
  const [issuerDeptHide, SetIssuerDeptHide] = useState(true);
  const [issuerDivHide, SetIssuerDivHide] = useState(true);
  const [issuerDeptIdHide, SetIssuerDeptIdHide] = useState(true);
  const [issuerDivIdHide, SetIssuerDivIdHide] = useState(true);
  const [resolverHide, SetResolverHide] = useState(true);
  const [resolverDeptIdHide, SetResolverDeptIdHide] = useState(true);
  const [resolverDeptHide, SetResolverDeptHide] = useState(true);
  const [resolverDivIdHide, SetResolverDivIdHide] = useState(true);
  const [resolverDivHide, SetResolverDivHide] = useState(true);

  const [qmrVerifyHide, SetQmrVerifyHide] = useState(true);

  const [auditorIdHide, SetAuditorIdHide] = useState(true);
  const [auditorHide, SetAuditorHide] = useState(true);

  const [custsatHide, SetCustsatHide] = useState(true);
  const [closedQmrHide, SetClosedQmrHide] = useState(true);

  // const [rootHumanHide,SetRootHumanHide] = useState(false)
  // const [rootMaterialHide,SetRootMaterialHide] = useState(false)
  // const [rootMachineHide,SetRootMachineHide] = useState(false)
  // const [rootProcessHide,SetRootProcessHide] = useState(false)
  // const [rootSupplierHide,SetRootSupplierHide] = useState(false)
  // const [rootSkillHide,SetRootSkillHide] = useState(false)
  // const [rootOtherHide,SetRootOtherHide] = useState(false)

  const [rootHuman, SetRootHuman] = useState("");
  const [rootMaterial, SetRootMaterial] = useState("");
  const [rootMachine, SetRootMachine] = useState("");
  const [rootProcess, SetRootProcess] = useState("");
  const [rootSupplier, SetRootSupplier] = useState("");
  const [rootSkill, SetRootSkill] = useState("");
  const [rootOther, SetRootOther] = useState("");
  const [rootCauseRequire, SetRootCauseRequire] = useState(false);

  // const [hideApproveStatus, SetHideApproveStatus] = useState(false)

  const [hideProbTypeOther, SetHideProbTypeOther] = useState(true);
  const [hidePreJustDocOther, SetHidePreJustDocOther] = useState(true);

  const [carTypeName, SetCarTypeName] = useState("");

  //Add
  const [nctypes, setNctypes] = useState<Nctype[] | undefined>(undefined);
  const [ncsubtypes, setNcsubtypes] = useState<Ncsubtype[] | undefined>(
    undefined
  );
  const [rootcauses, setRootcauses] = useState<Rootcause[] | undefined>(
    undefined
  );
  //Fix value

  //Cust sata api
  const [surveylines, SetSurveylines] = useState<
    SurveyLineResponse[] | undefined
  >(undefined);
  let dataArray: any = [];

  //const [driveFileList, setDriveFileList] = useState([] as Drive[])
  // const fileProps = {
  //     headers: {
  //         authorization: 'authorization-text',
  //     },
  //     action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //     name: 'file',
  // };
  // // const [drives, SetDrives] = useState<Drive[] | undefined>(undefined)
  // const [visibleUploadFile, setVisibleUploadFile] = useState(false);
  // const [visibleUploadSuccess, setVisibleUploadSuccess] = useState(false);

  const hideCondition = (doc: Car) => {
    switch (doc.wf_status_id) {
      case "S1":
        SetApproveButtonName("Submit");
        SetWkStatusId(doc.wf_status_id);
        break;
      case "S2":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);

        SetResolverHide(true);
        SetResolverDeptHide(true);
        SetResolverDivHide(true);
        SetQmrVerifyHide(true);
        SetAuditorIdHide(true);
        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);
        break;
      case "S3":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        SetResolverHide(true);
        SetResolverDeptHide(true);
        SetResolverDivHide(true);
        SetQmrVerifyHide(true);
        SetAuditorIdHide(true);
        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);

        break;
      case "S4":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);

        SetResolverDeptHide(true);
        SetResolverDivHide(true);
        SetQmrVerifyHide(true);
        SetAuditorIdHide(true);
        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);
        break;
      case "S5":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);

        SetResolverDivHide(true);
        SetQmrVerifyHide(true);
        SetAuditorIdHide(true);
        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);
        break;
      case "S6":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);
        //open DIV
        SetResolverDivHide(false);

        SetQmrVerifyHide(true);
        SetAuditorIdHide(true);
        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);
        break;
      case "S7":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);
        //open DIV
        SetResolverDivHide(false);

        SetQmrVerifyHide(false);

        SetAuditorIdHide(false);

        SetAuditorHide(true);
        SetCustsatHide(true);
        SetClosedQmrHide(true);

        SetApproveButtonName("Verify By QMR/EMR");
        break;
      case "S8":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);
        //open DIV
        SetResolverDivHide(false);

        SetQmrVerifyHide(false);

        SetAuditorIdHide(false);

        SetAuditorHide(false);
        SetCustsatHide(true);
        SetClosedQmrHide(true);

        SetApproveButtonName("Submit By Auditor");
        break;
      case "S10":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);
        //open DIV
        SetResolverDivHide(false);

        SetQmrVerifyHide(false);

        SetAuditorIdHide(false);

        SetAuditorHide(false);
        SetCustsatHide(false);
        SetClosedQmrHide(false);

        SetApproveButtonName("Closed This CAR");
        getSurveyLines(doc.car_doc_id);

        break;
      case "S11":
        SetIssuerDeptIdHide(true);

        //comment
        SetIssuerDeptHide(false);
        SetIssuerDivIdHide(false);
        SetIssuerDivHide(false);

        //Open Resolver
        SetResolverHide(false);
        SetResolverDeptHide(false);
        SetResolverDivIdHide(false);
        //open DIV
        SetResolverDivHide(false);

        SetQmrVerifyHide(false);

        SetAuditorIdHide(false);

        SetAuditorHide(false);
        SetCustsatHide(false);
        SetClosedQmrHide(false);

        getSurveyLines(doc.car_doc_id);

        break;
      default:
        break;
    }
  };

  //Get ID
  const getCarById = async (id: string) => {
    setLoading(true);

    const data = await requestCarById(id, accessToken);

    setRepeatedCase(data.repeated_case);
    setNcTypeName(data.nc_type_name);
    setNcsubTypeName(data.nc_subtype_name);
    setRootCauseName(data.root_cause_error_name);

    setCarDocId(data.car_doc_id);
    //wf_status_id = data.wf_status_id
    setWf_status_idParm(data.wf_status_id);
    workflowStyle(data.wf_status_id);

    //disable
    SetDisableId(true);
    SetDisableFix(true);

    if (data.wf_status_id === "S11") {
      SetClosed(true);
    }

    formCreateEdit.setFieldsValue({ id: data.id });

    formCreateEdit.setFieldsValue({ car_doc_id: data.car_doc_id });

    setCarDocNewId(data.car_doc_id);

    formCreateEdit.setFieldsValue({ wf_status_id: data.wf_status_id });
    SetWkStatusId(data.wf_status_id);

    if (data.doc_status_id !== 0) {
      formCreateEdit.setFieldsValue({ doc_status_id: data.doc_status_id });
    }
    // if (data.car_standard_id !== 0) {
    //     formCreateEdit.setFieldsValue({ car_standard_id: data.car_standard_id })
    // }
    if (data.car_standard_name !== "") {
      formCreateEdit.setFieldsValue({
        car_standard_name: data.car_standard_name,
      });
    }
    // if (data.car_category_id !== 0) {
    //     SetCarCategoryId(data.car_category_id)
    //     formCreateEdit.setFieldsValue({ car_category_id: data.car_category_id })
    // }
    if (data.car_category_name !== "") {
      formCreateEdit.setFieldsValue({
        car_category_name: data.car_category_name,
      });
      SetCarCategoryId(data.car_category_name);
    }

    // if (data.car_type_id !== 0) {
    //     formCreateEdit.setFieldsValue({ car_type_id: data.car_type_id })
    // }
    if (data.car_type_name !== "") {
      formCreateEdit.setFieldsValue({ car_type_name: data.car_type_name });
    }
    // if (data.issuer_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_id: data.issuer_id })
    // }
    if (data.issuer_code !== "") {
      formCreateEdit.setFieldsValue({ issuer_code: data.issuer_code });
    }

    if (data.issuer_company_name !== "") {
      formCreateEdit.setFieldsValue({
        issuer_company_name: data.issuer_company_name,
      });
    }
    // if (data.issuer_div_dir_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_div_dir_id: data.issuer_div_dir_id })
    // }
    if (data.issuer_div_dir_name !== "") {
      formCreateEdit.setFieldsValue({
        issuer_div_dir_name: data.issuer_div_dir_name,
      });
    }
    // if (data.issuer_div_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_div_id: data.issuer_div_id })
    // }
    if (data.issuer_div_name !== "") {
      formCreateEdit.setFieldsValue({ issuer_div_name: data.issuer_div_name });
    }
    // if (data.issuer_dept_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_dept_id: data.issuer_dept_id })
    // }
    if (data.issuer_dept_name !== "") {
      formCreateEdit.setFieldsValue({
        issuer_dept_name: data.issuer_dept_name,
      });
    }
    // if (data.issuer_country_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_country_id: data.issuer_country_id })
    // }
    if (data.issuer_country_name !== "") {
      formCreateEdit.setFieldsValue({
        issuer_country_name: data.issuer_country_name,
      });
    }

    // if (data.issuer_dept_mgr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_dept_mgr_id: data.issuer_dept_mgr_id })
    // }
    if (data.issuer_dept_mgr_code !== "") {
      formCreateEdit.setFieldsValue({
        issuer_dept_mgr_code: data.issuer_dept_mgr_code,
      });
    }
    if (data.issuer_dept_mgr_comment_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        issuer_dept_mgr_comment_date: moment(data.issuer_dept_mgr_comment_date),
      });
    }

    formCreateEdit.setFieldsValue({
      issuer_dept_mgr_comment: data.issuer_dept_mgr_comment,
    });

    // if (data.issuer_div_mgr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ issuer_div_mgr_id: data.issuer_div_mgr_id })
    // }
    if (data.issuer_div_mgr_code !== "") {
      formCreateEdit.setFieldsValue({
        issuer_div_mgr_code: data.issuer_div_mgr_code,
      });
    }
    if (data.issuer_div_mgr_comment_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        issuer_div_mgr_comment_date: moment(data.issuer_div_mgr_comment_date),
      });
    }

    formCreateEdit.setFieldsValue({
      issuer_div_mgr_comment: data.issuer_div_mgr_comment,
    });

    if (data.issuer_dept_mgr_approve !== 0) {
      SetIssuerDeptMgrApprove(data.issuer_dept_mgr_approve);
      formCreateEdit.setFieldsValue({
        issuer_dept_mgr_approve: data.issuer_dept_mgr_approve,
      });
    }
    if (data.issuer_div_mgr_approve !== 0) {
      SetIssuerDivMgrApprove(data.issuer_div_mgr_approve);
      formCreateEdit.setFieldsValue({
        issuer_div_mgr_approve: data.issuer_div_mgr_approve,
      });
    }

    //Part 1
    // if (data.cust_company_id !== 0) {
    //     formCreateEdit.setFieldsValue({ cust_company_id: data.cust_company_id })
    // }
    if (data.cust_company_code !== "") {
      formCreateEdit.setFieldsValue({
        cust_company_code: data.cust_company_code,
      });
    }
    // if (data.cust_contact_id !== 0) {
    //     formCreateEdit.setFieldsValue({ cust_contact_id: data.cust_contact_id })
    // }
    if (data.cust_contact_name !== "") {
      formCreateEdit.setFieldsValue({
        cust_contact_name: data.cust_contact_name,
      });
    }
    // if (data.cust_class_id !== 0) {
    //     formCreateEdit.setFieldsValue({ cust_class_id: data.cust_class_id })
    // }
    if (data.cust_class_name !== "") {
      formCreateEdit.setFieldsValue({ cust_class_name: data.cust_class_name });
    }
    // if (data.cust_country_id !== 0) {
    //     formCreateEdit.setFieldsValue({ cust_country_id: data.cust_country_id })
    // }
    if (data.cust_country_name !== "") {
      formCreateEdit.setFieldsValue({
        cust_country_name: data.cust_country_name,
      });
    }
    //cust_country_name

    formCreateEdit.setFieldsValue({
      cust_contact_email: data.cust_contact_email,
    });
    formCreateEdit.setFieldsValue({ cust_contact_tel: data.cust_contact_tel });

    //Part 2
    formCreateEdit.setFieldsValue({ prob_ref_doc: data.prob_ref_doc });
    formCreateEdit.setFieldsValue({
      prob_product_name: data.prob_product_name,
    });
    if (data.prob_receive_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        prob_receive_date: moment(data.prob_receive_date),
      });
    }

    formCreateEdit.setFieldsValue({ prob_topic: data.prob_topic });
    formCreateEdit.setFieldsValue({ prob_detail: data.prob_detail });

    // if (data.prob_type_id !== 0) {
    //     formCreateEdit.setFieldsValue({ prob_type_id: data.prob_type_id })
    // }
    if (data.prob_type_name !== "") {
      formCreateEdit.setFieldsValue({ prob_type_name: data.prob_type_name });

      SetCarTypeName(data.prob_type_name);
    }

    formCreateEdit.setFieldsValue({ prob_type_other: data.prob_type_other });
    formCreateEdit.setFieldsValue({
      prob_claim_amount: data.prob_claim_amount,
    });

    if (data.prob_claim_currency_name !== "") {
      formCreateEdit.setFieldsValue({
        prob_claim_currency_name: data.prob_claim_currency_name,
      });
    }

    formCreateEdit.setFieldsValue({
      prob_response_detail: data.prob_response_detail,
    });
    if (data.prob_response_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        prob_response_date: moment(data.prob_response_date),
      });
    }

    //Part 3

    let justArray = data.pre_just_doc_id.split(",");
    setPrejustDocIdString(data.pre_just_doc_id);
    formCreateEdit.setFieldsValue({ pre_just_doc_id: justArray });

    formCreateEdit.setFieldsValue({
      pre_just_doc_other: data.pre_just_doc_other,
    });
    if (data.pre_invest_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        pre_invest_date: moment(data.pre_invest_date),
      });
    }

    if (data.pre_dept_mgr_id !== 0) {
      formCreateEdit.setFieldsValue({ pre_dept_mgr_id: data.pre_dept_mgr_id });
    }

    //Part 4
    // if (data.resolver_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_id: data.resolver_id })
    // }
    if (data.resolver_code !== "") {
      formCreateEdit.setFieldsValue({ resolver_code: data.resolver_code });
    }
    // if (data.resolver_company_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_company_id: data.resolver_company_id })
    // }
    if (data.resolver_company_name !== "") {
      formCreateEdit.setFieldsValue({
        resolver_company_name: data.resolver_company_name,
      });
    }
    // if (data.resolver_div_dir_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_div_dir_id: data.resolver_div_dir_id })
    // }
    if (data.resolver_div_dir_name !== "") {
      formCreateEdit.setFieldsValue({
        resolver_div_dir_name: data.resolver_div_dir_name,
      });
    }
    // if (data.resolver_div_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_div_id: data.resolver_div_id })
    // }
    if (data.resolver_div_name !== "") {
      formCreateEdit.setFieldsValue({
        resolver_div_name: data.resolver_div_name,
      });
    }
    // if (data.resolver_dept_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_dept_id: data.resolver_dept_id })
    // }
    if (data.resolver_dept_name !== "") {
      formCreateEdit.setFieldsValue({
        resolver_dept_name: data.resolver_dept_name,
      });
    }
    // if (data.resolver_country_id !== 0) {
    //     formCreateEdit.setFieldsValue({ resolver_country_id: data.resolver_country_id })

    // }
    if (data.resolver_country_name !== "") {
      formCreateEdit.setFieldsValue({
        resolver_country_name: data.resolver_country_name,
      });
    }

    //Part 5
    // if (data.sup_company_id !== 0) {
    //     formCreateEdit.setFieldsValue({ sup_company_id: data.sup_company_id })
    // }
    if (data.sup_company_code !== "") {
      formCreateEdit.setFieldsValue({
        sup_company_code: data.sup_company_code,
      });
    }
    // if (data.sup_class_id !== 0) {
    //     formCreateEdit.setFieldsValue({ sup_class_id: data.sup_class_id })
    // }
    if (data.sup_class_name !== "") {
      formCreateEdit.setFieldsValue({ sup_class_name: data.sup_class_name });
    }

    formCreateEdit.setFieldsValue({
      sup_settle_detail: data.sup_settle_detail,
    });
    formCreateEdit.setFieldsValue({
      sup_settle_amount: data.sup_settle_amount,
    });

    // if (data.sup_settle_currency_id !== 0) {
    //     formCreateEdit.setFieldsValue({ sup_settle_currency_id: data.sup_settle_currency_id })
    // }
    if (data.sup_settle_currency_name !== "") {
      formCreateEdit.setFieldsValue({
        sup_settle_currency_name: data.sup_settle_currency_name,
      });
    }

    //Part 6
    formCreateEdit.setFieldsValue({ cause_human: data.cause_human });
    formCreateEdit.setFieldsValue({ cause_material: data.cause_material });
    formCreateEdit.setFieldsValue({ cause_machine: data.cause_machine });
    formCreateEdit.setFieldsValue({ cause_process: data.cause_process });
    formCreateEdit.setFieldsValue({
      cause_notperformed: data.cause_notperformed,
    });
    formCreateEdit.setFieldsValue({ cause_skilltrain: data.cause_skilltrain });
    formCreateEdit.setFieldsValue({ cause_other: data.cause_other });

    SetRootHuman(data.cause_human);
    SetRootMaterial(data.cause_material);
    SetRootMachine(data.cause_machine);
    SetRootProcess(data.cause_process);
    SetRootSupplier(data.cause_notperformed);
    SetRootSkill(data.cause_skilltrain);
    SetRootOther(data.cause_other);

    //Part 7
    formCreateEdit.setFieldsValue({
      rsv_implement_detail: data.rsv_implement_detail,
    });
    formCreateEdit.setFieldsValue({
      rsv_dept_mgr_comment: data.rsv_dept_mgr_comment,
    });

    // if (data.rsv_dept_mgr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ rsv_dept_mgr_id: data.rsv_dept_mgr_id })
    // }
    if (data.rsv_dept_mgr_code !== "") {
      formCreateEdit.setFieldsValue({
        rsv_dept_mgr_code: data.rsv_dept_mgr_code,
      });
    }
    if (data.rsv_dept_mgr_comment_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        rsv_dept_mgr_comment_date: moment(data.rsv_dept_mgr_comment_date),
      });
    }
    if (data.rsv_dept_mgr_approve !== 0) {
      formCreateEdit.setFieldsValue({
        rsv_dept_mgr_approve: data.rsv_dept_mgr_approve,
      });
    }

    formCreateEdit.setFieldsValue({
      rsv_div_mgr_comment: data.rsv_div_mgr_comment,
    });
    // if (data.rsv_div_mgr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ rsv_div_mgr_id: data.rsv_div_mgr_id })
    // }
    if (data.rsv_div_mgr_code !== "") {
      formCreateEdit.setFieldsValue({
        rsv_div_mgr_code: data.rsv_div_mgr_code,
      });
    }
    if (data.rsv_div_mgr_approve !== 0) {
      formCreateEdit.setFieldsValue({
        rsv_div_mgr_approve: data.rsv_div_mgr_approve,
      });
    }

    if (data.rsv_div_mgr_comment_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        rsv_div_mgr_comment_date: moment(data.rsv_div_mgr_comment_date),
      });
    }
    // if (data.rsv_qmr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ rsv_qmr_id: data.rsv_qmr_id })
    // }
    if (data.rsv_qmr_code !== "") {
      formCreateEdit.setFieldsValue({ rsv_qmr_code: data.rsv_qmr_code });
    }
    if (data.rsv_qmr_verify_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        rsv_qmr_verify_date: moment(data.rsv_qmr_verify_date),
      });
    }
    if (data.rsv_qmr_comment !== "") {
      formCreateEdit.setFieldsValue({ rsv_qmr_comment: data.rsv_qmr_comment });
    }

    if (data.rsv_qmr_verify !== 0) {
      formCreateEdit.setFieldsValue({ rsv_qmr_verify: data.rsv_qmr_verify });
    }

    //Part 8
    // if (data.auditor_id !== 0) {
    //     formCreateEdit.setFieldsValue({ auditor_id: data.auditor_id })
    // }
    if (data.auditor_code !== "") {
      formCreateEdit.setFieldsValue({ auditor_code: data.auditor_code });
    }
    if (data.auditor_within_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        auditor_within_date: moment(data.auditor_within_date),
      });
    }
    formCreateEdit.setFieldsValue({
      auditor_audit_comment: data.auditor_audit_comment,
    });

    if (data.auditor_audit_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        auditor_audit_date: moment(data.auditor_audit_date),
      });
    }
    if (data.auditor_adequate_status !== 0) {
      formCreateEdit.setFieldsValue({ auditor_adequate_status: 1 });
    }

    //Part 9
    formCreateEdit.setFieldsValue({
      cust_rsv_approve_detail: data.cust_rsv_approve_detail,
    });
    if (data.cust_rsv_approve_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        cust_rsv_approve_date: moment(data.cust_rsv_approve_date),
      });
    }
    formCreateEdit.setFieldsValue({
      cust_rsv_finish_detail: data.cust_rsv_finish_detail,
    });
    if (data.cust_rsv_finish_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        cust_rsv_finish_date: moment(data.cust_rsv_finish_date),
      });
    }

    //Part 10
    formCreateEdit.setFieldsValue({ custsat_detail: data.custsat_detail });
    formCreateEdit.setFieldsValue({ custsat_comment: data.custsat_comment });

    //Part close
    // if (data.close_qmr_id !== 0) {
    //     formCreateEdit.setFieldsValue({ close_qmr_id: data.close_qmr_id })
    // }
    if (data.close_qmr_code !== "") {
      formCreateEdit.setFieldsValue({ close_qmr_code: data.close_qmr_code });
    }

    formCreateEdit.setFieldsValue({
      close_qmr_comment: data.close_qmr_comment,
    });
    if (data.close_qmr_date !== "0001-01-01T00:00:00Z") {
      formCreateEdit.setFieldsValue({
        close_qmr_date: moment(data.close_qmr_date),
      });
    }

    hideCondition(data);

    // rootCauseCondition()
    setLoading(false);
  };

  const worker = {
    //claim_date: dayjs.tz(new Date(), "Asia/Bangkok")
  };

  const confirmApprove = (
    e: any,
    _actionName: string,
    _wf_status_id: string,
    _approveReject: number,
    _submitAgain: number
  ) => {
    console.log("confirmApprove");
    console.log(e);
    console.log(_actionName);
    console.log(_wf_status_id);
    console.log(_approveReject);
    console.log(_submitAgain);
    message.success("Click on Yes");
  };

  const cancelApprove: PopconfirmProps["onCancel"] = (e) => {
    //console.log(e);
    //message.error("Click on No");
  };
  const showModal = async (
    e: any,
    _actionName: string,
    _wf_status_id: string,
    _approveReject: number,
    _submitAgain: number
  ) => {
    if (_wf_status_id === "S1") {
      //save draft
      SetWkStatusId("S1");
    } else if (_wf_status_id === "S2") {
      //issuer create
      SetWkStatusId("S2");
    } else if (_wf_status_id === "S4") {
      rootCauseCondition("");
      SetWkStatusId("S4");
    } else if (_wf_status_id === "S5") {
      rootCauseCondition("");
      SetWkStatusId("S5");
    } else if (_wf_status_id === "S6") {
      rootCauseCondition("");
      SetWkStatusId("S6");
    } else if (_wf_status_id === "S7") {
      rootCauseCondition("");
      SetWkStatusId("S7");
    } else if (_wf_status_id === "S8") {
      rootCauseCondition("");
      SetWkStatusId("S8");
    } else if (_wf_status_id === "S9") {
      rootCauseCondition("");
      SetWkStatusId("S9");
    }

    SetWfApproveReject(_approveReject);
    SetSubmitAgain(_submitAgain);

    setActionName(_actionName);
    try {
      const values = await formCreateEdit.validateFields();
      console.log("Success:", values);
      setVisible(true);
      formCreateEdit.submit();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };
  const showModalUpdateAll = async (
    e: any,
    _actionName: string,
    _wf_status_id: string,
    _approveReject: number,
    _submitAgain: number
  ) => {
    SetUpdateAll(true);
    if (_wf_status_id === "S1") {
      //save draft
      SetWkStatusId("S1");
    } else if (_wf_status_id === "S2") {
      //issuer create
      SetWkStatusId("S2");
    } else if (_wf_status_id === "S4") {
      rootCauseCondition("");
      SetWkStatusId("S4");
    } else if (_wf_status_id === "S5") {
      rootCauseCondition("");
      SetWkStatusId("S5");
    } else if (_wf_status_id === "S6") {
      rootCauseCondition("");
      SetWkStatusId("S6");
    } else if (_wf_status_id === "S7") {
      rootCauseCondition("");
      SetWkStatusId("S7");
    } else if (_wf_status_id === "S8") {
      rootCauseCondition("");
      SetWkStatusId("S8");
    } else if (_wf_status_id === "S9") {
      rootCauseCondition("");
      SetWkStatusId("S9");
    }

    SetWfApproveReject(_approveReject);
    SetSubmitAgain(_submitAgain);

    setActionName(_actionName);
    try {
      const values = await formCreateEdit.validateFields();
      console.log("Success:", values);
      setVisible(true);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
    setLoading(false);
  };
  const handleUpdateWorkflowCancel = () => {
    setVisibleUpdateWorkflowSuccess(false);
    // setLoading(false);

    if (msgAlert != "") {
      //return history.
      history.go(0);
    }
    setMsgAlert("");
  };

  const handleUpdateRepeatedCaseCancel = () => {
    setVisibleUpdateRepeatedCase(false);
    // setLoading(false);

    if (msgAlert != "") {
      //return history.
      history.go(0);
    }
    setMsgAlert("");
  };
  const handleUpdateRootCauseCancel = () => {
    setVisibleUpdateRootCause(false);
    // setLoading(false);

    if (msgAlert != "") {
      //return history.
      history.go(0);
    }
    setMsgAlert("");
  };

  const config = {
    title: "Success!",
    content: (
      <>
        Name
        <br />
        Name
      </>
    ),
  };
  const handleFormSubmit = async (doc: Car) => {
    if (id) {
      //Worflow
      if (updateAll === true) {
        setLoading(true);
        doc.pre_just_doc_id = prejustDocIdString;
        const data = await requestUpdateAllById(doc, accessToken);
        console.log("updateAll");
        console.log(data);
        setLoading(false);
        setVisible(false);
        setCarDocNewId(data.car_doc_id);
        setVisibleSuccess(true);
        setNeedLoadCars(true);
        setActionName("");
        //return;
      } else {
        if (wfApproveReject > 0) {
          setLoading(true);
          doc.pre_just_doc_id = prejustDocIdString;
          if (doc.wf_status_id === "S2") {
            if (
              user.employee_id !== doc.issuer_dept_mgr_code &&
              user.car_role_id === 1
            ) {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not approve!. Because you are not authorized this case for Department manager only! (S2) , Your id is " +
                  user.employee_id +
                  " but system id is " +
                  doc.issuer_dept_mgr_code
              );
              return;
            }
            doc.issuer_dept_mgr_approve = wfApproveReject;
          }
          if (doc.wf_status_id === "S3") {
            if (
              user.employee_id !== doc.issuer_div_mgr_code &&
              user.car_role_id === 1
            ) {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not approve!. Because you are not authorized this case for Division manager only! (S3)"
              );
              return;
            }
            doc.issuer_div_mgr_approve = wfApproveReject;
          }
          if (doc.wf_status_id === "S5") {
            if (
              user.employee_id !== doc.rsv_dept_mgr_code &&
              user.car_role_id === 1
            ) {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not approve!. Because you are not authorized this case for Department manager only! (S5)"
              );
              return;
            }
            doc.rsv_dept_mgr_approve = wfApproveReject;
          }
          if (doc.wf_status_id === "S6") {
            if (
              user.employee_id !== doc.rsv_div_mgr_code &&
              user.car_role_id === 1
            ) {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not approve!. Because you are not authorized this case for Division Manager only! (S6)"
              );
              return;
            }

            doc.rsv_div_mgr_approve = wfApproveReject;
          }
          if (doc.wf_status_id === "S7") {
            if (
              user.car_role_id == 4 ||
              user.car_role_id == 5 ||
              user.car_role_id == 6
            ) {
              doc.rsv_qmr_verify = wfApproveReject;
              doc.rsv_qmr_code = user.employee_id;
            } else {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not verifiy!. Because you are not authorized this case for QMR/EMR only! (S7)"
              );
              return;
            }
            // if (user.car_role_id !== 4 && user.car_role_id !== 5 && user.car_role_id !== 6) {
            //     setVisible(false);
            //     setLoading(false);
            //     alert("You can not verifiy!. Because you are not authorized this case for QMR/EMR only! (S7)")
            //     return;
            // }
          }
          if (doc.wf_status_id === "S8") {
            if (
              user.employee_id !== doc.auditor_code &&
              user.car_role_id === 1
            ) {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not submit!. Because you are not authorized this case for Auditor only !(S6)"
              );
              return;
            }
            doc.auditor_audit_closed = wfApproveReject;
          }
          if (doc.wf_status_id === "S10") {
            if (
              user.car_role_id == 4 ||
              user.car_role_id == 5 ||
              user.car_role_id == 6
            ) {
              doc.close_qmr_closed = wfApproveReject;
              doc.close_qmr_code = user.employee_id;
            } else {
              setVisible(false);
              setLoading(false);
              alert(
                "You can not verifiy!. Because you are not authorized this case for QMR only! (S10)"
              );
              return;
            }
          }

          doc.wf_approve_reject = wfApproveReject;

          const data = await requestWorkflowUpdateById(doc, accessToken);
          setLoading(false);
          setVisible(false);
          setCarDocNewId(data.car_doc_id);
          setVisibleSuccess(true);
          setNeedLoadCars(true);
          setActionName("");
        } else {
          setLoading(true);
          doc.pre_just_doc_id = prejustDocIdString;

          //Submit again
          if (submitAgain === 1) {
            switch (doc.wf_status_id) {
              case "S1":
                doc.wf_status_id = "S2";
                doc.issuer_dept_mgr_approve = 0;
                break;
              case "S2":
                doc.wf_status_id = "S3";
                break;
              case "S3":
                doc.wf_status_id = "S4";
                break;
              case "S4":
                doc.wf_status_id = "S5";
                break;
              case "S5":
                doc.wf_status_id = "S6";
                break;
              case "S6":
                doc.wf_status_id = "S7";
                break;
              case "S7":
                doc.wf_status_id = "S8";
                break;
              case "S8":
                doc.wf_status_id = "S9";
                break;
              case "S9":
                doc.wf_status_id = "S10";
                break;
              case "S10":
                doc.wf_status_id = "S11";
                break;
              case "S11":
                break;
              default:
                break;
            }
          }

          doc.wf_approve_reject = 0;
          const data = await requestUpdateById(doc, accessToken);
          await getCarById(id);
          setLoading(false);
          setVisible(false);
          setCarDocNewId(data.car_doc_id);
          setVisibleSuccess(true);
          setNeedLoadCars(true);
          setActionName("");
        }
      }
      //end
    } else {
      setLoading(true);
      doc.pre_just_doc_id = prejustDocIdString;
      doc.wf_status_id = wfStatusId;
      doc.drives = drives;
      try {
        const data = await requestCreateCar(doc, accessToken);
        // message.success('The car has been created')
        setLoading(false);
        setVisible(false);

        setCarDocNewId(data.car_doc_id);
        setVisibleSuccess(true);
        setNeedLoadCars(true);
        setActionName("");
      } catch (err: any) {
        alert("Can not create CAR !" + err);
      }
    }
  };

  const handleSuccess = () => {
    setVisibleSuccess(false);
    return history.push(`/cars`);
    //return history.push(`/cars/add-edit/${newId}`)
  };
  const handleUpdateWorkflowSuccess = async () => {
    setLoadingUpdate(true);
    try {
      const data = await requestUpdateWorkflowById(
        id as string,
        workflowStatusIdUpdate,
        accessToken
      );
    } catch (e: any) {}
    setLoadingUpdate(false);
    setMsgAlert("Workflow Updated success");
    // setVisibleUpdateWorkflowSuccess(false)
  };
  const handleUpdateRepeatedCaseSuccess = async () => {
    setLoadingUpdate(true);
    try {
      const carObj: Car = {
        repeated_case: repeatedCase,
        id: 0,
        car_doc_id: "",
        doc_status_id: 0,
        doc_status_name: "",
        wf_status_id: "",
        wf_status_name: "",
        car_standard_id: 0,
        car_category_id: 0,
        car_type_id: 0,
        issuer_id: 0,
        issuer_company_id: 0,
        issuer_div_dir_id: 0,
        issuer_div_id: 0,
        issuer_dept_id: 0,
        issuer_country_id: 0,
        issuer_dept_mgr_id: 0,
        issuer_dept_mgr_comment: "",
        issuer_dept_mgr_comment_date: moment().format(),
        issuer_dept_mgr_approve: 0,
        issuer_div_mgr_id: 0,
        issuer_div_mgr_comment: "",
        issuer_div_mgr_comment_date: moment().format(),
        issuer_div_mgr_approve: 0,
        cust_company_id: 0,
        cust_contact_id: 0,
        cust_class_id: 0,
        cust_country_id: 0,
        cust_contact_email: "",
        cust_contact_tel: "",
        prob_ref_doc: "",
        prob_product_name: "",
        prob_receive_date: moment().format(),
        prob_topic: "",
        prob_detail: "",
        prob_type_id: 0,
        prob_type_other: "",
        prob_claim_amount: 0,
        prob_claim_currency_id: 0,
        prob_response_detail: "",
        prob_response_date: moment().format(),
        pre_just_doc_id: "",
        pre_just_doc_other: "",
        pre_invest_date: moment().format(),
        pre_dept_mgr_id: 0,
        resolver_id: 0,
        resolver_company_id: 0,
        resolver_div_dir_id: 0,
        resolver_div_id: 0,
        resolver_dept_id: 0,
        resolver_country_id: 0,
        sup_company_id: 0,
        sup_class_id: 0,
        sup_settle_detail: "",
        sup_settle_amount: 0,
        sup_settle_currency_id: 0,
        cause_human: "",
        cause_material: "",
        cause_machine: "",
        cause_process: "",
        cause_notperformed: "",
        cause_skilltrain: "",
        cause_other: "",
        rsv_implement_detail: "",
        rsv_dept_mgr_comment: "",
        rsv_dept_mgr_id: 0,
        rsv_dept_mgr_comment_date: moment().format(),
        rsv_dept_mgr_approve: 0,
        rsv_div_mgr_id: 0,
        rsv_div_mgr_comment: "",
        rsv_div_mgr_comment_date: moment().format(),
        rsv_div_mgr_approve: 0,
        rsv_qmr_id: 0,
        rsv_qmr_comment: "",
        rsv_qmr_verify_date: moment().format(),
        rsv_qmr_verify: 0,
        auditor_id: 0,
        auditor_within_date: moment().format(),
        auditor_audit_closed: 0,
        auditor_audit_date: moment().format(),
        auditor_audit_comment: "",
        auditor_adequate_status: 0,
        cust_rsv_approve_detail: "",
        cust_rsv_approve_date: moment().format(),
        cust_rsv_finish_detail: "",
        cust_rsv_finish_date: moment().format(),
        custsat_detail: "",
        custsat_comment: "",
        custsat_survey_id: 0,
        created_by: 0,
        created_datetime: moment().format(),
        updated_by: 0,
        updated_datetime: moment().format(),
        close_qmr_id: 0,
        close_qmr_comment: "",
        close_qmr_date: moment().format(),
        close_qmr_closed: 0,
        wf_approve_reject: 0,
        drives: [],
        car_standard_name: "",
        car_category_name: "",
        car_type_name: "",
        issuer_code: "",
        issuer_company_name: "",
        issuer_div_dir_name: "",
        issuer_div_name: "",
        issuer_dept_name: "",
        issuer_country_name: "",
        issuer_dept_mgr_code: "",
        issuer_div_mgr_code: "",
        cust_company_code: "",
        cust_contact_name: "",
        cust_class_name: "",
        cust_country_name: "",
        prob_type_name: "",
        prob_claim_currency_name: "",
        pre_dept_mgr_code: "",
        resolver_code: "",
        resolver_company_name: "",
        resolver_div_dir_name: "",
        resolver_div_name: "",
        resolver_dept_name: "",
        resolver_country_name: "",
        sup_company_code: "",
        sup_class_name: "",
        sup_settle_currency_name: "",
        rsv_dept_mgr_code: "",
        rsv_div_mgr_code: "",
        rsv_qmr_code: "",
        auditor_code: "",
        close_qmr_code: "",
        cust_company_name: "",
        issuer_name: "",
        nc_type_name: "",
        nc_subtype_name: "",
        root_cause_error_name: "",
      };
      const data = await requestUpdateRepeatedCaseById(
        id as string,
        carObj,
        accessToken
      );
    } catch (e: any) {}
    setLoadingUpdate(false);
    setMsgAlert("Updated success");
    // setVisibleUpdateWorkflowSuccess(false)
  };
  const handleUpdateRootCauseSuccess = async () => {
    setLoadingUpdate(true);

    try {
      const carObj: Car = {
        repeated_case: 0,
        id: 0,
        car_doc_id: "",
        doc_status_id: 0,
        doc_status_name: "",
        wf_status_id: "",
        wf_status_name: "",
        car_standard_id: 0,
        car_category_id: 0,
        car_type_id: 0,
        issuer_id: 0,
        issuer_company_id: 0,
        issuer_div_dir_id: 0,
        issuer_div_id: 0,
        issuer_dept_id: 0,
        issuer_country_id: 0,
        issuer_dept_mgr_id: 0,
        issuer_dept_mgr_comment: "",
        issuer_dept_mgr_comment_date: moment().format(),
        issuer_dept_mgr_approve: 0,
        issuer_div_mgr_id: 0,
        issuer_div_mgr_comment: "",
        issuer_div_mgr_comment_date: moment().format(),
        issuer_div_mgr_approve: 0,
        cust_company_id: 0,
        cust_contact_id: 0,
        cust_class_id: 0,
        cust_country_id: 0,
        cust_contact_email: "",
        cust_contact_tel: "",
        prob_ref_doc: "",
        prob_product_name: "",
        prob_receive_date: moment().format(),
        prob_topic: "",
        prob_detail: "",
        prob_type_id: 0,
        prob_type_other: "",
        prob_claim_amount: 0,
        prob_claim_currency_id: 0,
        prob_response_detail: "",
        prob_response_date: moment().format(),
        pre_just_doc_id: "",
        pre_just_doc_other: "",
        pre_invest_date: moment().format(),
        pre_dept_mgr_id: 0,
        resolver_id: 0,
        resolver_company_id: 0,
        resolver_div_dir_id: 0,
        resolver_div_id: 0,
        resolver_dept_id: 0,
        resolver_country_id: 0,
        sup_company_id: 0,
        sup_class_id: 0,
        sup_settle_detail: "",
        sup_settle_amount: 0,
        sup_settle_currency_id: 0,
        cause_human: "",
        cause_material: "",
        cause_machine: "",
        cause_process: "",
        cause_notperformed: "",
        cause_skilltrain: "",
        cause_other: "",
        rsv_implement_detail: "",
        rsv_dept_mgr_comment: "",
        rsv_dept_mgr_id: 0,
        rsv_dept_mgr_comment_date: moment().format(),
        rsv_dept_mgr_approve: 0,
        rsv_div_mgr_id: 0,
        rsv_div_mgr_comment: "",
        rsv_div_mgr_comment_date: moment().format(),
        rsv_div_mgr_approve: 0,
        rsv_qmr_id: 0,
        rsv_qmr_comment: "",
        rsv_qmr_verify_date: moment().format(),
        rsv_qmr_verify: 0,
        auditor_id: 0,
        auditor_within_date: moment().format(),
        auditor_audit_closed: 0,
        auditor_audit_date: moment().format(),
        auditor_audit_comment: "",
        auditor_adequate_status: 0,
        cust_rsv_approve_detail: "",
        cust_rsv_approve_date: moment().format(),
        cust_rsv_finish_detail: "",
        cust_rsv_finish_date: moment().format(),
        custsat_detail: "",
        custsat_comment: "",
        custsat_survey_id: 0,
        created_by: 0,
        created_datetime: moment().format(),
        updated_by: 0,
        updated_datetime: moment().format(),
        close_qmr_id: 0,
        close_qmr_comment: "",
        close_qmr_date: moment().format(),
        close_qmr_closed: 0,
        wf_approve_reject: 0,
        drives: [],
        car_standard_name: "",
        car_category_name: "",
        car_type_name: "",
        issuer_code: "",
        issuer_company_name: "",
        issuer_div_dir_name: "",
        issuer_div_name: "",
        issuer_dept_name: "",
        issuer_country_name: "",
        issuer_dept_mgr_code: "",
        issuer_div_mgr_code: "",
        cust_company_code: "",
        cust_contact_name: "",
        cust_class_name: "",
        cust_country_name: "",
        prob_type_name: "",
        prob_claim_currency_name: "",
        pre_dept_mgr_code: "",
        resolver_code: "",
        resolver_company_name: "",
        resolver_div_dir_name: "",
        resolver_div_name: "",
        resolver_dept_name: "",
        resolver_country_name: "",
        sup_company_code: "",
        sup_class_name: "",
        sup_settle_currency_name: "",
        rsv_dept_mgr_code: "",
        rsv_div_mgr_code: "",
        rsv_qmr_code: "",
        auditor_code: "",
        close_qmr_code: "",
        cust_company_name: "",
        issuer_name: "",
        nc_type_name: ncTypeName.toString(),
        nc_subtype_name: ncsubTypeName.toString(),
        root_cause_error_name: rootCauseName.toString(),
      };

      const data = await requestUpdateRootCauseById(
        id as string,
        carObj,
        accessToken
      );
    } catch (e: any) {}
    setLoadingUpdate(false);
    setMsgAlert("Updated success");
    // setVisibleUpdateWorkflowSuccess(false)
  };
  const handleUpdateWorkflowSuccessClose = async () => {
    setVisibleUpdateWorkflowSuccess(false);
  };
  const handleUpdateRepeatedCaseClose = async () => {
    setVisibleUpdateRepeatedCase(false);
  };
  const handleUpdateRootCauseClose = async () => {
    setVisibleUpdateRootCause(false);
  };
  // const handleUploadSuccess = () => {
  //     setVisibleUploadSuccess(false)

  // }
  const handleFormSubmitFail = async (errorInfo: any) => {
    message.error(errorInfo);
  };

  const getSurveyLines = async (ref_doc_id: string) => {
    const data = await requestCustsatGetSurveyLines(
      {},
      accessToken,
      ref_doc_id
    );
    //SetSurveylines(data)

    try {
      data.map((d: SurveyLineResponse) => {
        dataArray.push({
          id: d.id,
          qt_id: d.qt_id,
          qt_topic: d.qt_topic,
          qt_score: d.qt_score == null ? 1 : d.qt_score,
          qt_comment: d.qt_comment,
        });
      });
    } catch (err: any) {}
  };

  const getWkStatus = async () => {
    const data = await requestWorkflowstatus({}, accessToken);
    setWkStatuses(data);
  };
  const getCarstandards = async () => {
    const data = await requestCarstandards({}, accessToken);
    setCarstandards(data);
  };
  const getDocStatus = async () => {
    const data = await requestDocStatuss({}, accessToken);
    setDocStatus(data);
  };

  const getCarcategorys = async () => {
    const data = await requestCarcategorys({}, accessToken);
    setCarcategorys(data);
  };
  //
  const getCartypes = async () => {
    const data = await requestCartypes({}, accessToken);
    setCartypes(data);
  };
  const getNctypes = async () => {
    const data = await requestNctypes({}, accessToken);
    setNctypes(data);
  };
  const getNcsubtypes = async () => {
    const data = await requestNcsubtypes({}, accessToken);
    setNcsubtypes(data);
  };
  const getRootCauses = async () => {
    const data = await requestRootcauses({}, accessToken);
    setRootcauses(data);
  };

  const getIssuerDivisiondirectortems = async () => {
    const data = await requestDivisiondirectors({}, accessToken);
    setIssuerDivisionDirectors(data);
  };

  const getIssuerDivisions = async () => {
    const data = await requestDivisions({}, accessToken);
    setIssuerDivisions(data);
  };

  const getIssuerDepartments = async () => {
    const data = await requestDepartments({}, accessToken);
    setIssuerDepartments(data);
  };

  const getIssuerCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setIssuerCountrys(data);
  };

  const getIssuers = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuers(data);
  };

  const getIssuerCompanys = async () => {
    const data = await requestCompanys({}, accessToken);
    setIssuerCompanys(data);
  };

  //Part 1
  const getCustCompanys = async () => {
    const data = await requestCustomers({}, accessToken);
    console.log("getCustCompanys");
    console.log(data);
    setCustCompanys(data);
  };
  const getCustContacts = async (customer_id: string) => {
    const data = await requestCustomercontactByCustomerId(
      {},
      accessToken,
      customer_id
    );
    setCustContacts(data);
  };
  const getCustClasses = async () => {
    const data = await requestCustClasses({}, accessToken);
    setCustClasses(data);
  };
  const getCustCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setCustCountrys(data);
  };

  //Part 2
  const getProblemTypes = async () => {
    const data = await requestCarproblemtypes({}, accessToken);
    setProbTypes(data);
  };
  const getProbClaimCurrencys = async () => {
    const data = await requestCurrencys({}, accessToken);
    setProbClaimCurrencys(data);
  };
  //Part 3
  const getPreDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setPreDeptMgrs(data);
  };
  const getIssuerDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuerDeptMgrs(data);
  };
  const getIssuerDivMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuerDivMgrs(data);
  };
  const getIssuerDeptMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setIssuerDeptMgrApproves(data);
  };
  const getIssuerDivMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setIssuerDivMgrApproves(data);
  };

  //Part 4
  const getResolvers = async () => {
    const data = await requestEmployees({}, accessToken);
    setResolvers(data);
  };
  const getResolverCompanys = async () => {
    const data = await requestCompanys({}, accessToken);
    setResolverCompanys(data);
  };
  const getResolverDivdirs = async () => {
    const data = await requestDivisiondirectors({}, accessToken);
    setResolverDivdirs(data);
  };
  const getResolverDivs = async () => {
    const data = await requestDivisions({}, accessToken);
    setResolverDivs(data);
  };
  const getResolverDepts = async () => {
    const data = await requestDepartments({}, accessToken);
    setResolverDepts(data);
  };

  const GetProfileByEmployeeId = async (employee_id: string) => {
    formCreateEdit.setFieldsValue({ issuer_div_dir_name: emp.business_name });
    formCreateEdit.setFieldsValue({ issuer_div_name: emp.division_name });
    formCreateEdit.setFieldsValue({ issuer_dept_name: emp.department_name });
    formCreateEdit.setFieldsValue({ issuer_country_name: emp.country_name });
    formCreateEdit.setFieldsValue({ issuer_company_name: emp.company_name });
  };

  const getResolverCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setResolverCountrys(data);
  };

  const getSupCompanys = async () => {
    const data = await requestSuppliers({}, accessToken);
    setSupCompanys(data);
  };
  const getSupClasses = async () => {
    const data = await requestSupplierclasses({}, accessToken);
    setSupClasses(data);
  };
  const getSubSettleCurrencys = async () => {
    const data = await requestCurrencys({}, accessToken);
    setSupSettleCurrencys(data);
  };

  //Part 7
  const getRsvDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvDeptMgrs(data);
  };
  const getRsvDivMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvDivMgrs(data);
  };
  const getRsvDeptMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setRsvDeptMgrApproves(data);
  };
  const getRsvDivMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setRsvDivMgrApproves(data);
  };
  const getRsvQmrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvQmrs(data);
  };

  const getRsvQmrVerify = async () => {
    const data = await requestVerifystatus({}, accessToken);
    setRsvQmrVerifys(data);
  };

  //Part 8
  const getAuditors = async () => {
    const data = await requestEmployees({}, accessToken);
    setAuditors(data);
  };

  const getCloseQmrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setCloseQmrs(data);
  };

  const workflowStyle = (_wf_status_id: any) => {
    switch (_wf_status_id) {
      case "S1":
        S1Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S2":
        S2Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S3":
        S3Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S4":
        S4Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S5":
        S5Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S6":
        S6Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S7":
        S7Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S8":
        S8Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S9":
        S9Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S10":
        S9Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
      case "S11":
        S9Style = { backgroundColor: "#FFFFCC", border: "2px solid #CCCC00" };
        return;
    }
  };

  const initNewCar = () => {
    getWkStatus();

    getCarstandards();
    getCarcategorys();
    getCartypes();
    getNctypes();
    getNcsubtypes();
    getRootCauses();

    getIssuers();
    getIssuerCompanys();
    getIssuerDivisiondirectortems();
    getIssuerDivisions();
    getIssuerDepartments();
    getIssuerCountrys();

    getDocStatus();

    //Part1
    getCustCompanys();
    // getCustContacts();
    getCustClasses();
    getCustCountrys();

    //Part 2
    getProblemTypes();
    getProbClaimCurrencys();

    //Part 3
    getPreDeptMgrs();
    getIssuerDeptMgrs();
    getIssuerDivMgrs();
    getIssuerDeptMgrApproves();
    getIssuerDivMgrApproves();

    //Part 4
    getResolvers();
    getResolverCompanys();
    getResolverDivdirs();
    getResolverDivs();
    getResolverDepts();
    getResolverCountrys();

    //Part 5
    getSupCompanys();
    getSupClasses();
    getSubSettleCurrencys();

    //Part 7
    getRsvDeptMgrs();
    getRsvDivMgrs();
    getRsvDeptMgrApproves();
    getRsvDivMgrApproves();
    getRsvQmrs();
    getRsvQmrVerify();

    //Part 8
    getAuditors();

    //Part close
    getCloseQmrs();

    formCreateEdit.setFieldsValue({ wf_status_id: "S1" });
    SetWkStatusId("S1");
    formCreateEdit.setFieldsValue({ doc_status_id: 1 });

    formCreateEdit.setFieldsValue({ sup_class_name: "General" });

    workflowStyle("");

    formCreateEdit.setFieldsValue({ car_standard_name: "ISO9001" });

    formCreateEdit.setFieldsValue({ issuer_code: user.employee_id.toString() });

    //formCreateEdit.setFieldsValue({ resolver_company_name: "SCGI" })

    GetProfileByEmployeeId(user.employee_id.toString());
  };

  useEffect(() => {
    if (id) {
      getCarById(id);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    initNewCar();
    setDrives([]);

    // eslint-disable-next-line
  }, []);

  const handleChangeWfStatus = async (e: any) => {
    formCreateEdit.setFieldsValue({ wk_status_id: e });
  };
  const handleUpdateWfStatusByQmr = async (e: any) => {
    setWorkflowStatusIdUpdate(e);
  };
  const handleUpdateRepeatedCaseByQmr = async (e: any) => {
    setRepeatedCase(e.target.value);
  };
  const handleUpdateNcTypeByQmr = async (e: any) => {
    setNcTypeName(e);
  };
  const handleUpdateNcsubTypeByQmr = async (e: any) => {
    setNcsubTypeName(e);
  };
  const handleUpdateRootCauseByQmr = async (e: any) => {
    setRootCauseName(e);
  };
  const handleChangeCarstandard = async (e: any) => {
    formCreateEdit.setFieldsValue({ car_standard_name: e });
  };
  const handleChangeDocStatus = async (e: any) => {
    formCreateEdit.setFieldsValue({ doc_status_id: e });
  };

  const handleChangeCarcategory = async (e: any) => {
    // SetCarCategoryId(e)
    formCreateEdit.setFieldsValue({ car_category_name: e });
    SetCarCategoryId(e);
  };

  const handleChangeCartype = async (e: any) => {
    formCreateEdit.setFieldsValue({ car_type_name: e });
    SetCarTypeName(e);
  };
  const handleChangeIssuer = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_code: e });

    const data = await requestEmployeeByEmployeeId(
      {},
      accessToken,
      e.toString()
    );
    console.log(data);
    formCreateEdit.setFieldsValue({ issuer_company_name: data.company_name });
    formCreateEdit.setFieldsValue({
      issuer_div_dir_name: data.business_name ? data.business_name : "",
    });
    formCreateEdit.setFieldsValue({
      issuer_div_name: data.division_name ? data.division_name : "",
    });
    formCreateEdit.setFieldsValue({
      issuer_dept_name: data.department_name ? data.department_name : "",
    });
    formCreateEdit.setFieldsValue({
      issuer_country_name: data.country_name ? data.country_name : "",
    });
  };
  const handleChangeIssuerCompany = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_company_id: e });
  };
  const handleChangeIssuerDivisionDirector = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_divisiondirector_name: e });
  };
  const handleChangeIssuerDivision = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_division_name: e });
  };
  const handleChangeIssuerDepartment = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_dept_name: e });
  };

  // const handleChangeIssuerCountry = async (e: any) => {
  //     formCreateEdit.setFieldsValue({ issuer_country_id: e })
  // }
  const handleChangeIssuerCountry = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_country_name: e });
  };
  const handleChangeCustCompany = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_company_code: e });

    const data = await requestCustomerByCustomerCode(
      {},
      accessToken,
      e.toString()
    );
    formCreateEdit.setFieldsValue({
      cust_country_name: data.cust_country_name,
    });
    formCreateEdit.setFieldsValue({ cust_class_name: data.cust_class_name });

    const dataContact = await requestCustomercontactByCustomerId(
      {},
      accessToken,
      e.toString()
    );
    setCustContacts(dataContact);
  };
  const handleChangeCustContact = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_contact_name: e.target.value });

    const data = await requestCustomercontactByName(
      {},
      accessToken,
      e.target.value
    );
    //formCreateEdit.setFieldsValue({ cust_contact_email: data.contact_email })
  };
  const handleChangeCustContactEmail = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_contact_email: e.target.value });
  };
  const handleChangeCustContactTel = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_contact_tel: e.target.value });
  };
  const handleChangeCustClass = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_class_name: e });
  };
  const handleChangeCustCountry = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_country_name: e });
  };

  const handleChangeProbRefDoc = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_ref_doc: e.target.value });
  };
  const handleChangeProbProductName = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_product_name: e.target.value });
  };

  const handleChangeProbType = async (e: any) => {
    // if (e === 5) {
    //     SetHideProbTypeOther(false);
    // } else {
    //     SetHideProbTypeOther(true);
    // }

    formCreateEdit.setFieldsValue({ prob_type_name: e });
  };
  const handleChangeProbTopic = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_topic: e.target.value });
  };
  const handleChangeProbDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_detail: e.target.value });
  };
  const handleChangeProbTypeOther = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_type_other: e.target.value });
  };

  const handleChangeProblemReceiveDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_receive_date: e });
  };
  const handleProblemClaimAmount = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_claim_amount: e });
  };
  const handleChangeProbClaimCurrency = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_claim_currency_name: e });
  };

  const handleProblemReponseDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_response_detail: e.target.value });
  };

  const handleChangePreJustDocCheckbox = async (e: any) => {
    console.log(e.toString());
    if (e.includes("Other")) {
      // Found world

      SetHidePreJustDocOther(false);
    } else {
      SetHidePreJustDocOther(true);
    }

    setPrejustDocIdString(e.toString());
    formCreateEdit.setFieldsValue({ pre_just_doc_id: e });
  };
  const handleChangePreJustDocOther = async (e: any) => {
    formCreateEdit.setFieldsValue({ pre_just_doc_other: e.target.value });
  };

  const handleProblemReponseDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ prob_response_date: e });
  };

  const handleChangeCustImmediateResponseDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({
      cust_immediate_response_detail: e.target.value,
    });
  };

  // const handleChangeCustPreinvestEvident = async (e: any) => {
  //     formCreateEdit.setFieldsValue({ cust_preinvestigate_evident: e.target.value })
  // }
  const handleChangeCarStandardName = async (e: any) => {
    formCreateEdit.setFieldsValue({ carstandard_name: e.target.value });
  };

  const handleChangeCustPreinvestigateDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ pre_invest_date: e });
  };
  const handleChangePreDeptMgr = async (e: any) => {
    formCreateEdit.setFieldsValue({ pre_dept_mgr_id: e });
  };
  const handleChangeIssuerDeptMgr = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_dept_mgr_code: e });
  };
  const handleChangeIssuerDeptMgrApprove = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_dept_mgr_approve: e });
  };

  const handleChangeIssuerDeptMgrCommentDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_dept_mgr_comment_date: e });
  };

  const handleChangeIssuerDeptMgrComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_dept_mgr_comment: e.target.value });
    formCreateEdit.setFieldsValue({ issuer_dept_mgr_comment_date: moment() });
  };
  const handleChangeIssuerDivMgr = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_div_mgr_code: e });
  };
  const handleChangeIssuerDivMgrApprove = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_div_mgr_approve: e });
  };

  const handleChangeIssuerDivMgrCommentDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_div_mgr_comment_date: e });
  };

  const handleChangeIssuerDivMgrComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ issuer_div_mgr_comment: e.target.value });
    formCreateEdit.setFieldsValue({ issuer_div_mgr_comment_date: moment() });
  };

  //Part 3

  const handleChangeCorrectiveDeptDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ corrective_dept_date: e });
  };
  const handleChangeCorrectiveDivisionDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ corrective_division_date: e });
  };
  const handleChangeAuditorWithinDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ auditor_within_date: e });
  };
  const handleChangeCustFinishedDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_finished_date: e });
  };
  const handleChangeCustApprovedDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_approved_date: e });
  };

  //Part 4
  const handleChangeResolver = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_code: e });

    const data = await requestEmployeeByEmployeeId(
      {},
      accessToken,
      e.toString()
    );

    formCreateEdit.setFieldsValue({
      resolver_div_dir_name: data.business_name,
    });
    formCreateEdit.setFieldsValue({ resolver_div_name: data.division_name });
    formCreateEdit.setFieldsValue({ resolver_dept_name: data.department_name });
    formCreateEdit.setFieldsValue({ resolver_country_name: data.country_name });
    formCreateEdit.setFieldsValue({ resolver_company_name: data.company_name });
  };
  const handleChangeResolverCompany = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_company_name: e });
  };
  const handleChangeResolverDivdir = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_div_dir_name: e });
  };
  const handleChangeResolverDiv = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_div_name: e });
  };
  const handleChangeResolverDept = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_dept_name: e });
  };
  const handleChangeResolverCountry = async (e: any) => {
    formCreateEdit.setFieldsValue({ resolver_country_id: e });
  };
  const handleChangeSupCompany = async (e: any) => {
    formCreateEdit.setFieldsValue({ sup_company_code: e });
  };
  const handleChangeSupClass = async (e: any) => {
    formCreateEdit.setFieldsValue({ sup_class_name: e });
  };
  const handleChangeSupSettleDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ sup_settle_detail: e.target.value });
  };
  const handleSubSettleAmount = async (e: any) => {
    formCreateEdit.setFieldsValue({ sup_settle_amount: e });
  };
  const handleChangeSupSettleCurrency = async (e: any) => {
    formCreateEdit.setFieldsValue({ sup_settle_currency_name: e });
  };

  //Part 6
  const handleCauseHuman = async (e: any) => {
    SetRootHuman(e.target.value);

    formCreateEdit.setFieldsValue({ cause_human: e.target.value });

    rootCauseCondition(e.target.value);
  };
  const handleCauseMaterial = async (e: any) => {
    SetRootMaterial(e.target.value);

    formCreateEdit.setFieldsValue({ cause_material: e.target.value });
    rootCauseCondition(e.target.value);
  };
  const handleCauseMachine = async (e: any) => {
    SetRootMachine(e.target.value);

    formCreateEdit.setFieldsValue({ cause_machine: e.target.value });
    rootCauseCondition(e.target.value);
  };
  const handleCauseProcess = async (e: any) => {
    SetRootProcess(e.target.value);

    formCreateEdit.setFieldsValue({ cause_process: e.target.value });
    rootCauseCondition(e.target.value);
  };
  const handleCauseNotperformed = async (e: any) => {
    SetRootSupplier(e.target.value);

    formCreateEdit.setFieldsValue({ cause_notperformed: e.target.value });
    rootCauseCondition(e.target.value);
  };
  const handleCauseSkilltrain = async (e: any) => {
    SetRootSkill(e.target.value);

    formCreateEdit.setFieldsValue({ cause_skilltrain: e.target.value });
    rootCauseCondition(e.target.value);
  };
  const handleCauseOther = async (e: any) => {
    SetRootOther(e.target.value);

    formCreateEdit.setFieldsValue({ cause_other: e.target.value });

    rootCauseCondition(e.target.value);
  };
  const rootCauseCondition = (value: string) => {
    if (value !== "") {
      SetRootCauseRequire(false);
    } else {
      if (
        rootHuman !== "" ||
        rootMaterial !== "" ||
        rootMachine !== "" ||
        rootProcess !== "" ||
        rootSupplier !== "" ||
        rootSkill !== "" ||
        rootOther !== ""
      ) {
        SetRootCauseRequire(false);
      } else {
        SetRootCauseRequire(true);
        setVisible(false);
        message.warning("Please input Root Cause before!");
      }
    }
  };

  //Part 7
  const handleRsvImplementDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_implement_detail: e.target.value });
  };
  const handleRsvDeptMgrComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_dept_mgr_comment: e.target.value });
    formCreateEdit.setFieldsValue({ rsv_dept_mgr_comment_date: moment() });
  };
  const handleChangeRsvDeptMgr = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_dept_mgr_code: e });
  };
  const handleChangeRsvDeptMgrApprove = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_dept_mgr_approve: e });
  };

  const handleChangeRsvDeptMgrCommentDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_dept_mgr_comment_date: e });
  };
  const handleRsvDivMgrComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_div_mgr_comment: e.target.value });
    formCreateEdit.setFieldsValue({ rsv_div_mgr_comment_date: moment() });
  };
  const handleChangeRsvDivMgr = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_div_mgr_code: e });
  };
  const handleChangeRsvDivMgrApprove = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_div_mgr_approve: e });
  };
  const handleChangeRsvDivMgrCommentDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_div_mgr_comment_date: e });
  };
  const handleChangeRsvQmrId = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_qmr_code: e });
  };
  const handleChangeRsvQmrVerifyDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_qmr_verify_date: e });
  };
  const handleRsvQMRVerifyComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ rsv_qmr_comment: e.target.value });
  };

  //Part 8
  const handleChangeAuditor = async (e: any) => {
    formCreateEdit.setFieldsValue({ auditor_code: e });
    formCreateEdit.setFieldsValue({ rsv_qmr_verify_date: moment() });
  };
  const handleChangeAuditorResult = async (e: any) => {
    formCreateEdit.setFieldsValue({ auditor_audit_comment: e.target.value });
  };
  const handleChangeAuditDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ auditor_audit_date: e });
  };
  const handleAuditorAdequateStatus = async (e: any) => {
    // SetAuditorAdequateStatus(e.target.value)
    formCreateEdit.setFieldsValue({ auditor_adequate_status: e.target.value });
  };

  //Part 9
  const handleChangeCustRsvApproveDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_rsv_approve_detail: e.target.value });
  };
  const handleChangeCustRsvApprovedDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_rsv_approve_date: e });
  };
  const handleChangeCustRsvFinishDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_rsv_finish_detail: e.target.value });
  };
  const handleChangeCustRsvFinishDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ cust_rsv_finish_date: e });
  };

  //Part 10
  const handleChangeCustSatisfactionDetail = async (e: any) => {
    formCreateEdit.setFieldsValue({ custsat_detail: e.target.value });
  };
  const handleChangeCustSatComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ custsat_comment: e.target.value });
  };

  //Part close
  const handleChangeCloseQmrComment = async (e: any) => {
    formCreateEdit.setFieldsValue({ close_qmr_comment: e.target.value });
    formCreateEdit.setFieldsValue({ close_qmr_date: moment() });
  };
  const handleChangeCloseQmrDate = async (e: any) => {
    formCreateEdit.setFieldsValue({ close_qmr_date: e });
  };
  const handleChangeCloseQmrId = async (e: any) => {
    formCreateEdit.setFieldsValue({ close_qmr_code: e });
  };
  // const handleChangeDivision = async (e: any) => {
  //     formCreateEdit.setFieldsValue({ division_id: e })
  //     let deptFilter = departmentDatas?.filter(item => item.id === e)
  //     setDepartmentItems(deptFilter)
  //     formCreateEdit.setFieldsValue({ department_id: "" })
  // }

  const dateFormat = "YYYY-MM-DD";

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col span={18}>
          <Link to={"/cars/detail/" + id}>Back</Link> &nbsp;
        </Col>
        <Col span={6} className="text-right">
          <Button type="primary"></Button>
          {(user.car_role_id == 4 ||
            user.car_role_id == 5 ||
            user.car_role_id == 6) && (
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Menu <DownOutlined />
              </a>
            </Dropdown>
          )}
        </Col>
      </Row>
      {wf_status_idParm ? (
        <Row>
          <Col span={24}>Work flow : {wf_status_idParm}</Col>
        </Row>
      ) : (
        ""
      )}

      <Row justify="space-between" align="middle">
        <Col span={18}>
          <Title level={5} className="no-margin">
            {id ? "CAR No: " + cardocid : "Create"}
          </Title>
        </Col>
        <Col span={6} className="text-right"></Col>
      </Row>
      <Row>
        <Col span={12}>
          <span>
            Repeated Case :{" "}
            {repeatedCase === 1 ? "Yes" : repeatedCase === 2 ? "No" : " "}
          </span>
        </Col>
        <Col span={12}>
          <span>NC Type : {ncTypeName}</span>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <span>NC Sub Type : {ncsubTypeName}</span>
        </Col>
        <Col span={12}>
          <span>Root Cause Error : {rootCauseName}</span>
        </Col>
      </Row>

      <Divider />

      <Spin tip="Loading..." spinning={loading}></Spin>

      <Row>
        <Col span={24}>
          <Form
            style={{ marginBottom: "200px", backgroundColor: "#FFF8F7" }}
            initialValues={{ surveylines: dataArray }}
            form={formCreateEdit}
            name="formCreateEdit"
            onFinish={handleFormSubmit}
            onFinishFailed={handleFormSubmitFail}
            layout="vertical"
            //initialValues={worker}
          >
            {cardocid && (
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="CAR Doc No."
                    name="car_doc_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter CAR doc id",
                      },
                    ]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Workflow Status"
                    name="wf_status_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select work flow status",
                      },
                    ]}
                  >
                    <Select
                      disabled={true}
                      defaultValue={wfStatusesSelectedValue?.id}
                      onChange={handleChangeWfStatus}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // filterSort={(optionA: any, optionB: any) =>
                      //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      // }
                    >
                      {wfStatuses?.map((item: Workflowstatus, index: any) => (
                        <Select.Option value={item.wf_status_id} key={index}>
                          {item.wf_status_id} - {item.wf_status_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={12}>
                <Form.Item
                  label="Standard related to this CAR"
                  name="car_standard_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select standard",
                    },
                  ]}
                >
                  <Select
                    disabled={disableFix}
                    defaultValue={carstandardsSelectedValue?.name}
                    onChange={handleChangeCarstandard}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {carstandards?.map((item: Carstandard, index: any) => (
                      <Select.Option value={item.name} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Document status"
                  name="doc_status_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select document status",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={docStatusSelectedValue?.id}
                    onChange={handleChangeDocStatus}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {docStatus?.map((item: Docstatus, index: any) => (
                      <Select.Option value={item.id} key={index}>
                        {item.doc_status_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Category"
                  name="car_category_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <Select
                    disabled={disableFix}
                    defaultValue={carcategorysSelectedValue?.name}
                    onChange={handleChangeCarcategory}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {carcategorys?.map((item: Carcategory, index: any) => (
                      <Select.Option value={item.name} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Type"
                  name="car_type_name"
                  rules={[
                    {
                      required: true,
                      message: "Please select type of CAR",
                    },
                  ]}
                >
                  <Select
                    defaultValue={cartypesSelectedValue?.name}
                    onChange={handleChangeCartype}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {cartypes?.map((item: Cartype, index: any) => (
                      <Select.Option value={item.name} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="From"
                  name="issuer_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter issuer from",
                    },
                  ]}
                >
                  <Select
                    disabled={id ? true : false}
                    defaultValue={issuersSelectedValue?.employee_id}
                    onChange={handleChangeIssuer}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuers?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Company"
                  name="issuer_company_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company",
                    },
                  ]}
                >
                  <Select
                    defaultValue={issuerCompanysSelectedValue?.company_name}
                    onChange={handleChangeIssuerCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerCompanys?.map((item: Company, index: any) => (
                      <Select.Option value={item.company_name} key={index}>
                        {item.company_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Division Director"
                  name="issuer_div_dir_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter issuer division director from",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      issuerDivisionDirectorsSelectedValue?.divisiondirector_name
                    }
                    onChange={handleChangeIssuerDivisionDirector}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerDivisionDirectors?.map(
                      (item: Divisiondirector, index: any) => (
                        <Select.Option
                          value={item.divisiondirector_name}
                          key={index}
                        >
                          {item.divisiondirector_name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Division"
                  name="issuer_div_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter issuer division",
                    },
                  ]}
                >
                  <Select
                    defaultValue={issuerDivisionsSelectedValue?.division_name}
                    onChange={handleChangeIssuerDivision}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerDivisions?.map((item: Division, index: any) => (
                      <Select.Option value={item.division_name} key={index}>
                        {item.division_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Department"
                  name="issuer_dept_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter issuer department",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      issuerDepartmentsSelectedValue?.department_name
                    }
                    onChange={handleChangeIssuerDepartment}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerDepartments?.map((item: Department, index: any) => (
                      <Select.Option value={item.department_name} key={index}>
                        {item.department_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="issuer_country_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter issuer country",
                    },
                  ]}
                >
                  <Select
                    defaultValue={issuerCountrysSelectedValue?.country_name}
                    onChange={handleChangeIssuerCountry}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerCountrys?.map((item: Country, index: any) => (
                      <Select.Option value={item.country_name} key={index}>
                        {item.country_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Row style={{ backgroundColor: "#B4C6E7" }}>
              <Col span={24}>
                <Title level={5}>
                  PART 1: Customer Information (only claim, complaint and
                  suggestion)
                </Title>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label="Customer Company Code"
                  name="cust_company_code"
                  rules={[
                    {
                      required:
                        carCategoryId === "Customer claims & complaints" ||
                        carCategoryId === "Customer satisfaction"
                          ? true
                          : false,
                      message: "Please enter customer",
                    },
                  ]}
                >
                  <Select
                    defaultValue={custCompanysSelectedValue?.customer_id}
                    onChange={handleChangeCustCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {custCompanys?.map((item: Customer, index: any) => (
                      <Select.Option value={item.customer_id} key={index}>
                        {item.customer_id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="1.1 Customer Company"
                  name="cust_company_code"
                  rules={[
                    {
                      required: carCategoryId.toLowerCase().includes("customer")
                        ? true
                        : false,
                      message: "Please enter customer",
                    },
                  ]}
                >
                  <Select
                    defaultValue={custCompanysSelectedValue?.customer_id}
                    onChange={handleChangeCustCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {custCompanys?.map((item: Customer, index: any) => (
                      <Select.Option value={item.customer_id} key={index}>
                        {item.customer_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="1.2 Customer Contact Person Name"
                  name="cust_contact_name"
                  rules={[
                    {
                      required: carCategoryId.toLowerCase().includes("customer")
                        ? true
                        : false,
                      message: "Please enter customer contact",
                    },
                  ]}
                >
                  {/* <Select defaultValue={custContactsSelectedValue?.contact_name} onChange={handleChangeCustContact}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custContacts?.map((item: Customercontact, index: any) => <Select.Option value={item.contact_name} key={index}>{item.contact_name}</Select.Option>)}
                                    </Select> */}
                  <Input onChange={handleChangeCustContact} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="1.3 Customer Contact Email"
                  name="cust_contact_email"
                  rules={[
                    {
                      type: "email",
                      required: carCategoryId.toLowerCase().includes("customer")
                        ? true
                        : false,
                      message: "Please enter customer contact Email",
                    },
                  ]}
                >
                  <Input onChange={handleChangeCustContactEmail} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Customer Contact Tel"
                  name="cust_contact_tel"
                  rules={[
                    {
                      required: false,
                      message: "Please enter customer contact tel",
                    },
                  ]}
                >
                  <Input onChange={handleChangeCustContactTel} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="1.4 Type of Customer"
                  name="cust_class_name"
                  rules={[
                    {
                      required: carCategoryId.toLowerCase().includes("customer")
                        ? true
                        : false,
                      message: "Please enter customer class",
                    },
                  ]}
                >
                  <Select
                    defaultValue={custClassesSelectedValue?.cust_class_name}
                    onChange={handleChangeCustClass}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {custClasses?.map((item: Customerclass, index: any) => (
                      <Select.Option value={item.cust_class_name} key={index}>
                        {item.cust_class_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="1.5 Customer Country"
                  name="cust_country_name"
                  rules={[
                    {
                      required: carCategoryId.toLowerCase().includes("customer")
                        ? true
                        : false,
                      message: "Please enter customer country",
                    },
                  ]}
                >
                  <Select
                    defaultValue={custCountrysSelectedValue?.country_name}
                    onChange={handleChangeCustCountry}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {custCountrys?.map((item: Country, index: any) => (
                      <Select.Option value={item.country_name} key={index}>
                        {item.country_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row style={{ backgroundColor: "#B4C6E7" }}>
              <Col span={24}>
                <Title level={5}>PART 2: Problem details</Title>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.1 Reference Document (P/O,S/O)"
                  name="prob_ref_doc"
                  rules={[
                    {
                      required: true,
                      message: "Please enter reference document",
                    },
                  ]}
                >
                  <Input onChange={handleChangeProbRefDoc} maxLength={20} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.2 Product"
                  name="prob_product_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter product name",
                    },
                  ]}
                >
                  <Input onChange={handleChangeProbProductName} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="2.3 Date of receiving claim / complaint / suggestion from customer"
                  name="prob_receive_date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter date of receiving",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleChangeProblemReceiveDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.4 Topic"
                  name="prob_topic"
                  rules={[
                    {
                      required: true,
                      message: "Please enter problem topic",
                    },
                  ]}
                >
                  <Input onChange={(e) => handleChangeProbTopic(e)} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.5 Details of the problem"
                  name="prob_detail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter details of the problem",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    onChange={(e) => handleChangeProbDetail(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.6 Type of claim/complaint/non conformity/suggestion"
                  name="prob_type_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter type of claim",
                    },
                  ]}
                >
                  <Select
                    defaultValue={probTypesSelectedValue?.name}
                    onChange={handleChangeProbType}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {probTypes?.map((item: Carproblemtype, index: any) => (
                      <Select.Option value={item.name} key={index}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} hidden={hideProbTypeOther}>
                <Form.Item
                  label="&nbsp;"
                  name="prob_type_other"
                  rules={[
                    {
                      required: !hideProbTypeOther,
                      message: "Please enter type of claim",
                    },
                  ]}
                >
                  <Input onChange={(e) => handleChangeProbTypeOther(e)} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.7 Estimated amount of claim (if any)"
                  name="prob_claim_amount"
                  rules={[
                    {
                      required:
                        carTypeName === "Customer claims" ? true : false,
                      message: "Please enter estimated amount of claim",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: 200 }}
                    onChange={handleProblemClaimAmount}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Currency"
                  name="prob_claim_currency_name"
                  rules={[
                    {
                      required:
                        carTypeName === "Customer claims" ? true : false,
                      message: "Please enter currency",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      probClaimCurrencysSelectedValue?.currency_name
                    }
                    onChange={handleChangeProbClaimCurrency}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {probClaimCurrencys?.map((item: Currency, index: any) => (
                      <Select.Option value={item.currency_name} key={index}>
                        {item.currency_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="2.8 Details of our Immediate Response to customer"
                  name="prob_response_detail"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter details of our Immediate Response to customer",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleProblemReponseDetail} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="2.9 Date of immediate response to customer"
                  name="prob_response_date"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter date of immediate response to customer",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleProblemReponseDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row style={{ backgroundColor: "#B4C6E7" }}>
              <Col span={24}>
                <Title level={5}>
                  PART 3: Preliminary Investigation on customer side
                </Title>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="3.1 Justification for claim, photographs, sampling, site visits, etc…"
                  name="pre_just_doc_id"
                  rules={[
                    {
                      required: true,
                      message: "Please enter justification",
                    },
                  ]}
                >
                  <JustCheckboxGroup
                    options={justplainOptions}
                    onChange={handleChangePreJustDocCheckbox}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Details"
                  name="pre_just_doc_other"
                  rules={[
                    {
                      required: false,
                      message: "Please enter other",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleChangePreJustDocOther} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Date of Preliminary Investigation on customer side"
                  name="pre_invest_date"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter date of Preliminary Investigation on customer side",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleChangeCustPreinvestigateDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="3.2 Department Manager (Issuing department)"
                      name="issuer_dept_mgr_code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter department manager",
                        },
                      ]}
                    >
                      <Select
                        disabled={wf_status_idParm === "S2" ? true : false}
                        defaultValue={issuerDeptMgrSelectedValue?.employee_id}
                        onChange={handleChangeIssuerDeptMgr}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // filterSort={(optionA: any, optionB: any) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                      >
                        {issuerDeptMgrs?.map((item: Employee, index: any) => (
                          <Select.Option value={item.employee_id} key={index}>
                            {item.fullname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={
                        issuerDeptMgrApprove === 2 ? styleReject : undefined
                      }
                      label="Department Manager status"
                      name="issuer_dept_mgr_approve"
                      rules={[
                        {
                          required: false,
                          message: "Please enter Department Manager status",
                        },
                      ]}
                    >
                      <Select
                        disabled={true}
                        defaultValue={issuerDeptMgrApproveSelectedValue?.id}
                        onChange={handleChangeIssuerDeptMgrApprove}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // filterSort={(optionA: any, optionB: any) =>
                        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        // }
                      >
                        {issuerDeptMgrApproves?.map(
                          (item: Approverejectstatus, index: any) => (
                            <Select.Option value={item.id} key={index}>
                              {item.ap_status_name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  hidden={issuerDeptHide}
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S2" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>
                      Department Manager (Issuing department)
                    </Title>
                  </Col>
                </Row>
                <Row hidden={issuerDeptHide}>
                  <Col span={12}>
                    <Form.Item
                      label="Dept Mgr Comment"
                      name="issuer_dept_mgr_comment"
                      rules={[
                        {
                          required: wf_status_idParm === "S2" ? true : false,
                          message: "Please enter Dept Mgr Comment",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        onChange={handleChangeIssuerDeptMgrComment}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Dept Mgr Comment Date"
                      name="issuer_dept_mgr_comment_date"
                      rules={[
                        {
                          required: wf_status_idParm === "S2" ? true : false,
                          message: "Please enter Dept Mgr Comment Date",
                        },
                      ]}
                    >
                      <DatePicker
                        disabled={true}
                        onChange={(e) =>
                          handleChangeIssuerDeptMgrCommentDate(e)
                        }
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label="3.3 Division Manager / Regional Manager (Issuing division)"
                  name="issuer_div_mgr_code"
                  rules={[
                    {
                      required: true, //wf_status_id === "S2" ? true : false
                      message: "Please enter division manager",
                    },
                  ]}
                >
                  <Select
                    disabled={wf_status_idParm === "S3" ? true : false}
                    defaultValue={issuerDivMgrSelectedValue?.employee_id}
                    onChange={handleChangeIssuerDivMgr}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerDivMgrs?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Division Manager status"
                  name="issuer_div_mgr_approve"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Division Manager status",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={issuerDivMgrApproveSelectedValue?.id}
                    onChange={handleChangeIssuerDivMgrApprove}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {issuerDivMgrApproves?.map(
                      (item: Approverejectstatus, index: any) => (
                        <Select.Option value={item.id} key={index}>
                          {item.ap_status_name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row
              hidden={issuerDivHide}
              style={{
                backgroundColor:
                  wf_status_idParm === "S3" ? headerColor2 : headerColor1,
              }}
            >
              <Col span={24}>
                <Title level={5}>Division Manager (Issuing division)</Title>
              </Col>
            </Row>

            <Row hidden={issuerDivHide}>
              <Col span={12}>
                <Form.Item
                  label="Division Mgr Comment"
                  name="issuer_div_mgr_comment"
                  rules={[
                    {
                      required: wf_status_idParm === "S3" ? true : false,
                      message: "Please enter Division Mgr Comment",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    onChange={handleChangeIssuerDivMgrComment}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Division Mgr Comment Date"
                  name="issuer_div_mgr_comment_date"
                  rules={[
                    {
                      required: wf_status_idParm === "S3" ? true : false,
                      message: "Please enter Division Mgr Comment Date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => handleChangeIssuerDivMgrCommentDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row style={{ backgroundColor: headerColor1 }}>
              <Col span={24}>
                <Title level={5}>PART 4: Responsible party</Title>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  label="To"
                  name="resolver_code"
                  rules={[
                    {
                      required: true,
                      message: "Please enter to",
                    },
                  ]}
                >
                  <Select
                    defaultValue={resolversSelectedValue?.employee_id}
                    onChange={handleChangeResolver}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolvers?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Company"
                  name="resolver_company_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company",
                    },
                  ]}
                >
                  <Select
                    defaultValue={resolverCompanysSelectedValue?.company_name}
                    onChange={handleChangeResolverCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolverCompanys?.map((item: Company, index: any) => (
                      <Select.Option value={item.company_name} key={index}>
                        {item.company_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Division Director"
                  name="resolver_div_dir_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Division Director",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      resolverDivdirsSelectedValue?.divisiondirector_name
                    }
                    onChange={handleChangeResolverDivdir}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolverDivdirs?.map(
                      (item: Divisiondirector, index: any) => (
                        <Select.Option
                          value={item.divisiondirector_name}
                          key={index}
                        >
                          {item.divisiondirector_name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Division"
                  name="resolver_div_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter division",
                    },
                  ]}
                >
                  <Select
                    defaultValue={resolverDivsSelectedValue?.division_name}
                    onChange={handleChangeResolverDiv}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolverDivs?.map((item: Division, index: any) => (
                      <Select.Option value={item.division_name} key={index}>
                        {item.division_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Department"
                  name="resolver_dept_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter department",
                    },
                  ]}
                >
                  <Select
                    defaultValue={resolverDeptsSelectedValue?.department_name}
                    onChange={handleChangeResolverDept}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolverDepts?.map((item: Department, index: any) => (
                      <Select.Option value={item.department_name} key={index}>
                        {item.department_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="resolver_country_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter country",
                    },
                  ]}
                >
                  <Select
                    defaultValue={resolverCountrysSelectedValue?.country_name}
                    onChange={handleChangeResolverCountry}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {resolverCountrys?.map((item: Country, index: any) => (
                      <Select.Option value={item.country_name} key={index}>
                        {item.country_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={24}>
                <Divider />

                <Row
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S4" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>
                      PART 5: Claim Settlement with supplier/contractor
                    </Title>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Supplier Company Code"
                  name="sup_company_code"
                  rules={[
                    {
                      required: !resolverHide,
                      message: "Please enter supplier",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    defaultValue={supCompanysSelectedValue?.sup_id}
                    onChange={handleChangeSupCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {supCompanys?.map((item: Supplier, index: any) => (
                      <Select.Option value={item.sup_id} key={index}>
                        {item.sup_id}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Supplier Company Name"
                  name="sup_company_code"
                  rules={[
                    {
                      required: !resolverHide,
                      message: "Please enter supplier",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    defaultValue={supCompanysSelectedValue?.sup_id}
                    onChange={handleChangeSupCompany}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {supCompanys?.map((item: Supplier, index: any) => (
                      <Select.Option value={item.sup_id} key={index}>
                        {item.sup_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  hidden={true}
                  label="Type of Supplier"
                  name="sup_class_name"
                  rules={[
                    {
                      required: false, //!resolverHide
                      message: "Please enter type of supplier",
                    },
                  ]}
                >
                  <Select
                    defaultValue={supClassesSelectedValue?.sup_class_name}
                    onChange={handleChangeSupClass}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {supClasses?.map((item: Supplierclass, index: any) => (
                      <Select.Option value={item.sup_class_name} key={index}>
                        {item.sup_class_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Details of Settlement"
                  name="sup_settle_detail"
                  rules={[
                    {
                      required: !resolverHide,
                      message: "Please enter detail of settlement",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleChangeSupSettleDetail} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Amount settled by supplier"
                  name="sup_settle_amount"
                  rules={[
                    {
                      required: false,
                      message: "Please enter amount settled by supplier",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: 200 }}
                    onChange={handleSubSettleAmount}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Currency"
                  name="sup_settle_currency_name"
                  rules={[
                    {
                      required: false,
                      message: "Please enter currency",
                    },
                  ]}
                >
                  <Select
                    defaultValue={
                      supSettleCurrencysSelectedValue?.currency_name
                    }
                    onChange={handleChangeSupSettleCurrency}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {supSettleCurrencys?.map((item: Currency, index: any) => (
                      <Select.Option value={item.currency_name} key={index}>
                        {item.currency_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={24}>
                <Row
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S4" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>PART 6: Root cause investigation</Title>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Human"
                  name="cause_human"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter human",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseHuman} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Material"
                  name="cause_material"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter material",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseMaterial} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Machine"
                  name="cause_machine"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter machine",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseMachine} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Ineffective and inefficient process"
                  name="cause_process"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter process",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseProcess} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="Supplier/Contractor did not performed"
                  name="cause_notperformed"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message:
                        "Please enter Supplier/Contractor did not performed",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseNotperformed} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Lack of skill and training"
                  name="cause_skilltrain"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter Lack of skill and training",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseSkilltrain} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={24}>
                <Form.Item
                  label="Other"
                  name="cause_other"
                  rules={[
                    {
                      required: rootCauseRequire,
                      message: "Please enter other",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleCauseOther} />
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={24}>
                <Divider />

                <Row
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S4" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>PART 7: Resolve with customer</Title>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Title level={5}>7.1 Resolved Approved</Title>
                <Form.Item
                  label="Solution and timeline to resolve the claim/complaint has been agreed (“approved”) by the customer"
                  name="cust_rsv_approve_detail"
                  rules={[
                    {
                      required: !resolverHide,
                      message: "Please enter Resolve-Approved",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    onChange={handleChangeCustRsvApproveDetail}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of having resolve-approved by customer"
                  name="cust_rsv_approve_date"
                  rules={[
                    {
                      required: !resolverHide,
                      message:
                        "Please enter Date of having resolve-approved by customer",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleChangeCustRsvApprovedDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={12}>
                <Title level={5}>7.2 Resolved Finished</Title>
                <Form.Item
                  label="Completed resolving the claim/complaint as per solution and timeline in 7.1
                                    ex. Compensation of claims to customer etc."
                  name="cust_rsv_finish_detail"
                  rules={[
                    {
                      required: !resolverHide,
                      message: "Please enter Resolve-Finished",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    onChange={handleChangeCustRsvFinishDetail}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date of having resolve-finished with customer"
                  name="cust_rsv_finish_date"
                  rules={[
                    {
                      required: !resolverHide,
                      message:
                        "Please enter Date of having resolve-finished with customer",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleChangeCustRsvFinishDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={resolverHide}>
              <Col span={24}>
                <Divider />

                <Row
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S4" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>
                      PART 8: Corrective Actions (prevention)
                    </Title>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="8.1 Corrective Action(s) implemented (prevention)"
                  name="rsv_implement_detail"
                  rules={[
                    {
                      required: wf_status_idParm === "S4" ? true : false,
                      message:
                        "Please enter Supplier/Contractor did notperformed",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleRsvImplementDetail} />
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="8.2 Department Manager"
                  name="rsv_dept_mgr_code"
                  rules={[
                    {
                      required: wf_status_idParm === "S4" ? true : false,
                      message: "Please enter Department Manager",
                    },
                  ]}
                >
                  <Select
                    disabled={wf_status_idParm === "S5" ? true : false}
                    defaultValue={rsvDeptMgrSelectedValue?.employee_id}
                    onChange={handleChangeRsvDeptMgr}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvDeptMgrs?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Department Manager Status"
                  name="rsv_dept_mgr_approve"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Department Manager Status",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={rsvDeptMgrApproveSelectedValue?.id}
                    onChange={handleChangeRsvDeptMgr}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvDeptMgrApproves?.map(
                      (item: Approverejectstatus, index: any) => (
                        <Select.Option value={item.id} key={index}>
                          {item.ap_status_name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row
              hidden={resolverDeptHide}
              style={{
                backgroundColor:
                  wf_status_idParm === "S5" ? headerColor2 : headerColor1,
              }}
            >
              <Col span={24}>
                <Title level={5}>Department Manager</Title>
              </Col>
            </Row>

            <Row hidden={resolverDeptHide}>
              <Col span={12}>
                <Form.Item
                  label="Comments from Department"
                  name="rsv_dept_mgr_comment"
                  rules={[
                    {
                      required: wf_status_idParm === "S5" ? true : false,
                      message: "Please enter Comments from Department",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleRsvDeptMgrComment} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="rsv_dept_mgr_comment_date"
                  rules={[
                    {
                      required: wf_status_idParm === "S5" ? true : false,
                      message: "Please enter date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => handleChangeRsvDeptMgrCommentDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={resolverHide}>
              <Col span={12}>
                <Form.Item
                  label="8.3 Division Manager / Regional Manager" //resolverDivIdHide
                  name="rsv_div_mgr_code"
                  rules={[
                    {
                      // required: wf_status_id === "S5" ? true : false,
                      required: wf_status_idParm === "S4" ? true : false,
                      message: "Please enter Division Manager",
                    },
                  ]}
                >
                  <Select
                    defaultValue={rsvDivMgrSelectedValue?.employee_id}
                    onChange={handleChangeRsvDivMgr}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvDivMgrs?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Division Manager Status"
                  name="rsv_div_mgr_approve"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Division Manager Status",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={rsvDivMgrApproveSelectedValue?.id}
                    onChange={handleChangeRsvDivMgr}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvDivMgrApproves?.map(
                      (item: Approverejectstatus, index: any) => (
                        <Select.Option value={item.id} key={index}>
                          {item.ap_status_name}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row
              hidden={resolverDivHide}
              style={{
                backgroundColor:
                  wf_status_idParm === "S6" ? headerColor2 : headerColor1,
              }}
            >
              <Col span={24}>
                <Title level={5}>Division Manager/Regional Manager</Title>
              </Col>
            </Row>

            <Row hidden={resolverDivHide}>
              <Col span={12}>
                <Form.Item
                  label="Comments from Division Manager/Regional Manager"
                  name="rsv_div_mgr_comment"
                  rules={[
                    {
                      required: wf_status_idParm === "S6" ? true : false,
                      message:
                        "Please enter Comments from DivisionManager/Country Manager",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleRsvDivMgrComment} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="rsv_div_mgr_comment_date"
                  rules={[
                    {
                      required: wf_status_idParm === "S6" ? true : false,
                      message: "Please enter date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => handleChangeRsvDivMgrCommentDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={auditorIdHide}>
              <Col span={24}>
                <Divider />

                <Row
                  style={{
                    backgroundColor:
                      wf_status_idParm === "S7" ? headerColor2 : headerColor1,
                  }}
                >
                  <Col span={24}>
                    <Title level={5}>PART 9: Audit Check</Title>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row hidden={true}>
              <Col span={12}>
                <Form.Item
                  label="QMR verified"
                  name="rsv_qmr_code"
                  rules={[
                    {
                      required: false, //qmrVerifyHide
                      message: "Please enter QMR verified",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={rsvQmrSelectedValue?.employee_id}
                    onChange={handleChangeRsvQmrId}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvQmrs?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={qmrVerifyHide}>
              <Col span={12}>
                <Form.Item
                  label="QMR verified status"
                  name="rsv_qmr_verify"
                  rules={[
                    {
                      required: false,
                      message: "Please enter QMR verified status",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={rsvDivMgrSelectedValue?.id}
                    onChange={handleChangeRsvQmrId}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {rsvQmrVerifys?.map((item: Verifystatus, index: any) => (
                      <Select.Option value={item.id} key={index}>
                        {item.verify_status_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="rsv_qmr_verify_date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => handleChangeRsvQmrVerifyDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={qmrVerifyHide}>
              <Col span={12}>
                <Form.Item
                  label="Comments from QMR"
                  name="rsv_qmr_comment"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Comments from QMR",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleRsvQMRVerifyComment} />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={auditorIdHide}>
              <Col span={12}>
                <Form.Item
                  label="Appointed Auditor"
                  name="auditor_code"
                  rules={[
                    {
                      required: wf_status_idParm === "S7" ? true : false,
                      message: "Please enter Appointed Auditor",
                    },
                  ]}
                >
                  <Select
                    disabled={wf_status_idParm === "S8" ? true : false}
                    defaultValue={auditorsSelectedValue?.employee_id}
                    onChange={handleChangeAuditor}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {auditors?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Within Date"
                  name="auditor_within_date"
                  rules={[
                    {
                      required: wf_status_idParm === "S7" ? true : false,
                      message: "Please enter within date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={wf_status_idParm === "S8" ? true : false}
                    onChange={(e) => handleChangeAuditorWithinDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              hidden={auditorHide}
              style={{
                backgroundColor:
                  wf_status_idParm === "S8" ? headerColor2 : headerColor1,
              }}
            >
              <Col span={24}>
                <Title level={5}>For Auditor</Title>
              </Col>
            </Row>

            <Row hidden={auditorHide}>
              <Col span={12}>
                <Form.Item
                  label="Audit Result"
                  name="auditor_audit_comment"
                  rules={[
                    {
                      required: wf_status_idParm === "S8" ? true : false,
                      message: "Please enter audit results",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleChangeAuditorResult} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Audit Date"
                  name="auditor_audit_date"
                  rules={[
                    {
                      required: wf_status_idParm === "S8" ? true : false,
                      message: "Please enter audit date",
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(e) => handleChangeAuditDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row hidden={auditorHide}>
              <Col span={12}>
                <Form.Item name="auditor_adequate_status" label="">
                  <Radio.Group onChange={handleAuditorAdequateStatus}>
                    <Space direction="vertical">
                      <Radio value={1}>
                        Corrective and preventive actions are adequate
                      </Radio>
                      <Radio value={2}>
                        Corrective and preventive actions are inadequate
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={custsatHide}>
              <Col span={24}>
                <Divider />

                <Row style={{ backgroundColor: "#B4C6E7" }}>
                  <Col span={24}>
                    <Title level={5}>
                      PART 10: Customer Satisfaction (Feedback from Customer)
                    </Title>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row hidden={custsatHide}>
              <Col span={24}>
                <Form.List name="surveylines">
                  {(surveylines, { add, remove }) => {
                    return (
                      <SurveyLineList
                        surveylines={surveylines}
                        add={add}
                        remove={remove}
                      />
                    );
                  }}
                </Form.List>
              </Col>
            </Row>
            {/* <Row hidden={custsatHide}>
                            <Col span={12}>
                                <Form.Item
                                    label="Satisfaction"
                                    name="custsat_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Satisfaction'
                                        }
                                    ]}>
                                    <Input onChange={handleChangeCustSatisfactionDetail} />
                                </Form.Item>
                            </Col>


                        </Row> */}
            <Row hidden={custsatHide}>
              <Col span={12}>
                <Form.Item
                  label="Comments from CRM"
                  name="custsat_comment"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Comments from CRM",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleChangeCustSatComment} />
                </Form.Item>
              </Col>
            </Row>

            <Row
              hidden={closedQmrHide}
              style={{
                backgroundColor:
                  wf_status_idParm === "S10" ? headerColor2 : headerColor1,
              }}
            >
              <Col span={24}>
                <Title level={5}>For QMR close </Title>
              </Col>
            </Row>

            <Row hidden={closedQmrHide}>
              <Col span={12}>
                <Form.Item
                  label="QMR closed "
                  name="close_qmr_code"
                  rules={[
                    {
                      required: false,
                      message: "Please enter QMR closed",
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    defaultValue={closeQmrSelectedValue?.employee_id}
                    onChange={handleChangeCloseQmrId}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // filterSort={(optionA: any, optionB: any) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                  >
                    {closeQmrs?.map((item: Employee, index: any) => (
                      <Select.Option value={item.employee_id} key={index}>
                        {item.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Date"
                  name="close_qmr_date"
                  rules={[
                    {
                      required: false,
                      message: "Please enter date",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={true}
                    onChange={(e) => handleChangeCloseQmrDate(e)}
                    format={dateFormat}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row hidden={closedQmrHide}>
              <Col span={12}>
                <Form.Item
                  label="Comments from QMR"
                  name="close_qmr_comment"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Comments from QMR",
                    },
                  ]}
                >
                  <TextArea rows={4} onChange={handleChangeCloseQmrComment} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Title level={5}>Attached File</Title>

            <DriveUpload
              closed={closed}
              ref_doc_id={cardocid}
              page={"AddEdit"}
              drive_name={"cars"}
            />

            <Row>
              <Col span={4}></Col>
              <Col span={20} className="text-right">
                <Form.Item
                  style={{ visibility: "hidden" }}
                  label="id"
                  name="id"
                  rules={[
                    {
                      required: false,
                      message: "Please enter id",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  {id ? (
                    <>
                      <Button
                        type="default"
                        onClick={(e) =>
                          showModalUpdateAll(
                            e,
                            "update all",
                            wf_status_idParm as string,
                            1,
                            0
                          )
                        }
                      >
                        Update all by QMR
                      </Button>{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {wf_status_idParm === "S2" ||
                      wf_status_idParm === "S3" ||
                      wf_status_idParm === "S5" ||
                      wf_status_idParm === "S6" ||
                      wf_status_idParm === "S7" ||
                      wf_status_idParm === "S8" ||
                      wf_status_idParm === "S10" ? (
                        <>
                          {wf_status_idParm !== "S8" &&
                            wf_status_idParm !== "S10" && (
                              <>
                                <Button
                                  type="default"
                                  onClick={(e) =>
                                    showModal(
                                      e,
                                      "Reject",
                                      wf_status_idParm as string,
                                      2,
                                      0
                                    )
                                  }
                                >
                                  Reject
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </>
                            )}

                          {/* <Button
                            type="primary"
                            onClick={(e) =>
                              showModal(
                                e,
                                approveButtonName,
                                wf_status_idParm as string,
                                1,
                                0
                              )
                            }
                          >
                            {approveButtonName}
                          </Button> */}

                          <Popconfirm
                            title={
                              "Are you sure " +
                              approveButtonName.toLowerCase() +
                              "?"
                            }
                            onConfirm={(e) =>
                              showModal(
                                e,
                                approveButtonName,
                                wf_status_idParm as string,
                                1,
                                0
                              )
                            }
                            onCancel={cancelApprove}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="primary">{approveButtonName}</Button>
                          </Popconfirm>
                        </>
                      ) : (
                        <Button
                          hidden={
                            wfStatusId === "S1" || wf_status_idParm === "S4"
                              ? false
                              : true
                          }
                          type="primary"
                          onClick={(e) =>
                            showModal(e, "Submit", wfStatusId, 0, 1)
                          }
                        >
                          Submit
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        type="default"
                        onClick={(e) => showModal(e, "Save Draft", "S1", 0, 0)}
                      >
                        Save Draft
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        type="primary"
                        onClick={(e) =>
                          showModal(e, "Submit and Send Email", "S2", 0, 0)
                        }
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal
        title="Confirm"
        visible={visible}
        onOk={formCreateEdit.submit}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Are you sure to {actionName} ?</p>

        <Space size="middle" style={{ display: loading ? "block" : "none" }}>
          <Spin size="large" />
          Creating...
        </Space>
      </Modal>

      <Modal
        title="Success"
        visible={visibleSuccess}
        onOk={handleSuccess}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Space size="middle">
          <p style={{ fontSize: "24px" }}>
            {" "}
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "50px" }}
            />{" "}
            CAR No. {cardocnewId}
          </p>
        </Space>
      </Modal>
      <Modal
        title="Workflow updating"
        visible={visibleUpdateWorkflowSuccess}
        onOk={handleUpdateWorkflowSuccessClose}
        confirmLoading={confirmLoadingWorkflowStatus}
        onCancel={handleUpdateWorkflowCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        {msgAlert && <Alert message={msgAlert} type="success" />}
        Workflow:
        <br />
        <Select
          style={{ width: "100%" }}
          defaultValue={wf_status_idParm}
          onChange={handleUpdateWfStatusByQmr}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // filterSort={(optionA: any, optionB: any) =>
          //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
        >
          {wfStatuses?.map((item: Workflowstatus, index: any) => (
            <Select.Option value={item.wf_status_id} key={index}>
              {item.wf_status_id} - {item.wf_status_name}
            </Select.Option>
          ))}
        </Select>
        <Button
          onClick={handleUpdateWorkflowSuccess}
          type="primary"
          size="small"
          loading={loadingUpdate}
        >
          Submit
        </Button>
      </Modal>

      <Modal
        title="Repeated Case updating"
        visible={visibleUpdateRepeatedCase}
        onOk={handleUpdateRepeatedCaseClose}
        confirmLoading={confirmLoadingRepeatedCaseStatus}
        onCancel={handleUpdateRepeatedCaseCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        {msgAlert && <Alert message={msgAlert} type="success" />}
        Repeated Case :<br />
        <Radio.Group
          onChange={handleUpdateRepeatedCaseByQmr}
          value={repeatedCase}
        >
          <Radio value={1}>Yes</Radio>
          <Radio value={2}>No</Radio>
        </Radio.Group>
        <Button
          onClick={handleUpdateRepeatedCaseSuccess}
          type="primary"
          size="small"
          loading={loadingUpdate}
        >
          Submit
        </Button>
      </Modal>
      <Modal
        title="Root Cause Analysis by QMR updating"
        visible={visibleUpdateRootCause}
        onOk={handleUpdateRootCauseClose}
        confirmLoading={confirmLoadingRepeatedCaseStatus}
        onCancel={handleUpdateRootCauseCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        {msgAlert && <Alert message={msgAlert} type="success" />}
        NC Type :<br />
        <Select
          style={{ width: "100%" }}
          defaultValue={ncTypeName}
          onChange={handleUpdateNcTypeByQmr}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // filterSort={(optionA: any, optionB: any) =>
          //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
        >
          {nctypes?.map((item: Nctype, index: any) => (
            <Select.Option value={item.name} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        NC Sub Type :<br />
        <Select
          style={{ width: "100%" }}
          defaultValue={ncsubTypeName}
          onChange={handleUpdateNcsubTypeByQmr}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // filterSort={(optionA: any, optionB: any) =>
          //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
        >
          {ncsubtypes?.map((item: Ncsubtype, index: any) => (
            <Select.Option value={item.name} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        Root Cause :<br />
        <Select
          style={{ width: "100%" }}
          defaultValue={rootCauseName}
          onChange={handleUpdateRootCauseByQmr}
          showSearch
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // filterSort={(optionA: any, optionB: any) =>
          //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          // }
        >
          {rootcauses?.map((item: Rootcause, index: any) => (
            <Select.Option value={item.name} key={index}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        <br />
        <Button
          onClick={handleUpdateRootCauseSuccess}
          type="primary"
          size="small"
          loading={loadingUpdate}
        >
          Submit
        </Button>
      </Modal>
      {/* <Modal
                title="Add Contact"
                visible={visibleUploadFile}
                onOk={handleUploadSuccess}
                confirmLoading={confirmLoading}
                onCancel={handleUploadCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Space size="middle">
                    <Upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" directory>
                        <Button type="default" icon={<UploadOutlined />}>Choose File</Button>
                    </Upload>


                </Space>

            </Modal> */}
    </>
  );
};

export default DomainsCarsAddEdit;
