import {useContext} from 'react'
import {Row,Col,Space,Button,message} from 'antd'
import { Link } from 'react-router-dom'
import {Car, requestCarById} from '../../hooks/car'
// import {requestDeleteCarById} from '../../hooks/car'
import UserContext from '../../contexts/UserContext'
import './DriveItem.css'
import CarContext from '../../contexts/CarContext'
import { Drive, requestDeleteFileByFileId } from '../../hooks/drive'


type DriveItemProps = {
    drive: Drive
    page : string | undefined

    ChangeDriveData: (arg:string) => void;

    closed: boolean | false
   
}



const DriveItem = ({drive,page,ChangeDriveData,closed}: DriveItemProps) => {

    const {accessToken,setDrives,drives} = useContext(UserContext)
    const {setNeedLoadCars} = useContext(CarContext)

    const handleFileRemove = async(fileId:string) => {
       
      try {
          const data = await requestDeleteFileByFileId(accessToken,fileId)
        
         let drivesFilter=  drives.filter((d)=>{
              return d.file_id !== fileId
          })

          ChangeDriveData(fileId)
          setDrives(drivesFilter)
        //onSuccess("Ok");
        console.log("server res: ", data);
      } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        //onError({ err });
      }
    }
    const handleFileView  = (fileurl:string) => {
        window.open(fileurl,"_blank")
    }
    
    return (
        <div className="article-item">
            <Row>
                <Col span={12}><a href="#" onClick={() => handleFileView(drive.file_url)}>{drive.orig_file_name}</a></Col>
                <Col span={12} className="text-right">
                    <Space>
                   {page !== "detail" && (
                       <>
          
                    <Button disabled={closed===true?true:false} type="default" onClick={() => handleFileRemove(drive.file_id)}>Delete</Button>
                       </>
                   )}
                    
                    </Space>
                </Col>
            </Row>
            <Row>
                {/* <Col span={24}>{car.topic}</Col> */}
           
            </Row>
        </div>
    )
}

export default DriveItem