import { useEffect, useContext, useState } from 'react'
import { Row, Col, Typography, Button, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { requestArticles, Article } from '../../../hooks/article'
import UserContext from '../../../contexts/UserContext'
import ArticleList from '../../../components/ArticleList'
import ArticleContext from '../../../contexts/ArticleContext'


const { Title } = Typography

const DomainsCompanysList = () => {

    // const { accessToken } = useContext(UserContext)
    // const { needLoadArticles, setNeedLoadArticles } = useContext(ArticleContext)
    // const [articles, setArticles] = useState<Article[]>([])

    // const getArticles = async () => {
    //     const data = await requestArticles({}, accessToken)

    //     setArticles(data)

    // }
    // useEffect(() => {
    //     getArticles()
    // }, [])

    // useEffect(() => {
    //     if (needLoadArticles) {
    //         getArticles()
    //         setNeedLoadArticles(false)
    //     }

    // }, [needLoadArticles])


    return (
        <>
        Companys
            {/* <Row justify="space-between" align="middle">
                <Col span={18} >
                    <Title level={1} className="no-margin">Articles</Title>
                </Col>
                <Col span={6} className="text-right">
                    <Button type="primary"></Button>
                    <Link to={"/articles/add-edit"}>Create New</Link>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    <ArticleList articles={articles}></ArticleList>
                </Col>
            </Row> */}
        </>
    )
}

export default DomainsCompanysList