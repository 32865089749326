import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Workflowstatus {
    id: number
    wf_status_id: string
    wf_status_name: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestWorkflowstatus = async(conditions: object = {},accessToken: string): Promise<Workflowstatus[]> => {
    // const data:Docstatus[] = await axios
    // .get(`${REACT_APP_MASTERDATAS_API_URL}/countrys/all?access_token=${accessToken}`,{
    //     // headers: {
    //     //     Authorization: `Bearer ${accessToken}`
    //     // }
    // })
    // .then(response => response.data)
    // .catch(error => handleResponseError(error))
    let data:Workflowstatus[] = []
    data.push({
        id: 1,
        wf_status_id: "S1",
        wf_status_name: "Saved Draft"
    })
    data.push({
        id: 2,
        wf_status_id: "S2",
        wf_status_name: "Issuer has created"
    })
    data.push({
        id: 3,
        wf_status_id: "S3",
        wf_status_name: "Issuer’s Dept.Mgr. has reviewed"
    })
    data.push({
        id: 4,
        wf_status_id: "S4",
        wf_status_name: "Issuer’s Div.Mgr. has reviewed"
    })
    data.push({
        id: 5,
        wf_status_id: "S5",
        wf_status_name: "Resolver (PIC)​ has resolved"
    })
    data.push({
        id: 6,
        wf_status_id: "S6",
        wf_status_name: "Resolver’s Dept.Mgr.​ has reviewed"
    })
    data.push({
        id: 7,
        wf_status_id: "S7",
        wf_status_name: "Resolver’s Div.Mgr.​ has reviewed"
    })
    data.push({
        id: 8,
        wf_status_id: "S8",
        wf_status_name: "QMR / QA​ has verified"
    })
    data.push({
        id: 9,
        wf_status_id: "S9",
        wf_status_name: "Audited​"
    })
    data.push({
        id: 10,
        wf_status_id: "S10",
        wf_status_name: "CRM Cus.Sat. survey done​"
    })

    data.push({
        id: 11,
        wf_status_id: "S11",
        wf_status_name: "Closed by QMR​"
    })

    return data
}

