import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Employee {
    id: number
    employee_id: string
    first_name: string
    last_name: string
    email: string
    fullname: string
    department_name: string,
    division_name: string,
    business_name: string,
    country_name: string,
    company_name: string,
   
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestEmployees = async(conditions: object = {},accessToken: string): Promise<Employee[]> => {
    const data:Employee[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/employees/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

export const requestEmployeeByEmployeeId = async(conditions: object = {},accessToken: string,employee_id: string): Promise<Employee> => {
    const data:Employee = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/employees/${employee_id}?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    

    return data
}