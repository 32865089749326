import CarItem, { Car } from '../CarItem'
import { Table, Tag, Space } from 'antd';
import { Link } from 'react-router-dom';
import intlFormat from 'date-fns/esm/fp/intlFormat/index.js';
import { useState } from "react"
import moment from 'moment';

type CarListProps = {
  cars: Car[],
  totalItems: number,
  totalPages: number,
  currentPage: number,
  page: number,
  pageSize: number,
  loading: boolean,
  handleReloadPagination: (_page: number, _pageSize: number) => void,


}


const CarList = ({ cars, totalItems, totalPages, currentPage, page, pageSize, loading, handleReloadPagination }: CarListProps) => {




  const columns = [
    // {
    //   title: '#',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    // {
    //   title: '#',
    //   key: 'index',
    //   render: (text:any, record:any,index:any) => ((page=1?0:page-1)*pageSize)+index + 1,
    // },
    {
      title: 'CAR No.',
      dataIndex: 'car_doc_id',
      key: 'car_doc_id',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`/cars/detail/${record.id}`}>{text}</Link>
        </Space>
      ),
    },
    {
      title: 'Topic',
      dataIndex: 'prob_topic',
      key: 'prob_topic',
      // render: (text:any) => {text},
    },
    {
      title: 'Product',
      dataIndex: 'prob_product_name',
      key: 'prob_product_name',
      // render: (text:any) => {text},
    },
    {
      title: 'Customer',
      dataIndex: 'cust_company_name',
      key: 'cust_company_name',
      // render: (text:any) => {text},
    },
    {
      title: 'Category',
      dataIndex: 'car_category_name',
      key: 'car_category_name',
      // render: (text:any) => {text},
    },
    {
      title: 'Created by',
      dataIndex: 'issuer_name',
      key: 'issuer_name',
      //render: (text:any) => {text},
    },
    {
      title: "Created Date",
      dataIndex: 'created_datetime',
      key: 'created_datetime',
      render: (text:any) => { return (<p>{moment(text).format('DD-MM-YYYY')}</p>)},
    },
    {
      title: 'Status',
      dataIndex: 'wf_status_name',
      key: 'wf_status_name',
      render: (text: any, record: any) => {
          return (<p>{record.wf_status_id} - {record.wf_status_name}</p>)
      }
    },
    {
      title: "Issuer's country",
      dataIndex: 'issuer_country_name',
      key: 'issuer_country_name',
      // render: (text:any) => {text},
    },
    //
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={`/cars/detail/${record.id}`}>View</Link>
        </Space>
      ),
    },

  ];



  // const carListRender = cars.map((car, index) => (

  //     <CarItem key={`car-${index}`}
  //         car={car}
  //     />

  // ))
  return (
    <div>
      <Table columns={columns} dataSource={cars}
        loading={loading}
        scroll={{ x: 'calc(700px + 50%)', y: 480 }}
        //rowClassName={(record, index) => (record.amount > 50 ? "red" : "green")}
        pagination={{
          current: page, pageSize: pageSize, total: totalItems, showSizeChanger: false,
          onChange: (page: any, pageSize: any) => {
            handleReloadPagination(page, pageSize)
          }
        }}
      />
      {/* {carListRender.length ? carListRender : 'There are no cars'} */}
    </div>
  )
}

export default CarList