import axios from 'axios'
import fetch from 'node-fetch'



const { REACT_APP_OAUTH_API_URL,REACT_APP_WITH_CREDENTIALS } = process.env


export interface CookieResponse{
    Access_token: string,
    User_id: number
}
export interface Jwtdata{
    authorized: boolean,
    access_token: string,
    user_id: number,
    email: string,
    role_id: number,
    car_role_id: number,
    exp: number,
    token: string,
    employee_id: string
}


const handleResponseError = (error: any) => {
    //? = protect data no value

    if (error?.response?.data?.error) {
        throw error.response.data.error
    }
    throw error
}


export const requestGetCookieToJwtdata = async (): Promise<Jwtdata> => {
    const data: Jwtdata = await axios.get(`${REACT_APP_OAUTH_API_URL}/oauth/jwtdatabycookie`, {
        withCredentials: REACT_APP_WITH_CREDENTIALS=="true"? true:false
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

   
    return data
}
export const requestGetJwtdata = async (token:string): Promise<Jwtdata> => {
    const data: Jwtdata = await axios.get(`${REACT_APP_OAUTH_API_URL}/oauth/jwtdata/${token}`, {
        withCredentials: REACT_APP_WITH_CREDENTIALS=="true"? true:false
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    console.log(data)

    return data
}
export const requestLogout = async (token:string): Promise<boolean> => {
    const data: any = await axios.get(`${REACT_APP_OAUTH_API_URL}/oauth/logout?access_token=${token}`, {
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    console.log("data")

    console.log(data)

    return data
}
