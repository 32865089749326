import React, { useContext } from 'react';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    QuestionCircleOutlined,
    GlobalOutlined,
    BellOutlined,
    UserOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Badge } from 'antd';
// import './LayoutBanner.less';
import UserAvatar from '../UserAvatar/UserAvatar';
import LogoDark from '../../assets/logo-dark.png'
import { If, Else, Then } from 'react-if'
import { useHistory } from 'react-router-dom';
import UserContext from '../../contexts/UserContext'
import { UserResponse } from '../../types/hooks/user';


import { useCookies } from 'react-cookie'
import { requestLogout } from '../../hooks/oauth';
const { REACT_APP_ACCESS_TOKEN_COOKIE, REACT_APP_USER_ID_COOKIE, REACT_APP_DOMAIN_NAME, REACT_APP_ENV } = process.env


const { Header } = Layout;
const { SubMenu } = Menu;

type LayoutBannerProps = {
    collapsed: boolean
    handleOnCollapse: () => void
    loggedin: boolean
};
const LayoutBanner: React.FC<LayoutBannerProps> = ({
    collapsed, handleOnCollapse, loggedin
}) => {

    const [cookies, removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE!])
    const { user, accessToken } = useContext(UserContext)

    const {
        setIsLoggedIn,
        setAccessToken,
        setUser
    } = useContext(UserContext)
    const history = useHistory();
    //const LayoutBanner = (collapsed: any, handleOnCollapse: any) => {
    const getCollapseIcon = () => {
        if (collapsed) {
            return (
                <MenuUnfoldOutlined onClick={handleOnCollapse} className="trigger" />
            );
        }
        return <MenuFoldOutlined onClick={handleOnCollapse} className="trigger" />;
    };

    const handleLanguageMenuClick = () => { };
    const handleSettingMenuClick = () => { };
    const handleLogout = async () => {

        if (REACT_APP_ENV === "prod") {
            try
            {
                const data = await requestLogout(accessToken)
            }catch(err:any){
            

            }
            removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE!, "")
            removeCookie(REACT_APP_USER_ID_COOKIE!, "")
            setAccessToken('')
            setIsLoggedIn(false)
            setUser({} as UserResponse)
            //return history.push('/login')
            return window.open(REACT_APP_DOMAIN_NAME, "_self")
        } else {
            removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE!, "")
            removeCookie(REACT_APP_USER_ID_COOKIE!, "")
            setAccessToken('')
            setIsLoggedIn(false)
            setUser({} as UserResponse)
            return history.push('/login')
        }
      


    };

    return (
        <Header className="header" style={{ background: '#fff', padding: 0 }}>
            <div
                style={{
                    float: 'left',
                    width: '100%',
                    alignSelf: 'center',
                    display: 'flex',
                }}
            >
                <If condition={loggedin}>
                    <Then>
                        {window.innerWidth > 992 && getCollapseIcon()}
                    </Then>
                    <Else>
                        <div style={{ margin: '20px' }}>
                            <img src={LogoDark} />
                        </div>
                    </Else>
                </If>


            </div>
            <If condition={loggedin}>
                <Then>
                    {/* <Menu
                onClick={handleLanguageMenuClick}
                mode="horizontal"
                className="menu"
            >
                <SubMenu title={<QuestionCircleOutlined />} />
            </Menu> */}
                    {/* <Menu
                onClick={handleLanguageMenuClick}
                mode="horizontal"
                className="menu"
            >
                <SubMenu
                    title={
                        <Badge dot>
                            <BellOutlined />
                        </Badge>
                    }
                />
            </Menu> */}
                    {/* <Menu
                onClick={handleLanguageMenuClick}
                mode="horizontal"
                className="menu"
            >
                <SubMenu title={<GlobalOutlined />}>
                    <Menu.Item key="en">
                        <span role="img" aria-label="English">
                            🇺🇸 English
                        </span>
                    </Menu.Item>
                    <Menu.Item key="it">
                        <span role="img" aria-label="Italian">
                            🇮🇹 Italian
                        </span>
                    </Menu.Item>
                </SubMenu>
            </Menu> */}
                    <Menu onClick={handleSettingMenuClick} mode="horizontal" className="menu">
                        <SubMenu title={UserAvatar(loggedin === true && user.email ? user.email : "")}>
                            <Menu.Item key="setting:1">
                                <span>
                                    <UserOutlined />
                                    {loggedin && (
                                        user.email
                                    )}
                                </span>
                            </Menu.Item>
                            <Menu.Item key="setting:2" onClick={handleLogout} >
                                <span>
                                    <LogoutOutlined />
                                    Logout
                                </span>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Then>
                <Else>

                </Else>
            </If>
        </Header>
    );
}

export default LayoutBanner;