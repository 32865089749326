import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Country {
    id: string
    country_id: string
    country_name: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestCountrys = async(conditions: object = {},accessToken: string): Promise<Country[]> => {
    const data:Country[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/countrys/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

