import React from 'react'

const DomainsHome: React.FC = () => {
    return (
        <>
        Dashboard

        </>
    )
}

export default DomainsHome