import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Supplier {
    id: string
    sup_id: string
    sup_name: string
    sup_class_id: number
    sup_search:string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestSuppliers = async(conditions: object = {},accessToken: string): Promise<Supplier[]> => {
    const data:Supplier[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/suppliers/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))
    console.log("supplier")

    console.log(data)

    return data
}

