import { useContext } from 'react'
import { Row, Col, Space, Button, message } from 'antd'
import { Link } from 'react-router-dom'
import { Car, requestCarById } from '../../hooks/car'
// import {requestDeleteCarById} from '../../hooks/car'
import UserContext from '../../contexts/UserContext'
import './DriveItem.css'
import CarContext from '../../contexts/CarContext'
import { Drive } from '../../hooks/drive'
import DriveItem from '../DriveItem'


type DriveListProps = {
    drives: Drive[]
    page: string | undefined
    ChangeDriveData: (arg:string) => void
    closed: boolean | false
   
}


const DriveList = ({ drives,page,ChangeDriveData,closed }: DriveListProps) => {

    const { accessToken } = useContext(UserContext)
    const { setNeedLoadCars } = useContext(CarContext)

    

    const driveListRender = drives.map((drive, index) => (
        
   
        <DriveItem closed={closed} ChangeDriveData={ChangeDriveData} key={`drive-${index}`}
            drive={drive} page={page}
        />
   

    ))
    console.log("drives")
    console.log(drives)
 
    return (
        <div>
            {driveListRender.length ? driveListRender : 'There are no file'}
        </div>
    )
}

export default DriveList