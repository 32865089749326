import { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Form,
  Input,
  message,
  Select,
  Modal,
  InputNumber,
  Spin,
  Checkbox,
  Upload,
  Radio,
} from "antd";
import { Link } from "react-router-dom";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { Car, requestCarById } from "../../hooks/car";
// import {requestDeleteCarById} from '../../hooks/car'
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import UserContext from "../../contexts/UserContext";
import "./CarDetail.css";
import CarContext from "../../contexts/CarContext";
import { Docstatus, requestDocStatuss } from "../../hooks/docstatus";
import { Carstandard, requestCarstandards } from "../../hooks/carstandard";
import { Carcategory, requestCarcategorys } from "../../hooks/carcategory";
import { Cartype, requestCartypes } from "../../hooks/cartype";
import { Employee, requestEmployees } from "../../hooks/employee";
import { Company, requestCompanys } from "../../hooks/company";
import {
  Divisiondirector,
  requestDivisiondirectors,
} from "../../hooks/divisiondirector";
import { Division, requestDivisions } from "../../hooks/division";
import { Department, requestDepartments } from "../../hooks/department";
import { Country, requestCountrys } from "../../hooks/country";
import { Customer, requestCustomers } from "../../hooks/customer";
import {
  Customercontact,
  requestCustomercontacts,
} from "../../hooks/customercontact";
import { Customerclass, requestCustClasses } from "../../hooks/customerclass";
import {
  Carproblemtype,
  requestCarproblemtypes,
} from "../../hooks/carproblemtype";
import { Currency, requestCurrencys } from "../../hooks/currency";
import { requestSuppliers, Supplier } from "../../hooks/supplier";
import {
  requestSupplierclasses,
  Supplierclass,
} from "../../hooks/supplierclass";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  requestWorkflowstatus,
  Workflowstatus,
} from "../../hooks/workflowstatus";
import {
  Approverejectstatus,
  requestApproverejectstatus,
} from "../../hooks/approverejectstatus";
import { requestVerifystatus, Verifystatus } from "../../hooks/verifystatus";
import {
  requestCustsatGetSurveyLines,
  SurveyLineResponse,
  SurveyTableResponse,
} from "../../hooks/custsat";
import SurveyLineList from "../SurveyLineList";
import axios from "axios";
import { format } from "date-fns";
import { isDefaultDate } from "../../utils/date";
import ConfirmationModal from "../ConfirmationModal";

const { Title } = Typography;
type CarItemProps = {
  car: Car;
};
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Bangkok");
const JustCheckboxGroup = Checkbox.Group;
const StandardCheckboxGroup = Checkbox.Group;

const standardplainOptions = ["ISO9001", "ISO14001", "OHSAS18001", "ISO27001"];
const justplainOptions = [
  "Photograph",
  "Sampling",
  "Site visiting report",
  "Survey report",
  "Other",
];

const styleReject = {
  color: "white",
  backgroundColor: "#FF6666",
};

export type { Car };
const dateFormat = "YYYY-MM-DD";
const CarDetail = ({ car }: CarItemProps) => {
  console.log("car");
  console.log(car);
  let dataArray: any = [];

  const [resendSurveyModalVisible, setResendSurveyModalVisible] =
    useState(false);

  const { accessToken } = useContext(UserContext);
  const { setNeedLoadCars } = useContext(CarContext);
  const [formCreateEdit] = Form.useForm(); //react hook form
  const [AddEditStatus, setAddEditStatus] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);
  const [newId, setNewId] = useState(0);
  const [cardocnewId, setCarDocNewId] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionName, setActionName] = useState("");

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  //workflow
  const [wfStatuses, setWkStatuses] = useState<Workflowstatus[] | undefined>(
    undefined
  );
  const [wfStatusesSelectedValue, setWkStatusesSelectedValue] = useState<
    Workflowstatus | undefined
  >(undefined);
  //Issuer

  const [docStatus, setDocStatus] = useState<Docstatus[] | undefined>(
    undefined
  );
  const [docStatusSelectedValue, setDocStatusSelectedValue] = useState<
    Docstatus | undefined
  >(undefined);

  const [carstandards, setCarstandards] = useState<Carstandard[] | undefined>(
    undefined
  );
  const [carstandardsSelectedValue, setCarstandardsSelectedValue] = useState<
    Carstandard | undefined
  >(undefined);
  const [carcategorys, setCarcategorys] = useState<Carcategory[] | undefined>(
    undefined
  );
  const [carcategorysSelectedValue, setCarcategorysSelectedValue] = useState<
    Carcategory | undefined
  >(undefined);

  const [cartypes, setCartypes] = useState<Cartype[] | undefined>(undefined);
  const [cartypesSelectedValue, setCartypesSelectedValue] = useState<
    Cartype | undefined
  >(undefined);
  const [issuers, setIssuers] = useState<Employee[] | undefined>(undefined);
  const [issuersSelectedValue, setIssuersSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [issuerCompanys, setIssuerCompanys] = useState<Company[] | undefined>(
    undefined
  );
  const [issuerCompanysSelectedValue, setIssuerCompanysSelectedValue] =
    useState<Company | undefined>(undefined);
  const [issuerDivisionDirectors, setIssuerDivisionDirectors] = useState<
    Divisiondirector[] | undefined
  >(undefined);
  const [
    issuerDivisionDirectorsSelectedValue,
    setIssuerDivisionDirectorsSelectedValue,
  ] = useState<Divisiondirector | undefined>(undefined);
  const [issuerDivisions, setIssuerDivisions] = useState<
    Division[] | undefined
  >(undefined);
  const [issuerDivisionsSelectedValue, setIssuerDivisionsSelectedValue] =
    useState<Division | undefined>(undefined);
  const [issuerDepartments, setIssuerDepartments] = useState<
    Department[] | undefined
  >(undefined);
  const [issuerDepartmentsSelectedValue, setIssuerDepartmentsSelectedValue] =
    useState<Department | undefined>(undefined);
  const [issuerCountrys, setIssuerCountrys] = useState<Country[] | undefined>(
    undefined
  );
  const [issuerCountrysSelectedValue, setIssuerCountrysSelectedValue] =
    useState<Country | undefined>(undefined);

  const [issuerDeptMgrs, setIssuerDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [issuerDeptMgrSelectedValue, setIssuerDeptMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  const [issuerDeptMgrApproves, setIssuerDeptMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [
    issuerDeptMgrApproveSelectedValue,
    setIssuerDeptMgrApproveSelectedValue,
  ] = useState<Approverejectstatus | undefined>(undefined);

  const [issuerDivMgrs, setIssuerDivMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [issuerDivMgrSelectedValue, setIssuerDivMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  const [issuerDivMgrApproves, setIssuerDivMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [
    issuerDivMgrApproveSelectedValue,
    setIssuerDivMgrApproveSelectedValue,
  ] = useState<Approverejectstatus | undefined>(undefined);

  //Part 1
  const [custCompanys, setCustCompanys] = useState<Customer[] | undefined>(
    undefined
  );
  const [custCompanysSelectedValue, setCustCompanysSelectedValue] = useState<
    Customer | undefined
  >(undefined);
  const [custContacts, setCustContacts] = useState<
    Customercontact[] | undefined
  >(undefined);
  const [custContactsSelectedValue, setCustContactsSelectedValue] = useState<
    Customercontact | undefined
  >(undefined);

  const [custClasses, setCustClasses] = useState<Customerclass[] | undefined>(
    undefined
  );
  const [custClassesSelectedValue, setCustClassesSelectedValue] = useState<
    Customerclass | undefined
  >(undefined);
  const [custCountrys, setCustCountrys] = useState<Country[] | undefined>(
    undefined
  );
  const [custCountrysSelectedValue, setCustCountrysSelectedValue] = useState<
    Country | undefined
  >(undefined);

  //Part 2
  const [probTypes, setProbTypes] = useState<Carproblemtype[] | undefined>(
    undefined
  );
  const [probTypesSelectedValue, setProbTypesSelectedValue] = useState<
    Carproblemtype | undefined
  >(undefined);
  const [probClaimCurrencys, setProbClaimCurrencys] = useState<
    Currency[] | undefined
  >(undefined);
  const [probClaimCurrencysSelectedValue, setProbClaimCurrencysSelectedValue] =
    useState<Currency | undefined>(undefined);

  //Part 3
  // const [justcheckedList, setJustCheckedList] = useState([]);
  const [standardcheckedList, setStandardCheckedList] = useState([]);
  const [prejustDocIdArray, setPrejustDocIdArray] = useState([]);
  const [preDeptMgrs, setPreDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [preDeptMgrsSelectedValue, setPreDeptMgrsSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  //Part 4
  const [resolvers, setResolvers] = useState<Employee[] | undefined>(undefined);
  const [resolversSelectedValue, setResolversSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [resolverCompanys, setResolverCompanys] = useState<
    Company[] | undefined
  >(undefined);
  const [resolverCompanysSelectedValue, setResolverCompanysSelectedValue] =
    useState<Company | undefined>(undefined);
  const [resolverDivdirs, setResolverDivdirs] = useState<
    Divisiondirector[] | undefined
  >(undefined);
  const [resolverDivdirsSelectedValue, setResolverDivdirsSelectedValue] =
    useState<Divisiondirector | undefined>(undefined);
  const [resolverDivs, setResolverDivs] = useState<Division[] | undefined>(
    undefined
  );
  const [resolverDivsSelectedValue, setResolverDivsSelectedValue] = useState<
    Division | undefined
  >(undefined);
  const [resolverDepts, setResolverDepts] = useState<Department[] | undefined>(
    undefined
  );
  const [resolverDeptsSelectedValue, setResolverDeptsSelectedValue] = useState<
    Department | undefined
  >(undefined);
  const [resolverCountrys, setResolverCountrys] = useState<
    Country[] | undefined
  >(undefined);
  const [resolverCountrysSelectedValue, setResolverCountrysSelectedValue] =
    useState<Country | undefined>(undefined);

  //Part 5
  const [supCompanys, setSupCompanys] = useState<Supplier[] | undefined>(
    undefined
  );
  const [supCompanysSelectedValue, setSupCompanysSelectedValue] = useState<
    Supplier | undefined
  >(undefined);
  const [supClasses, setSupClasses] = useState<Supplierclass[] | undefined>(
    undefined
  );
  const [supClassesSelectedValue, setSupClassesSelectedValue] = useState<
    Supplierclass | undefined
  >(undefined);
  const [supSettleCurrencys, setSupSettleCurrencys] = useState<
    Currency[] | undefined
  >(undefined);
  const [supSettleCurrencysSelectedValue, setSupSettleCurrencysSelectedValue] =
    useState<Currency | undefined>(undefined);

  //Part 7
  const [rsvDeptMgrs, setRsvDeptMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [rsvDeptMgrSelectedValue, setRsvDeptMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  const [rsvDivMgrs, setRsvDivMgrs] = useState<Employee[] | undefined>(
    undefined
  );
  const [rsvDivMgrSelectedValue, setRsvDivMgrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  const [rsvDeptMgrApproves, setRsvDeptMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [rsvDeptMgrApproveSelectedValue, setRsvDeptMgrApproveSelectedValue] =
    useState<Approverejectstatus | undefined>(undefined);

  const [rsvDivMgrApproves, setRsvDivMgrApproves] = useState<
    Approverejectstatus[] | undefined
  >(undefined);
  const [rsvDivMgrApproveSelectedValue, setRsvDivMgrApproveSelectedValue] =
    useState<Approverejectstatus | undefined>(undefined);

  const [rsvQmrs, setRsvQmrs] = useState<Employee[] | undefined>(undefined);
  const [rsvQmrSelectedValue, setRsvQmrSelectedValue] = useState<
    Employee | undefined
  >(undefined);
  const [rsvQmrVerifys, setRsvQmrVerifys] = useState<
    Verifystatus[] | undefined
  >(undefined);
  const [rsvQmrVerifySelectedValue, setRsvQmrVerifySelectedValue] = useState<
    Verifystatus | undefined
  >(undefined);

  //Part 8
  const [auditors, setAuditors] = useState<Employee[] | undefined>(undefined);
  const [auditorsSelectedValue, setAuditorsSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  //Part close
  const [closeQmrs, setCloseQmrs] = useState<Employee[] | undefined>(undefined);
  const [closeQmrSelectedValue, setCloseQmrSelectedValue] = useState<
    Employee | undefined
  >(undefined);

  //Workflow

  const [issuerDeptMgrApprove, SetIssuerDeptMgrApprove] = useState(0);
  const [issuerDivMgrApprove, SetIssuerDivMgrApprove] = useState(0);

  const [repeatedCase, setRepeatedCase] = useState(0);
  const [ncTypeName, setNcTypeName] = useState("");
  const [ncsubTypeName, setNcsubTypeName] = useState("");
  const [rootCauseName, setRootCauseName] = useState("");

  const [surveytable, setSurveyTable] = useState<
    SurveyTableResponse | undefined
  >(undefined);
  const [surveylines, setSurveylines] = useState<
    SurveyLineResponse[] | undefined
  >(undefined);

  const getSurveyLines = async (ref_doc_id: string) => {
    const data: any = await requestCustsatGetSurveyLines(
      {},
      accessToken,
      ref_doc_id
    );

    setSurveyTable(data?.surveyTable);
    setSurveylines(data?.surveyLine);
    console.log("getSurveyLines");
    console.log(data);
    try {
      data.surveyLine.map((d: SurveyLineResponse) => {
        dataArray.push({
          id: d.id,
          qt_id: d.qt_id,
          qt_topic: d.qt_topic,
          qt_score: d.qt_score == null ? 1 : d.qt_score,
          qt_comment: d.qt_comment,
        });
      });
      console.log("data.map");
    } catch (err: any) {
      console.log("err");
      console.log(err);
    }
  };

  const getWkStatus = async () => {
    const data = await requestWorkflowstatus({}, accessToken);
    setWkStatuses(data);
  };
  const getCarstandards = async () => {
    const data = await requestCarstandards({}, accessToken);
    setCarstandards(data);
  };
  const getDocStatus = async () => {
    const data = await requestDocStatuss({}, accessToken);
    setDocStatus(data);
  };

  const getCarcategorys = async () => {
    const data = await requestCarcategorys({}, accessToken);
    setCarcategorys(data);
  };
  //
  const getCartypes = async () => {
    const data = await requestCartypes({}, accessToken);
    setCartypes(data);
  };

  const getIssuerDivisiondirectortems = async () => {
    const data = await requestDivisiondirectors({}, accessToken);
    setIssuerDivisionDirectors(data);
  };

  const getIssuerDivisions = async () => {
    const data = await requestDivisions({}, accessToken);
    setIssuerDivisions(data);
  };

  const getIssuerDepartments = async () => {
    const data = await requestDepartments({}, accessToken);
    setIssuerDepartments(data);
  };

  const getIssuerCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setIssuerCountrys(data);
  };

  const getIssuers = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuers(data);
  };

  const getIssuerCompanys = async () => {
    const data = await requestCompanys({}, accessToken);
    setIssuerCompanys(data);
  };

  //Part 1
  const getCustCompanys = async () => {
    const data = await requestCustomers({}, accessToken);
    setCustCompanys(data);
  };
  const getCustContacts = async () => {
    const data = await requestCustomercontacts({}, accessToken);
    setCustContacts(data);
  };
  const getCustClasses = async () => {
    const data = await requestCustClasses({}, accessToken);
    setCustClasses(data);
  };
  const getCustCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setCustCountrys(data);
  };

  //Part 2
  const getProblemTypes = async () => {
    const data = await requestCarproblemtypes({}, accessToken);
    setProbTypes(data);
  };
  const getProbClaimCurrencys = async () => {
    const data = await requestCurrencys({}, accessToken);
    setProbClaimCurrencys(data);
  };
  //Part 3
  const getPreDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setPreDeptMgrs(data);
  };

  //Part 4
  const getResolvers = async () => {
    const data = await requestEmployees({}, accessToken);
    setResolvers(data);
  };
  const getResolverCompanys = async () => {
    const data = await requestCompanys({}, accessToken);
    setResolverCompanys(data);
  };
  const getResolverDivdirs = async () => {
    const data = await requestDivisiondirectors({}, accessToken);
    setResolverDivdirs(data);
  };
  const getResolverDivs = async () => {
    const data = await requestDivisions({}, accessToken);
    setResolverDivs(data);
  };
  const getResolverDepts = async () => {
    const data = await requestDepartments({}, accessToken);
    setResolverDepts(data);
  };
  const getResolverCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setResolverCountrys(data);
  };

  const getSupCompanys = async () => {
    const data = await requestSuppliers({}, accessToken);
    setSupCompanys(data);
  };
  const getSupClasses = async () => {
    const data = await requestSupplierclasses({}, accessToken);
    setSupClasses(data);
  };
  const getSubSettleCurrencys = async () => {
    const data = await requestCurrencys({}, accessToken);
    setSupSettleCurrencys(data);
  };

  //Part 7
  const getRsvDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvDeptMgrs(data);
  };
  const getRsvDivMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvDivMgrs(data);
  };
  const getRsvDeptMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setRsvDeptMgrApproves(data);
  };
  const getRsvDivMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setRsvDivMgrApproves(data);
  };
  const getRsvQmrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setRsvQmrs(data);
  };

  const getRsvQmrVerify = async () => {
    const data = await requestVerifystatus({}, accessToken);
    setRsvQmrVerifys(data);
  };

  //Part 8
  const getAuditors = async () => {
    const data = await requestEmployees({}, accessToken);
    setAuditors(data);
  };

  //Part Close
  const getCloseQmrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setCloseQmrs(data);
  };

  const getIssuerDeptMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuerDeptMgrs(data);
  };
  const getIssuerDivMgrs = async () => {
    const data = await requestEmployees({}, accessToken);
    setIssuerDivMgrs(data);
  };
  const getIssuerDeptMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setIssuerDeptMgrApproves(data);
  };
  const getIssuerDivMgrApproves = async () => {
    const data = await requestApproverejectstatus({}, accessToken);
    setIssuerDivMgrApproves(data);
  };

  useEffect(() => {
    if (car.id) {
      setLoading(true);

      getWkStatus();
      getCarstandards();
      getCarcategorys();
      getCartypes();
      getIssuers();
      getIssuerCompanys();
      getIssuerDivisiondirectortems();
      getIssuerDivisions();
      getIssuerDepartments();
      getIssuerCountrys();

      getDocStatus();

      //Part1
      getCustCompanys();
      getCustContacts();
      getCustClasses();
      getCustCountrys();

      //Part 2
      getProblemTypes();
      getProbClaimCurrencys();

      //Part 3
      getPreDeptMgrs();
      getIssuerDeptMgrs();
      getIssuerDivMgrs();
      getIssuerDeptMgrApproves();
      getIssuerDivMgrApproves();

      //Part 4
      getResolvers();
      getResolverCompanys();
      getResolverDivdirs();
      getResolverDivs();
      getResolverDepts();
      getResolverCountrys();

      //Part 5
      getSupCompanys();
      getSupClasses();
      getSubSettleCurrencys();

      //Part 7
      getRsvDeptMgrs();
      getRsvDivMgrs();
      getRsvDeptMgrApproves();
      getRsvDivMgrApproves();
      getRsvQmrs();
      getRsvQmrVerify();

      //Part 8
      getAuditors();

      //Part close
      getCloseQmrs();

      setRepeatedCase(car.repeated_case);
      setNcTypeName(car.nc_type_name);
      setNcsubTypeName(car.nc_subtype_name);
      setRootCauseName(car.root_cause_error_name);

      formCreateEdit.setFieldsValue({ id: car.id });

      formCreateEdit.setFieldsValue({ car_doc_id: car.car_doc_id });

      formCreateEdit.setFieldsValue({ wf_status_id: car.wf_status_id });

      if (car.doc_status_id !== 0) {
        formCreateEdit.setFieldsValue({ doc_status_id: car.doc_status_id });
      }
      // if (car.car_standard_id !== 0) {
      //     formCreateEdit.setFieldsValue({ car_standard_id: car.car_standard_id })
      // }
      if (car.car_standard_name !== "") {
        formCreateEdit.setFieldsValue({
          car_standard_name: car.car_standard_name,
        });
      }
      // if (car.car_category_id !== 0) {
      //     formCreateEdit.setFieldsValue({ car_category_id: car.car_category_id })
      // }
      if (car.car_category_name !== "") {
        formCreateEdit.setFieldsValue({
          car_category_name: car.car_category_name,
        });
      }

      // if (car.car_type_id !== 0) {
      //     formCreateEdit.setFieldsValue({ car_type_id: car.car_type_id })
      // }
      if (car.car_type_name !== "") {
        formCreateEdit.setFieldsValue({ car_type_name: car.car_type_name });
      }
      // if (car.issuer_id !== 0) {
      //     formCreateEdit.setFieldsValue({ issuer_id: car.issuer_id })
      // }
      if (car.issuer_code !== "") {
        formCreateEdit.setFieldsValue({ issuer_code: car.issuer_code });
      }

      // if (car.issuer_company_id !== 0) {
      //     formCreateEdit.setFieldsValue({ issuer_company_id: car.issuer_company_id })
      // }
      if (car.issuer_company_name !== "") {
        formCreateEdit.setFieldsValue({
          issuer_company_name: car.issuer_company_name,
        });
      }
      if (car.issuer_div_dir_name !== "") {
        formCreateEdit.setFieldsValue({
          issuer_div_dir_name: car.issuer_div_dir_name,
        });
      }
      if (car.issuer_div_name !== "") {
        formCreateEdit.setFieldsValue({ issuer_div_name: car.issuer_div_name });
      }
      if (car.issuer_dept_name !== "") {
        formCreateEdit.setFieldsValue({
          issuer_dept_name: car.issuer_dept_name,
        });
      }
      if (car.issuer_country_name !== "") {
        formCreateEdit.setFieldsValue({
          issuer_country_name: car.issuer_country_name,
        });
      }
      if (car.issuer_dept_mgr_code !== "") {
        formCreateEdit.setFieldsValue({
          issuer_dept_mgr_code: car.issuer_dept_mgr_code,
        });
      }
      if (car.issuer_dept_mgr_comment_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          issuer_dept_mgr_comment_date: moment(
            car.issuer_dept_mgr_comment_date
          ),
        });
      }

      formCreateEdit.setFieldsValue({
        issuer_dept_mgr_comment: car.issuer_dept_mgr_comment,
      });

      if (car.issuer_div_mgr_code !== "") {
        formCreateEdit.setFieldsValue({
          issuer_div_mgr_code: car.issuer_div_mgr_code,
        });
      }
      if (car.issuer_div_mgr_comment_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          issuer_div_mgr_comment_date: moment(car.issuer_div_mgr_comment_date),
        });
      }

      formCreateEdit.setFieldsValue({
        issuer_div_mgr_comment: car.issuer_div_mgr_comment,
      });

      if (car.issuer_dept_mgr_approve !== 0) {
        SetIssuerDeptMgrApprove(car.issuer_dept_mgr_approve);
        formCreateEdit.setFieldsValue({
          issuer_dept_mgr_approve: car.issuer_dept_mgr_approve,
        });
      }
      if (car.issuer_div_mgr_approve !== 0) {
        SetIssuerDivMgrApprove(car.issuer_div_mgr_approve);
        formCreateEdit.setFieldsValue({
          issuer_div_mgr_approve: car.issuer_div_mgr_approve,
        });
      }

      //Part 1
      if (car.cust_company_code !== "") {
        formCreateEdit.setFieldsValue({
          cust_company_code: car.cust_company_code,
        });
      }
      if (car.cust_contact_name !== "") {
        formCreateEdit.setFieldsValue({
          cust_contact_name: car.cust_contact_name,
        });
      }
      if (car.cust_class_name !== "") {
        formCreateEdit.setFieldsValue({ cust_class_name: car.cust_class_name });
      }
      if (car.cust_country_name !== "") {
        formCreateEdit.setFieldsValue({
          cust_country_name: car.cust_country_name,
        });
      }

      formCreateEdit.setFieldsValue({
        cust_contact_email: car.cust_contact_email,
      });
      formCreateEdit.setFieldsValue({ cust_contact_tel: car.cust_contact_tel });

      //Part 2
      formCreateEdit.setFieldsValue({ prob_ref_doc: car.prob_ref_doc });
      formCreateEdit.setFieldsValue({
        prob_product_name: car.prob_product_name,
      });

      if (car.prob_receive_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          prob_receive_date: moment(car.prob_receive_date),
        });
      }

      formCreateEdit.setFieldsValue({ prob_topic: car.prob_topic });
      formCreateEdit.setFieldsValue({ prob_detail: car.prob_detail });

      if (car.prob_type_name !== "") {
        formCreateEdit.setFieldsValue({ prob_type_name: car.prob_type_name });
      }

      formCreateEdit.setFieldsValue({ prob_type_other: car.prob_type_other });
      formCreateEdit.setFieldsValue({
        prob_claim_amount: car.prob_claim_amount,
      });

      if (car.prob_claim_currency_name !== "") {
        formCreateEdit.setFieldsValue({
          prob_claim_currency_name: car.prob_claim_currency_name,
        });
      }

      formCreateEdit.setFieldsValue({
        prob_response_detail: car.prob_response_detail,
      });
      if (car.prob_response_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          prob_response_date: moment(car.prob_response_date),
        });
      }

      //Part 3

      let justArray = car.pre_just_doc_id.split(",");

      formCreateEdit.setFieldsValue({ pre_just_doc_id: justArray });

      formCreateEdit.setFieldsValue({
        pre_just_doc_other: car.pre_just_doc_other,
      });
      if (car.pre_invest_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          pre_invest_date: moment(car.pre_invest_date),
        });
      }

      if (car.pre_dept_mgr_code !== "") {
        formCreateEdit.setFieldsValue({
          pre_dept_mgr_code: car.pre_dept_mgr_code,
        });
      }

      //Part 4
      if (car.resolver_code !== "") {
        formCreateEdit.setFieldsValue({ resolver_code: car.resolver_code });
      }
      if (car.resolver_company_name !== "") {
        formCreateEdit.setFieldsValue({
          resolver_company_name: car.resolver_company_name,
        });
      }
      if (car.resolver_div_dir_name !== "") {
        formCreateEdit.setFieldsValue({
          resolver_div_dir_name: car.resolver_div_dir_name,
        });
      }
      if (car.resolver_div_name !== "") {
        formCreateEdit.setFieldsValue({
          resolver_div_name: car.resolver_div_name,
        });
      }
      if (car.resolver_dept_name !== "") {
        formCreateEdit.setFieldsValue({
          resolver_dept_name: car.resolver_dept_name,
        });
      }
      if (car.resolver_country_name !== "") {
        formCreateEdit.setFieldsValue({
          resolver_country_name: car.resolver_country_name,
        });
      }

      //Part 5
      if (car.sup_company_code !== "") {
        formCreateEdit.setFieldsValue({
          sup_company_code: car.sup_company_code,
        });
      }
      if (car.sup_class_name !== "") {
        formCreateEdit.setFieldsValue({ sup_class_name: car.sup_class_name });
      }

      formCreateEdit.setFieldsValue({
        sup_settle_detail: car.sup_settle_detail,
      });
      formCreateEdit.setFieldsValue({
        sup_settle_amount: car.sup_settle_amount,
      });

      if (car.sup_settle_currency_name !== "") {
        formCreateEdit.setFieldsValue({
          sup_settle_currency_name: car.sup_settle_currency_name,
        });
      }

      //Part 6
      formCreateEdit.setFieldsValue({ cause_human: car.cause_human });
      formCreateEdit.setFieldsValue({ cause_material: car.cause_material });
      formCreateEdit.setFieldsValue({ cause_machine: car.cause_machine });
      formCreateEdit.setFieldsValue({ cause_process: car.cause_process });
      formCreateEdit.setFieldsValue({
        cause_notperformed: car.cause_notperformed,
      });
      formCreateEdit.setFieldsValue({ cause_skilltrain: car.cause_skilltrain });
      formCreateEdit.setFieldsValue({ cause_other: car.cause_other });

      //Part 7
      formCreateEdit.setFieldsValue({
        rsv_implement_detail: car.rsv_implement_detail,
      });
      formCreateEdit.setFieldsValue({
        rsv_dept_mgr_comment: car.rsv_dept_mgr_comment,
      });

      if (car.rsv_dept_mgr_code !== "") {
        formCreateEdit.setFieldsValue({
          rsv_dept_mgr_code: car.rsv_dept_mgr_code,
        });
      }
      if (car.rsv_dept_mgr_approve !== 0) {
        formCreateEdit.setFieldsValue({
          rsv_dept_mgr_approve: car.rsv_dept_mgr_approve,
        });
      }

      if (car.rsv_dept_mgr_comment_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          rsv_dept_mgr_comment_date: moment(car.rsv_dept_mgr_comment_date),
        });
      }

      formCreateEdit.setFieldsValue({
        rsv_div_mgr_comment: car.rsv_div_mgr_comment,
      });
      if (car.rsv_div_mgr_code !== "") {
        formCreateEdit.setFieldsValue({
          rsv_div_mgr_code: car.rsv_div_mgr_code,
        });
      }
      if (car.rsv_div_mgr_approve !== 0) {
        formCreateEdit.setFieldsValue({
          rsv_div_mgr_approve: car.rsv_div_mgr_approve,
        });
      }

      if (car.rsv_div_mgr_comment_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          rsv_div_mgr_comment_date: moment(car.rsv_div_mgr_comment_date),
        });
      }
      if (car.rsv_qmr_code !== "") {
        formCreateEdit.setFieldsValue({ rsv_qmr_code: car.rsv_qmr_code });
      }
      if (car.rsv_qmr_verify !== 0) {
        formCreateEdit.setFieldsValue({ rsv_qmr_verify: car.rsv_qmr_verify });
      }

      if (car.rsv_qmr_verify_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          rsv_qmr_verify_date: moment(car.rsv_qmr_verify_date),
        });
      }
      if (car.rsv_qmr_comment !== "") {
        formCreateEdit.setFieldsValue({ rsv_qmr_comment: car.rsv_qmr_comment });
      }

      //Part 8
      if (car.auditor_code !== "") {
        formCreateEdit.setFieldsValue({ auditor_code: car.auditor_code });
      }
      if (car.auditor_within_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          auditor_within_date: moment(car.auditor_within_date),
        });
      }

      formCreateEdit.setFieldsValue({
        auditor_audit_comment: car.auditor_audit_comment,
      });

      if (car.auditor_audit_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          auditor_audit_date: moment(car.auditor_audit_date),
        });
      }
      if (car.auditor_adequate_status !== 0) {
        formCreateEdit.setFieldsValue({ auditor_adequate_status: 1 });
      }

      //Part 9
      formCreateEdit.setFieldsValue({
        cust_rsv_approve_detail: car.cust_rsv_approve_detail,
      });
      if (car.cust_rsv_approve_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          cust_rsv_approve_date: moment(car.cust_rsv_approve_date),
        });
      }
      formCreateEdit.setFieldsValue({
        cust_rsv_finish_detail: car.cust_rsv_finish_detail,
      });
      if (car.cust_rsv_finish_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          cust_rsv_finish_date: moment(car.cust_rsv_finish_date),
        });
      }

      //Part 10
      formCreateEdit.setFieldsValue({ custsat_detail: car.custsat_detail });
      formCreateEdit.setFieldsValue({ custsat_comment: car.custsat_comment });

      //close_qmr_code
      if (car.close_qmr_code !== "") {
        formCreateEdit.setFieldsValue({ close_qmr_code: car.close_qmr_code });
      }
      if (car.close_qmr_date !== "0001-01-01T00:00:00Z") {
        formCreateEdit.setFieldsValue({
          close_qmr_date: moment(car.close_qmr_date),
        });
      }
      if (car.close_qmr_comment !== "") {
        formCreateEdit.setFieldsValue({
          close_qmr_comment: car.close_qmr_comment,
        });
      }
      getSurveyLines(car.car_doc_id);

      setLoading(false);
    }
  }, [car]);

  const filterStatus = (_id: any) => {
    // Avoid filter for null value
    //   {docStatus?.filter(s => s.doc_status_id = car.doc_status_id).map(d=>d.doc_status_name)}

    const filteredCars: any = docStatus?.filter(
      (car) => car.doc_status_id === _id
    );
    return filteredCars.doc_status_name;
  };

  const handleResendSurvey = async () => {
    //alert(process.env.REACT_APP_CARS_API_URL);
    setLoading(true);
    if (car.cust_contact_email !== "") {
      if (
        car.wf_status_id === "S8" ||
        car.wf_status_id === "S9" ||
        car.wf_status_id === "S10" ||
        car.wf_status_id === "S11"
      ) {
        const data: any = await axios
          .get(
            `${process.env.REACT_APP_CARS_API_URL}/cars/resendsurvey/${car.id}?access_token=${accessToken}`
          )
          .then(async (response) => {
            // Extract data from response
            const responseData = response.data;

            // Call getSurveyLines function
            await getSurveyLines(car.car_doc_id);
            setLoading(false);
            // Show alert message
            alert("Sent survey done");

            // Return response data
            return responseData;
          })
          .catch((error) => {
            // Handle error
            alert("Error: " + error);

            // Return null or some default value
            return null;
          });
      } else {
        alert(
          "Workflow status is " +
            car.wf_status_id +
            " can not resend survey ( excepts S8,S9,S10,S11)"
        );
      }
    } else {
      alert("No have customer email, Please input before resend");
    }
  };

  const handleResendSurveyModelConfirm = () => {
    // handleResendSurvey;
    handleResendSurvey();
    setResendSurveyModalVisible(false);
  };

  const handleModelCancel = () => {
    setResendSurveyModalVisible(false);
  };
  return (
    <div>
      <Spin tip="Loading..." spinning={loading}></Spin>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <b>CAR Doc No.</b>
              <br />
              {car.car_doc_id}
            </Col>
            <Col span={12}>
              <b>Workflow Status</b>
              <br />
              {car.wf_status_id}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span>
                <b>Repeated Case : </b>
                {repeatedCase === 1 ? "Yes" : repeatedCase === 2 ? "No" : " "}
              </span>
            </Col>
            <Col span={12}>
              <span>
                <b>NC Type : </b>
                {ncTypeName}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <span>
                <b>NC Sub Type : </b>
                {ncsubTypeName}
              </span>
            </Col>
            <Col span={12}>
              <span>
                <b>Root Cause Error : </b>
                {rootCauseName}
              </span>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <b>Standard related to this CAR</b> <br />
              {car.car_standard_name}
            </Col>
            <Col span={12}>
              <b>Document status</b> <br />
              {car.doc_status_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Category</b> <br />
              {car.car_category_name}
            </Col>
            <Col span={12}>
              <b>Type</b> <br />
              {car.car_type_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>From</b> <br />
              {issuers
                ?.filter((d) => d.employee_id == car.issuer_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Company</b> <br />
              {car.issuer_company_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Division Director</b> <br />
              {car.issuer_div_dir_name}
            </Col>
            <Col span={12}>
              <b>Division</b> <br />
              {car.issuer_div_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Department</b> <br />
              {car.issuer_dept_name}
            </Col>
            <Col span={12}>
              <b>Country</b> <br />
              {car.issuer_country_name}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>
                PART 1: Customer Information (only claim, complaint and
                suggestion)
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Customer Company Code</b> <br />
              {car.cust_company_code}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>1.1 Customer Company</b> <br />
              {custCompanys
                ?.filter((d) => d.customer_id == car.cust_company_code)
                .map((d) => d.customer_name)}
            </Col>
            <Col span={12}>
              <b>1.2 Customer Contact Person Name</b> <br />
              {car.cust_contact_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>1.3 Customer Contact Email</b> <br />
              {car.cust_contact_email}
            </Col>
            <Col span={12}>
              <b>Customer Contact Tel</b> <br />
              {car.cust_contact_tel}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>1.4 Type of Customer</b> <br />
              {car.cust_class_name}
            </Col>
            <Col span={12}>
              <b>1.5 Customer Country</b> <br />
              {car.cust_country_name}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 2: Problem details</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>2.1 Reference Document (P/O,S/O)</b> <br />
              {car.prob_ref_doc}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>2.2 Product</b> <br />
              {car.prob_product_name}
            </Col>
            <Col span={12}>
              <b>
                2.3 Date of receiving claim / complaint / suggestion from
                customer
              </b>
              <br />
              {moment(car.prob_receive_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <b>2.4 Topic</b> <br />
              {car.prob_topic}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <b>2.5 Details of the problem</b> <br />
              <p> {car.prob_detail}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <b>2.6 Type of claim/complaint/non conformity/suggestion</b>{" "}
              <br />
              {car.prob_type_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>2.7 Estimated amount of claim (if any)</b> <br />
              {car.prob_claim_amount}
            </Col>
            <Col span={12}>
              <b>Currency</b> <br />
              {car.prob_claim_currency_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>2.8 Details of our Immediate Response to customer</b> <br />
              <p>{car.prob_response_detail}</p>
            </Col>
            <Col span={12}>
              <b>2.9 Date of immediate response to customer</b> <br />
              {moment(car.prob_response_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>
                {" "}
                PART 3: Preliminary Investigation on customer side
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>
                3.1 Justification for claim, photographs, sampling, site visits,
                etc…
              </b>{" "}
              <br />
              <JustCheckboxGroup
                disabled={AddEditStatus}
                options={justplainOptions}
                value={car.pre_just_doc_id.split(",")}
              />
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={24}>
              <b>Details</b> <br />
              {car.pre_just_doc_other}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Date of Preliminary Investigation on customer side</b> <br />
              {moment(car.pre_invest_date).format("DD-MM-YYYY")}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>3.2 Department Manager (Issuing department)</b> <br />
              {issuerDeptMgrs
                ?.filter((d) => d.employee_id == car.issuer_dept_mgr_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Department Manager status</b>
              <br />

              {issuerDeptMgrApproves
                ?.filter((d) => d.id == car.issuer_dept_mgr_approve)
                .map((d) => d.ap_status_name)}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}> Department Manager (Issuing department)</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Dept Mgr Comment</b> <br />
              {car.issuer_dept_mgr_comment}
            </Col>
            <Col span={12}>
              <b>Dept Mgr Comment Date</b>
              <br />
              {moment(car.issuer_dept_mgr_comment_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>3.3 Division Manager / Regional Manager (Issuing division)</b>{" "}
              <br />
              {issuerDivMgrs
                ?.filter((d) => d.employee_id == car.issuer_div_mgr_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Division Manager status</b>
              <br />

              {issuerDivMgrApproves
                ?.filter((d) => d.id == car.issuer_div_mgr_approve)
                .map((d) => d.ap_status_name)}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 4: Responsible party</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>To</b> <br />
              {resolvers
                ?.filter((d) => d.employee_id == car.resolver_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Company</b>
              <br />
              {car.resolver_company_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Division Director</b> <br />
              {car.resolver_div_dir_name}
            </Col>
            <Col span={12}>
              <b>Division</b>
              <br />
              {car.resolver_div_name}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Department</b> <br />
              {car.resolver_dept_name}
            </Col>
            <Col span={12}>
              <b>Country</b>
              <br />
              {car.resolver_country_name}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>
                PART 5: Claim Settlement with supplier/contractor
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Supplier Company Code</b> <br />
              {car.sup_company_code}
            </Col>
            <Col span={12}>
              <b></b>
              <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Supplier Company Name</b> <br />
              {supCompanys
                ?.filter((d) => d.sup_id == car.sup_company_code)
                .map((d) => d.sup_name)}
            </Col>
            <Col span={12}>
              <b></b>
              <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Details of Settlement</b> <br />
              {car.sup_settle_detail}
            </Col>
            <Col span={12}>
              <b></b>
              <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Details of Settlement</b> <br />
              {car.sup_settle_detail}
            </Col>
            <Col span={12}>
              <b></b>
              <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Amount settled by supplier</b> <br />
              {car.sup_settle_amount}
            </Col>
            <Col span={12}>
              <b>Currency</b>
              <br />
              {car.sup_settle_currency_name}
            </Col>
          </Row>

          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 6: Root cause investigation</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Human</b> <br />
              {car.cause_human}
            </Col>
            <Col span={12}>
              <b>Material</b>
              <br />
              {car.cause_material}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Machine</b> <br />
              {car.cause_machine}
            </Col>
            <Col span={12}>
              <b>nan Ineffective and inefficient process</b>
              <br />
              {car.cause_process}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Supplier/Contractor did not performed</b> <br />
              {car.cause_notperformed}
            </Col>
            <Col span={12}>
              <b>Lack of skill and training</b>
              <br />
              {car.cause_skilltrain}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Other</b> <br />
              {car.cause_other}
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 7: Resolve with customer</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>7.1 Resolved Approved</b> <br />
              Solution and timeline to resolve the claim/complaint has been
              agreed (“approved”) by the customer
              <br />
              {car.cust_rsv_approve_detail}
            </Col>
            <Col span={12}>
              <b>7.1 Resolved Approved</b> <br />
              {moment(car.cust_rsv_approve_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>7.2 Resolved Finished</b> <br />
              Completed resolving the claim/complaint as per solution and
              timeline in 7.1 ex. Compensation of claims to customer etc.
              <br />
              {car.cust_rsv_finish_detail}
            </Col>
            <Col span={12}>
              <b>Date of having resolve-finished with customer</b> <br />
              {moment(car.cust_rsv_finish_date).format("DD-MM-YYYY")}
            </Col>
          </Row>

          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 8: Corrective Actions (prevention)</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>8.1 Corrective Action(s) implemented (prevention)</b> <br />
              {car.rsv_implement_detail}
            </Col>
            <Col span={12}>
              <b></b> <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>8.2 Department Manager</b> <br />
              {rsvDeptMgrs
                ?.filter((d) => d.employee_id == car.rsv_dept_mgr_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Department Manager Status</b> <br />
              {rsvDeptMgrApproves
                ?.filter((d) => d.id == car.issuer_dept_mgr_approve)
                .map((d) => d.ap_status_name)}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>Department Manager</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Comments from Department</b> <br />
              {car.rsv_dept_mgr_comment}
            </Col>
            <Col span={12}>
              <b>Date</b> <br />
              {moment(car.rsv_dept_mgr_comment_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>8.3 Division Manager / Regional Manager</b> <br />
              {rsvDivMgrs
                ?.filter((d) => d.employee_id == car.rsv_div_mgr_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Division Manager Status</b> <br />
              {rsvDivMgrApproves
                ?.filter((d) => d.id == car.rsv_div_mgr_approve)
                .map((d) => d.ap_status_name)}
            </Col>
          </Row>

          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>Division Manager/Regional Manager</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Comments from Division Manager/Regional Manager</b> <br />
              {car.rsv_div_mgr_comment}
            </Col>
            <Col span={12}>
              <b>Date</b> <br />
              {moment(car.rsv_div_mgr_comment_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>PART 9: Audit Check</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>QMR verified status</b> <br />
              {rsvQmrVerifys
                ?.filter((d) => d.id == car.rsv_qmr_verify)
                .map((d) => d.verify_status_name)}
            </Col>
            <Col span={12}>
              <b>Date</b> <br />
              {moment(car.rsv_qmr_verify_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Comments from QMR</b> <br />
              {car.rsv_qmr_comment}
            </Col>
            <Col span={12}>
              <b></b> <br />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Appointed Auditor</b> <br />
              {auditors
                ?.filter((d) => d.employee_id == car.auditor_code)
                .map((d) => d.fullname + "(" + d.email + ")")}
            </Col>
            <Col span={12}>
              <b>Within Date</b> <br />
              {moment(car.auditor_within_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>For Auditor</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Audit Result</b> <br />
              {car.auditor_audit_comment}
            </Col>
            <Col span={12}>
              <b>Audit Date</b> <br />
              {moment(car.auditor_audit_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Radio.Group disabled={true}>
                <Space direction="vertical">
                  <Radio
                    value={1}
                    checked={car.auditor_adequate_status == 1 ? true : false}
                  >
                    Corrective and preventive actions are adequate
                  </Radio>
                  <Radio
                    value={2}
                    checked={car.auditor_adequate_status == 2 ? true : false}
                  >
                    Corrective and preventive actions are inadequate
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>
                PART 10: Customer Satisfaction (Feedback from Customer)
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {surveytable ? (
                <>
                  <b> Send to:</b> {surveytable.contact_email}
                  <br />
                  <b> Survey Date:</b>{" "}
                  {format(
                    new Date(surveytable?.created_datetime as string),
                    "yyyy-MM-dd"
                  )}
                  <br />
                  <b> Answer Date:</b> {""}
                  {surveytable && surveytable.answer_date !== undefined ? (
                    // If both conditions are met, render the formatted date
                    isDefaultDate(surveytable.answer_date.toString()) ? (
                      <span>
                        Not yet{" "}
                        <Button
                          onClick={() => setResendSurveyModalVisible(true)}
                        >
                          Resend Survey
                        </Button>
                      </span>
                    ) : (
                      <span>
                        {format(
                          new Date(surveytable.answer_date),
                          "yyyy-MM-dd"
                        )}
                      </span>
                    )
                  ) : (
                    <>
                      <b>
                        Not yet <br />
                        <Button
                          onClick={() => setResendSurveyModalVisible(true)}
                        >
                          Resend Survey
                        </Button>
                      </b>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {surveylines ? (
                <SurveyLineList
                  surveylines={surveylines}
                  add={undefined}
                  remove={undefined} // add={add}
                  // remove={remove}
                />
              ) : (
                <div>
                  No have survey{" "}
                  <Button onClick={() => setResendSurveyModalVisible(true)}>
                    Resend Survey
                  </Button>
                </div>
              )}

              {/* <Form.List name="surveylines">
                {(surveylines, { add, remove }) => {
                  return (
                    <SurveyLineList
                      surveylines={surveylines}
                      add={add}
                      remove={remove}
                    />
                  );
                }}
              </Form.List> */}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Comments from CRM</b> <br />
              {car.custsat_comment}
            </Col>
            <Col span={12}>
              <b></b> <br />
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#B4C6E7" }}>
            <Col span={24}>
              <Title level={5}>For QMR close</Title>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>QMR closed</b> <br />
              {car.close_qmr_closed}
            </Col>
            <Col span={12}>
              <b>Date</b> <br />
              {moment(car.close_qmr_date).format("DD-MM-YYYY")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <b>Comments from QMR</b> <br />
              {car.rsv_qmr_comment}
            </Col>
            <Col span={12}>
              <b></b> <br />
            </Col>
          </Row>
          {/* <Form style={{ marginBottom: '0px',backgroundColor:"#F7F7F7" }}
 initialValues={{ surveylines: dataArray }}
                        form={formCreateEdit}
                        name="formCreateEdit"

                        layout="vertical"


                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="CAR Doc No."
                                    name="car_doc_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter CAR doc id'
                                        }
                                    ]}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Workflow Status"
                                    name="wf_status_id"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select work flow status'
                                        }
                                    ]}>

                                    <Select disabled={true} defaultValue={wfStatusesSelectedValue?.wf_status_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {wfStatuses?.map((item: Workflowstatus, index: any) => <Select.Option value={item.wf_status_id} key={item.wf_status_id}>{item.wf_status_id} - {item.wf_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Standard related to this CAR"
                                    name="car_standard_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select standard'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={carstandardsSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {carstandards?.map((item: Carstandard, index: any) => <Select.Option value={item.name} key={index}>{item.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Document status"
                                    name="doc_status_id"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select document status'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={docStatusSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {docStatus?.map((item: Docstatus, index: any) => <Select.Option value={item.id} key={index}>{item.doc_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Category"
                                    name="car_category_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select category'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={carcategorysSelectedValue?.name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {carcategorys?.map((item: Carcategory, index: any) => <Select.Option value={item.name} key={index}>{item.name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Type"
                                    name="car_type_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select type of CAR'
                                        }
                                    ]}>
                                    <Select defaultValue={cartypesSelectedValue?.name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {cartypes?.map((item: Cartype, index: any) => <Select.Option value={item.name} key={index}>{item.name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="From"
                                    name="issuer_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter issuer from'
                                        }
                                    ]}>

                                    <Select disabled={AddEditStatus} defaultValue={issuersSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuers?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.first_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Company"
                                    name="issuer_company_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter company'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={issuerCompanysSelectedValue?.company_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerCompanys?.map((item: Company, index: any) => <Select.Option value={item.company_name} key={index}>{item.company_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Director"
                                    name="issuer_div_dir_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter issuer division director from'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={issuerDivisionDirectorsSelectedValue?.divisiondirector_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDivisionDirectors?.map((item: Divisiondirector, index: any) => <Select.Option value={item.divisiondirector_name} key={index}>{item.divisiondirector_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Division"
                                    name="issuer_div_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter issuer division'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={issuerDivisionsSelectedValue?.division_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDivisions?.map((item: Division, index: any) => <Select.Option value={item.division_name} key={index}>{item.division_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Department"
                                    name="issuer_dept_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter issuer department'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={issuerDepartmentsSelectedValue?.department_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDepartments?.map((item: Department, index: any) => <Select.Option value={item.department_name} key={index}>{item.department_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Country"
                                    name="issuer_country_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter issuer country'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={issuerCountrysSelectedValue?.country_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerCountrys?.map((item: Country, index: any) => <Select.Option value={item.country_name} key={index}>{item.country_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>

                            </Col>

                        </Row>

                        <Divider />
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 1: Customer Information (only claim, complaint and suggestion)</Title>
                                        </Col>
                            </Row>
                    
                        <Row >
                            <Col span={12}>
                            <Form.Item
                                    label="Customer Company Code"
                                    name="cust_company_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={custCompanysSelectedValue?.customer_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custCompanys?.map((item: Customer, index: any) => <Select.Option value={item.customer_id} key={index}>{item.customer_id}</Select.Option>)}
                                    </Select>


                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="1.1 Customer Company"
                                    name="cust_company_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={custCompanysSelectedValue?.customer_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custCompanys?.map((item: Customer, index: any) => <Select.Option value={item.customer_id} key={index}>{item.customer_name}</Select.Option>)}
                                    </Select>


                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="1.2 Customer Contact Person Name"
                                    name="cust_contact_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer contact'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={custContactsSelectedValue?.contact_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custContacts?.map((item: Customercontact, index: any) => <Select.Option value={item.contact_name} key={index}>{item.contact_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="1.3 Customer Contact Email"
                                    name="cust_contact_email"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer contact Email'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Customer Contact Tel"
                                    name="cust_contact_tel"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer contact tel'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="1.4 Type of Customer"
                                    name="cust_class_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer class'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={custClassesSelectedValue?.cust_class_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custClasses?.map((item: Customerclass, index: any) => <Select.Option value={item.cust_class_name} key={index}>{item.cust_class_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="1.5 Customer Country"
                                    name="cust_country_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter customer country'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={custCountrysSelectedValue?.country_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {custCountrys?.map((item: Country, index: any) => <Select.Option value={item.country_name} key={index}>{item.country_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>

                            </Col>

                        </Row>
                        <Divider />
                     
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                          <Title level={5}>PART 2: Problem details</Title>
                                        </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="2.1 Reference Document (P/O,S/O)"
                                    name="prob_ref_doc"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter reference document'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="2.2 Product"
                                    name="prob_product_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter product name'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="2.3 Date of receiving claim / complaint / suggestion from customer"
                                    name="prob_receive_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date of receiving'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus}
                                        format={dateFormat} />

                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="2.4 Topic"
                                    name="prob_topic"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter problem topic'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="2.5 Details of the problem"
                                    name="prob_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter details of the problem'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />

                                </Form.Item>

                            </Col>


                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="2.6 Type of claim/complaint/non conformity/suggestion"
                                    name="prob_type_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter type of claim'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={probTypesSelectedValue?.name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {probTypes?.map((item: Carproblemtype, index: any) => <Select.Option value={item.name} key={index}>{item.name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="&nbsp;"
                                    name="prob_type_other"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter type of claim'
                                        }
                                    ]}>
                                    <Input disabled={AddEditStatus} />

                                </Form.Item>
                            </Col>
                        </Row>
                       

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="2.7 Estimated amount of claim (if any)"
                                    name="prob_claim_amount"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter estimated amount of claim'
                                        }
                                    ]}>
                                    <InputNumber disabled={AddEditStatus} style={{ width: 200 }} />


                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Currency"
                                    name="prob_claim_currency_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter currency'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={probClaimCurrencysSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {probClaimCurrencys?.map((item: Currency, index: any) => <Select.Option value={item.id} key={index}>{item.currency_name}</Select.Option>)}
                                    </Select>



                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="2.8 Details of our Immediate Response to customer"
                                    name="prob_response_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter details of our Immediate Response to customer'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="2.9 Date of immediate response to customer"
                                    name="prob_response_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date of immediate response to customer'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />

                                </Form.Item>

                            </Col>

                        </Row>

                        <Divider />
                        
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 3: Preliminary Investigation on customer side</Title>
                                        </Col>
                            </Row>
                    
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="3.1 Justification for claim, photographs, sampling, site visits, etc…"
                                    name="pre_just_doc_id"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter justification'
                                        }
                                    ]}>

                                    <JustCheckboxGroup disabled={AddEditStatus} options={justplainOptions} />

                                </Form.Item>
                            </Col>
                           

                        </Row>
                        <Row>
                        <Col span={12}>
                                <Form.Item
                                    label="Details"
                                    name="pre_just_doc_other"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter other'
                                        }
                                    ]}>
                                    <TextArea rows={4} disabled={AddEditStatus} />
                                </Form.Item>

                            </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Date of Preliminary Investigation on customer side"
                                    name="pre_invest_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date of Preliminary Investigation on customer side'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />

                                </Form.Item>
                            </Col>
                            

                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="3.2 Department Manager (Issuing department)"
                                    name="issuer_dept_mgr_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter department manager'
                                        }
                                    ]}>

                                    <Select disabled={AddEditStatus} defaultValue={issuerDeptMgrSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDeptMgrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item style={issuerDeptMgrApprove === 2? styleReject : undefined}
                                    label="3.2 Department Manager status"
                                    name="issuer_dept_mgr_approve"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Department Manager status'
                                        }
                                    ]}>

                                    <Select 
                                    disabled={true} defaultValue={issuerDeptMgrApproveSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDeptMgrApproves?.map((item: Approverejectstatus, index: any) => <Select.Option value={item.id} key={index}>{item.ap_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Dept Mgr Comment"
                                    name="issuer_dept_mgr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Dept Mgr Comment'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Dept Mgr Comment Date"
                                    name="issuer_dept_mgr_comment_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Dept Mgr Comment Date'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />

                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="3.3 Division Manager (Issuing division)"
                                    name="issuer_div_mgr_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter division manager'
                                        }
                                    ]}>

                                    <Select disabled={AddEditStatus} defaultValue={issuerDivMgrSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDivMgrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item style={issuerDivMgrApprove === 2? styleReject : undefined}
                                    label="3.2 Division Manager status"
                                    name="issuer_div_mgr_approve"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Division Manager status'
                                        }
                                    ]}>

                                    <Select  
                                       defaultValue={issuerDivMgrApproveSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDivMgrApproves?.map((item: Approverejectstatus, index: any) => <Select.Option value={item.id} key={index}>{item.ap_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Mgr Comment"
                                    name="issuer_div_mgr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Division Mgr Comment'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Mgr Comment Date"
                                    name="issuer_div_mgr_comment_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Division Mgr Comment Date'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />

                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider />
                        
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 4: Responsible party</Title>
                                        </Col>
                            </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="To"
                                    name="resolver_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter to'
                                        }
                                    ]}>

                                    <Select disabled={AddEditStatus} defaultValue={resolversSelectedValue?.employee_id}  >
                                        {resolvers?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Company"
                                    name="resolver_company_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter company'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={resolverCompanysSelectedValue?.company_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {resolverCompanys?.map((item: Company, index: any) => <Select.Option value={item.company_name} key={index}>{item.company_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Director"
                                    name="resolver_div_dir_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Division Director'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={resolverDivdirsSelectedValue?.divisiondirector_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {resolverDivdirs?.map((item: Divisiondirector, index: any) => <Select.Option value={item.divisiondirector_name} key={index}>{item.divisiondirector_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Division"
                                    name="resolver_div_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter division'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={resolverDivsSelectedValue?.division_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {resolverDivs?.map((item: Division, index: any) => <Select.Option value={item.division_name} key={index}>{item.division_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Department"
                                    name="resolver_dept_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter department'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={resolverDeptsSelectedValue?.department_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {resolverDepts?.map((item: Department, index: any) => <Select.Option value={item.department_name} key={index}>{item.department_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Country"
                                    name="resolver_country_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter country'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={resolverCountrysSelectedValue?.country_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {resolverCountrys?.map((item: Country, index: any) => <Select.Option value={item.country_name} key={index}>{item.country_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>

                        </Row>
                        <Divider />
                       
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 5: Claim Settlement with supplier/contractor</Title>
                                        </Col>
                            </Row>
                        <Row>
                        <Col span={12}>
                                <Form.Item
                                    label="Supplier Company Code"
                                    name="sup_company_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter supplier'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={supCompanysSelectedValue?.sup_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {supCompanys?.map((item: Supplier, index: any) => <Select.Option value={item.sup_id} key={index}>{item.sup_id}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Supplier Company Name"
                                    name="sup_company_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter supplier'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={supCompanysSelectedValue?.sup_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {supCompanys?.map((item: Supplier, index: any) => <Select.Option value={item.sup_id} key={index}>{item.sup_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Type of Supplier"
                                    name="sup_class_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter type of supplier'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={supClassesSelectedValue?.sup_class_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {supClasses?.map((item: Supplierclass, index: any) => <Select.Option value={item.sup_class_name} key={index}>{item.sup_class_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>

                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Details of Settlement"
                                    name="sup_settle_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter detail of settlement'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />

                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="Amount settled by supplier"
                                    name="sup_settle_amount"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter amount settled by supplier'
                                        }
                                    ]}>
                                    <InputNumber disabled={AddEditStatus} style={{ width: 200 }} />
                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Currency"
                                    name="sup_settle_currency_name"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter currency'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={supSettleCurrencysSelectedValue?.currency_name}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {supSettleCurrencys?.map((item: Currency, index: any) => <Select.Option value={item.currency_name} key={index}>{item.currency_name}</Select.Option>)}
                                    </Select>

                                </Form.Item>
                            </Col>

                        </Row>
                        <Divider />
                     
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>Part 6: Root cause investigation</Title>
                                        </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Human"
                                    name="cause_human"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter human'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Material"
                                    name="cause_material"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter material'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Machine"
                                    name="cause_machine"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter machine'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Ineffective and inefficient process"
                                    name="cause_process"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter process'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Supplier/Contractor did not performed"
                                    name="cause_notperformed"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Supplier/Contractor did not performed'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Lack of skill and training"
                                    name="cause_skilltrain"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Lack of skill and training'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="Other"
                                    name="cause_other"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter other'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>


                        </Row>

                        <Divider />
                        
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 7: Resolve with customer</Title>
                                        </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="7.1 Solution and timeline to resolve the claim/complaint has been agreed (“approved”) by the customer"
                                    name="cust_rsv_approve_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Resolve-Approved'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date of having resolve-approved by customer"
                                    name="cust_rsv_approve_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Date of having resolve-approved by customer'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="7.2 Completed resolving the claim/complaint as per solution and timeline in 7.1
                                    ex. Compensation of claims to customer etc."
                                    name="cust_rsv_finish_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Resolve-Finished'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date of having resolve-finished with customer"
                                    name="cust_rsv_finish_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Date of having resolve-finished with customer'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Divider />
                      
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 8: Corrective Actions</Title>
                                        </Col>
                            </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="8.1 Corrective Action(s) implemented"
                                    name="rsv_implement_detail"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Supplier/Contractor did notperformed'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="8.2 Comments from Department"
                                    name="rsv_dept_mgr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Comments from Department'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Department Manager Status"
                                    name="rsv_dept_mgr_approve"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Department Manager Status'
                                        }
                                    ]}>
                                    <Select defaultValue={rsvDeptMgrApproveSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvDeptMgrApproves?.map((item: Approverejectstatus, index: any) => <Select.Option value={item.id} key={index}>{item.ap_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Department Manager"
                                    name="rsv_dept_mgr_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Department Manager'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={rsvDeptMgrSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvDeptMgrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date"
                                    name="rsv_dept_mgr_comment_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="8.3 Comments from DivisionManager/Country Manager"
                                    name="rsv_div_mgr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Comments from DivisionManager/Country Manager'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Manager Status"
                                    name="rsv_div_mgr_approve"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Division Manager Status'
                                        }
                                    ]}>
                                    <Select defaultValue={rsvDivMgrApproveSelectedValue?.id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvDivMgrApproves?.map((item: Approverejectstatus, index: any) => <Select.Option value={item.id} key={index}>{item.ap_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Division Manager"
                                    name="rsv_div_mgr_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Division Manager'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={rsvDivMgrSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvDivMgrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date"
                                    name="rsv_div_mgr_comment_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>
                        
                        <Divider />
                       
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 9: Audit Check</Title>
                                        </Col>
                            </Row>
                        <Row>
                             <Col span={12} hidden={true}>
                                <Form.Item
                                    label="QMR verified"
                                    name="rsv_qmr_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter QMR verified'
                                        }
                                    ]}>
                                    <Select disabled={AddEditStatus} defaultValue={rsvDivMgrSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvDivMgrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            </Row>
                        <Row>
                           
                            <Col span={12}>
                                <Form.Item
                                    label="QMR verified status"
                                    name="rsv_qmr_verify"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter QMR verified status'
                                        }
                                    ]}>
                                    <Select disabled={true} defaultValue={rsvDivMgrSelectedValue?.id} 
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {rsvQmrVerifys?.map((item: Verifystatus, index: any) => <Select.Option value={item.id} key={index}>{item.verify_status_name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Date"
                                    name="rsv_qmr_verify_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter date'
                                        }
                                    ]}>
                                    <DatePicker disabled={AddEditStatus} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Comments from QMR"
                                    name="rsv_qmr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Comments from QMR'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4}  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Appointed Auditor"
                                    name="auditor_code"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Appointed Auditor'
                                        }
                                    ]}>
                                    <Select disabled={true} defaultValue={auditorsSelectedValue?.employee_id}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {auditors?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Within Date"
                                    name="auditor_within_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter within date'
                                        }
                                    ]}>
                                    <DatePicker disabled={true} format={dateFormat} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row>

                            <Col span={12}>
                                <Form.Item
                                    label="Audit Result"
                                    name="auditor_audit_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter audit results'
                                        }
                                    ]}>
                                    <TextArea disabled={true} rows={4}  />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Audit Date"
                                    name="auditor_audit_date"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter audit date'
                                        }
                                    ]}>
                                    <DatePicker disabled={true} format={dateFormat} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item name="auditor_adequate_status" label="">
                                    <Radio.Group disabled={true}>
                                        <Space direction="vertical">
                                            <Radio value={1}>Corrective and preventive actions are adequate</Radio>
                                            <Radio value={2}>Corrective and preventive actions are inadequate</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider />
                       
                     
                        <Row style={{backgroundColor:'#B4C6E7'}}>
                                        <Col span={24}>
                                        <Title level={5}>PART 10: Customer Satisfaction (Feedback from Customer)</Title>
                                        </Col>
                            </Row>
                        <Row>
                            <Col span={24}>
                            
                                
                                                   <Form.List name="surveylines">
                                                   {(surveylines, { add, remove }) => {
                                                     return <SurveyLineList surveylines={surveylines} add={add} remove={remove} />;
                                                   }}
                                                 </Form.List>
                            
                            </Col>

                        </Row>
                        
                        
                        <Row>
                            <Col span={12}>

                                <Form.Item
                                    label="Comments from CRM"
                                    name="custsat_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Comments from CRM'
                                        }
                                    ]}>
                                    <TextArea disabled={AddEditStatus} rows={4} />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Divider/>
                        <Title level={5}>Closed CAR</Title>
                        <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label="QMR closed "
                                            name="close_qmr_code"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please enter QMR closed'
                                                }
                                            ]}>
                                            <Select defaultValue={closeQmrSelectedValue?.employee_id} 
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input: any, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA: any, optionB: any) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }>
                                                {closeQmrs?.map((item: Employee, index: any) => <Select.Option value={item.employee_id} key={index}>{item.fullname}</Select.Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Date"
                                            name="close_qmr_date"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please enter date'
                                                }
                                            ]}>
                                            <DatePicker  format={dateFormat} />
                                        </Form.Item>
                                    </Col>

                                </Row>

                        <Row>
                            <Col span={12}>

                                <Form.Item
                                    label="Comments from QMR"
                                    name="close_qmr_comment"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please enter Comments from QMR'
                                        }
                                    ]}>
                                    <TextArea rows={4}  />
                                </Form.Item>

                            </Col>
                        </Row>

                        <Row>
                            <Col span={4} >
                            </Col>
                            <Col span={20} className="text-right">
                          

                            </Col>
                        </Row>
                    </Form> */}
        </Col>
      </Row>
      <ConfirmationModal
        visible={resendSurveyModalVisible}
        onConfirm={handleResendSurveyModelConfirm}
        onCancel={handleModelCancel}
      />
    </div>
  );
};

export default CarDetail;
