export const getCurrentTimestamp = (digit: number = 10): number =>
  +new Date().getTime().toString().substr(0, digit);

export const isDefaultDate = (dateString: string): boolean => {
  if (dateString === "0001-01-01T00:00:00Z") {
    return true;
  }
  const dateObject = new Date(dateString);
  return dateObject.getTime() === 0;
};
