import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Customer {
    id: string
    customer_id: string
    customer_name: string
    cust_class_id: number
    cust_class_name: string
    cust_country_name:string

    customer_search:string
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestCustomers = async(conditions: object = {},accessToken: string): Promise<Customer[]> => {
    const data:Customer[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/customers/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

export const requestCustomerByCustomerCode = async(conditions: object = {},accessToken: string,customer_id: string): Promise<Customer> => {
    const data:Customer = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/customers/${customer_id}?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

