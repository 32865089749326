import React, { useContext, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { message } from "antd";
// import * as jwt from "jsonwebtoken";
import {
  AccessTokenDecoded,
  PrivateRouteProps,
} from "../../types/components/PrivateRoute";
import { getCurrentTimestamp } from "../../utils/date";

import UserContext from "../../contexts/UserContext";

import { requestRefreshToken } from "../../hooks/auth";
import { UserResponse } from "../../types/hooks/user";
import { requestGetUser } from "../../hooks/user";
import { isExpired, decodeToken } from "react-jwt";

const getExpFromToken = (token: string): number => {
  //   const { exp }: AccessTokenDecoded = Object(jwt.decode(token));
  const { exp }: AccessTokenDecoded = Object(decodeToken(token));
  return exp;
};

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { isLoggedIn, accessToken, setIsLoggedIn, setAccessToken, setUser } =
    useContext(UserContext);

  const history = useHistory();

  const accessTokenExp = getExpFromToken(accessToken);
  const currentTimeStamp = getCurrentTimestamp();

  const toDoRefreshToken = async () => {
    try {
      const data = await requestRefreshToken(accessToken);
      setAccessToken(data.access_token);
      setIsLoggedIn(true);
      if (data.access_token) {
        const user_data = await requestGetUser(data.access_token, data.user_id);
        setUser(user_data);
        console.log(user_data);
      }
    } catch (error) {
      setAccessToken("");
      setIsLoggedIn(false);
      setUser({} as UserResponse);
      message.error("Unauthorized");

      return history.push("/login");
    }
  };

  useEffect(() => {
    if (currentTimeStamp > accessTokenExp) {
      message.error("Session has expired");
      setIsLoggedIn(false);
      setAccessToken("");
      setUser({} as UserResponse);
      return;
    }

    const needToRefreshToken = accessTokenExp - currentTimeStamp < 60;
    if (needToRefreshToken) {
      toDoRefreshToken();
    }
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
