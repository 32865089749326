import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_CARS_API_URL } = process.env

export interface Rootcause {
    id: string
    name: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestRootcauses = async(conditions: object = {},accessToken: string): Promise<Rootcause[]> => {
    const data:Rootcause[] = await axios
    .get(`${REACT_APP_CARS_API_URL}/rootcauses/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

