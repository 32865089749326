import axios from 'axios'
import fetch from 'node-fetch'
import { LoginResponse } from '../types/hooks/auth'


const { REACT_APP_OAUTH_API_URL,REACT_APP_USERS_API_URL } = process.env

export interface Redirecturl{
    
    id: number
    url: string
    ip: string
    created_datetime: string

}

const handleResponseError = (error: any) => {
    //? = protect data no value

    if (error?.response?.data?.error) {
        throw error.response.data.error
    }
    throw error
}

export const requestAuthLogin = async (username: string, password: string): Promise<LoginResponse> => {
    const data: LoginResponse = await axios.post(`${REACT_APP_OAUTH_API_URL}/oauth/access_token`,{ //http://localhost:4000/auth/login
        "username": username,
        "password": password,
        "grant_type": "password"
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

export const requestRefreshToken = async (token: string): Promise<LoginResponse> => {
    const data: LoginResponse = await axios.post(`${REACT_APP_OAUTH_API_URL}/oauth/refresh-token`, {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}

export const requestCreateRedirecturl = async (url: string,email: any): Promise<Redirecturl> => {

    const res = await axios.get('https://geolocation-db.com/json/')
    var ip = res.data.IPv4

    const data: Redirecturl = await axios.post(`${REACT_APP_OAUTH_API_URL}/oauth/redirecturls`, {
        url: url,
        ip: ip,
        email: email,
    }, {
        // headers: {
        //     Authorization: `Bearer ${token}`
        // }
    })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestGetRedirecturl = async (): Promise<Redirecturl> => {

    const res = await axios.get('https://geolocation-db.com/json/')
    var ip = res.data.IPv4

    const data: Redirecturl = await axios.get(`${REACT_APP_OAUTH_API_URL}/oauth/redirecturls/${ip}`, {
        // headers: {
        //     Authorization: `Bearer ${token}`
        // }
    })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}


const requestAction = [
    requestAuthLogin,
    requestRefreshToken,
    
]

export default requestAction

