import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Customercontact {
    id: number
    contact_id: string
    contact_name: string
    contact_email: string
    customer_id: number
    contact_tel: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestCustomercontacts = async(conditions: object = {},accessToken: string): Promise<Customercontact[]> => {
    const data:Customercontact[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/customercontacts/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}
export const requestCustomercontactByCustomerId = async(conditions: object = {},accessToken: string,customer_id: string): Promise<Customercontact[]> => {
    const data:Customercontact[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/customercontacts/customer/${customer_id}?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}
export const requestCustomercontactByName = async(conditions: object = {},accessToken: string,name: string): Promise<Customercontact> => {
    const data:Customercontact = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/customercontacts/name/${name}?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

