import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Department {
    id: string
    department_id: string
    department_name: string
    division_id: string
    
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestDepartments = async(conditions: object = {},accessToken: string): Promise<Department[]> => {
    const data:Department[] = await axios
    .get(`${REACT_APP_MASTERDATAS_API_URL}/departments/all?access_token=${accessToken}`,{
        // headers: {
        //     Authorization: `Bearer ${accessToken}`
        // }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

