import axios from 'axios'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { LoginResponse } from '../types/hooks/auth'
const { REACT_APP_DRIVES_API_URL } = process.env
axios.defaults.timeout = 300000

export interface Drive {
    id: number

    file_id: string 
    orig_file_name: string
    drive_name: string
    file_name: string
    file_type: string
    file_size: number
    file_url: string
    folder_id: string

    created_by: string
    created_datetime: string
    updated_by: string
    updated_datetime: string
	
    //For doc
    ref_id: number 
    ref_doc_id: string
    
 
}
export interface DefaultFileList {
    uid: string
    name: string
	status: string
    url: string
    thumbUrl: string
}


const handleResponseError = (error: any) => {

    //? = protect data no value
    if (error?.response?.data?.message) {
        console.warn("Error : " + error?.response?.data?.message)
    }
    console.warn(error)
}


export const requestUploadFile = async(accessToken: string,formdata: FormData,config: any): Promise<Drive[]> => {
    const data:Drive[] = await axios
    .post(`${REACT_APP_DRIVES_API_URL}/drives?access_token=${accessToken}`,formdata,config)
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

export const requestDeleteFileByFileId = async(accessToken: string,fileId : string): Promise<Drive[]> => {
    const data:Drive[] = await axios
    .delete(`${REACT_APP_DRIVES_API_URL}/drives/${fileId}?access_token=${accessToken}`)
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}
export const requestGetFileById = async(accessToken: string,fileId : string): Promise<Drive[]> => {
    const data:Drive[] = await axios
    .get(`${REACT_APP_DRIVES_API_URL}/drives/${fileId}?access_token=${accessToken}`)
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}

export const requestGetFileByRefDocId = async(accessToken: string,refDocId : string,driveName : string): Promise<Drive[]> => {
    const data:Drive[] = await axios
    .get(`${REACT_APP_DRIVES_API_URL}/drives/refdoc/${refDocId}/${driveName}?access_token=${accessToken}`)
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}


