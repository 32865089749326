import axios from "axios";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { LoginResponse } from "../types/hooks/auth";
const { REACT_APP_CUSTSATS_API_URL } = process.env;
axios.defaults.timeout = 300000;

export interface SurveyTableResponse {
  id: number;
  ref_doc_id?: string;
  ref_doc_type?: string;
  cust_id?: number;
  cust_contact_id?: number;
  issuer_id?: number;
  issuer_date?: string; // Consider using string for date representation in JSON
  status_id?: number;
  survey_id?: string; // Consider using string for UUID representation in JSON
  created_by?: number;
  created_datetime?: string;
  updated_by?: number;
  updated_datetime?: string;
  wf_status_id: string;
  language: string;
  topic: string;
  answer_date?: Date;
  customer_code?: string;
  contact_name?: string;
  contact_email?: string;
  issuer_code?: string;
  topic_date: string;
  product_name: string;
  salesgroup_code: string;
}
export interface SurveyLineResponse {
  id: number;
  survey_id: string;
  qt_id: number;
  qt_topic: string;
  qt_choice: string;
  qt_score: string;
  qt_comment: string;
  template_id: number;
  ref_doc_type: string;
  qt_total_score: number;

  created_by: number;
  created_datetime: Date;
  updated_by: number;
  updated_datetime: Date;
  language: string;
  wf_status_id: string;
  answer_date: Date;
}

const handleResponseError = (error: any) => {
  //? = protect data no value
  if (error?.response?.data?.message) {
    console.warn("Error : " + error?.response?.data?.message);
  }
  console.warn(error);
};

export const requestCustsatGetSurveyLines = async (
  conditions: object = {},
  accessToken: string,
  ref_doc_id: string
): Promise<SurveyLineResponse[]> => {
  const data: SurveyLineResponse[] = await axios
    .get(
      `${REACT_APP_CUSTSATS_API_URL}/results?ref_doc_id=${ref_doc_id}&access_token=${accessToken}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => response.data)
    .catch((error) => handleResponseError(error));
  // const data:SurveyLineResponse[] = await axios
  // .get(`${REACT_APP_CUSTSATS_API_URL}/results?ref_doc_id=${ref_doc_id}&access_token=${accessToken}`)
  // .then(response => response.data)
  // .catch(error => handleResponseError(error))

  return data;
};
