import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../../domains/Dashboard';
import ShowCustomers from '../../domains/Customers/List';
// import ShowCustomers from '../page/customer/ShowCustomers';
import ShowProducts from '../../domains/Products/List';
import ShowCompanys from '../../domains/Companys/List';
import ShowCars from '../../domains/Cars/List';
import ShowCarDetail from '../../domains/Cars/Detail';
import ShowEmployees from '../../domains/Employees/List';
import DomainsCarsAddEdit from '../../domains/Cars/AddEdit'
import PrivateRoute from '../PrivateRoute';

import DomainSiglesignon from '../../domains/Singlesignon';
import DomainsActionToken from '../../domains/Cars/ActionToken';
import DomainsAdminLogin from '../../domains/Admin/Login';

const routes = [
  {
    path: '/',
    component: Dashboard,
    key: '/',
    private: false
  },
  {
    path: '/customers',
    component: ShowCustomers,
    key: '/customers',
    private: true
  },
  {
    path: '/products',
    component: ShowProducts,
    key: '/products',
    private: true
  },
  {
    path: '/companys',
    component: ShowCompanys,
    key: '/companys',
    private: true
  },
  {
    path: '/employees',
    component: ShowEmployees,
    key: '/employees',
    private: true
  },
  {
    path: '/cars',
    component: ShowCars,
    key: '/cars',
    private: true
  },
  {
    path: '/cars/actiontoken/:actiontoken',
    component: DomainsActionToken,
    key: '/cars/actiontoken/:actiontoken',
    private: true
  },
  {
    path: '/cars/detail/:id',
    component: ShowCarDetail,
    key: '/cars/detail/:id',
    private: true
  },
  {
    path: '/cars/add-edit/:id?/:wf_status_id?',
    component: DomainsCarsAddEdit,
    key: '/cars/add-edit/:id?/:wf_status_id?',
    private: true
  },
  {
    path: '/cars/actiontoken/:token?',
    component: DomainsActionToken,
    key: '/cars/actiontoken/:token?',
    private: false
  },
  {
    path: '/admin/login',
    component: DomainsAdminLogin,
    key: '/admin/login',
    private: false
  },
  
];
//function RoutingList(): JSX.Element {
 
const RoutingList = () => {
  return <>
  {
    routes.map(item => {
      if (item.path.split('/').length === 2) {
        if(item.private === true){
          return (
            <PrivateRoute
              exact
              path={item.path}
              component={item.component}
              key={item.key}
            />
          );
        }else{
          return (
            <Route
              exact
              path={item.path}
              component={item.component}
              key={item.key}
            />
          );
        }
      }
      return <Route path={item.path} component={item.component} key={item.key} />;
    })
  }
  </>
}

export default RoutingList;
