import React, { useEffect, useState } from "react";

import "./SurveyLineList.css";
import { SurveyLineResponse } from "../../hooks/custsat";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Radio, Space, Table } from "antd";
import { EditOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import TextArea from "rc-textarea";
import { format } from "date-fns";

type SurveyLineListProps = {
  surveylines: any;
  add: any;
  remove: any;
};

const SurveyLineList = ({ surveylines, add, remove }: SurveyLineListProps) => {
  //const { users, add, remove } = props;
  const [editingIndex, setEditingIndex] = useState(undefined);
  console.log("SurveyLineList");
  console.log(surveylines);
  const columns = [
    {
      title: "No.",
      dataIndex: "",
      key: "index",
      render: (text: any, record: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: "Topic",
      dataIndex: "qt_topic",
      key: "qt_topic",
    },
    {
      title: "Satisfactions",
      dataIndex: "qt_score",
      key: "qt_score",
      render: (text: any, record: any, index: any) => (
        <>
          <Radio.Group style={{ width: "100%" }} defaultValue={text}>
            <Radio value={1} disabled={text === 2 || text === 0}>
              Satisfied
            </Radio>
            <Radio value={2} disabled={text === 1 || text === 0}>
              Dissatisfied​
            </Radio>
          </Radio.Group>
        </>
      ),
    },
    {
      title: "Comment",
      dataIndex: "qt_comment",
      key: "qt_comment",
    },
    // {
    //   title: "Survey Date",
    //   dataIndex: "created_datetime",
    //   key: "created_datetime",
    //   render: (text: any, record: any, index: any) => (
    //     // {text == "0001-01-01" ? (
    //     //   <span>{format(new Date(text), "yyyy-MM-dd")}</span>
    //     // ):(
    //     //   <span>No send</span>
    //     // )}
    //     <span>No</span>
    //   ),
    // },
    // {
    //   title: "Answer Date",
    //   dataIndex: "answer_date",
    //   key: "answer_date",
    //   render: (text: any, record: any, index: any) => (
    //     <span>{format(new Date(text), "yyyy-MM-dd")}</span>
    //   ),
    // },
  ];
  return (
    <div>
      <br />
      <div></div>
      <br />
      <Table dataSource={surveylines} columns={columns} pagination={false} />
      <br />
      {/* <Table dataSource={surveylines} pagination={false}>
        <Column
          title="No."
          key="index"
          align={"center"}
          render={(value, item, index) => 1 + index}
        />
        <Column
          dataIndex={"qt_topic"}
          title={"Topic"}
          align={"center"}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, "qt_topic"]}>
                <TextArea
                  disabled={true}
                  rows={4}
                  value={value}
                  placeholder="Topic"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            );
          }}
        />
        <Column
          dataIndex={"qt_score"}
          title={"Satisfactions"}
          align={"center"}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, "qt_score"]}>
                <Radio.Group
                  disabled={true}
                  style={{ width: "100%" }}
                  defaultValue={1}
                >
                  <Radio value={1}>Satisfied</Radio>
                  <Radio value={2}>Dissatisfied​</Radio>
                </Radio.Group>
              </Form.Item>
            );
          }}
        />
        <Column
          dataIndex={"qt_comment"}
          title={"Additional comment"}
          render={(value, row, index) => {
            return (
              <Form.Item name={[index, "qt_comment"]}>
                <TextArea
                  disabled={true}
                  rows={4}
                  style={{ width: "100%" }}
                  placeholder=""
                />
              </Form.Item>
            );
          }}
        />
      </Table> */}
    </div>
  );
};

export default SurveyLineList;
