import { useContext, useEffect ,useRef,useState} from 'react'
import { Row, Col, Typography, Button, Divider, Form, Input, message, Select, Modal, Spin } from 'antd'
import { Link, useParams, useHistory } from 'react-router-dom'

import { Car, requestCarById, requestUpdateStatusCar } from '../../../hooks/car'
import UserContext from '../../../contexts/UserContext';
import { DatePicker, Space } from 'antd';
import CarStatusNavbar from '../../../components/CarStatusNavbar';
import CarDetail from '../../../components/CarDetail';
import CheckCircleTwoTone from '@ant-design/icons/lib/icons/CheckCircleTwoTone';
import DriveUpload from '../../../components/DriveUpload';
import ReactToPrint from 'react-to-print';

const {REACT_APP_DRIVE_NAME} = process.env

const { Title } = Typography
const { TextArea } = Input
const { Option } = Select;

interface DomainsCarsAddEditParams {
    id?: string
}

const DomainsCarsDetail = () => {
    const history = useHistory()
    const { id }: DomainsCarsAddEditParams = useParams()
    const { accessToken } = useContext(UserContext)
    const [formCreateEdit] = Form.useForm() //react hook form
    const [car, setCar] = useState<Car>()
    const [cardocId, setCarDocId] = useState("");
    const [visibleSuccess, setVisibleSuccess] = useState(false);
    

    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal')

    const componentRef = useRef<HTMLDivElement>(null)

    const handleSuccess = () => {
        setVisibleSuccess(false)
        return history.push(`/cars`)
        //return history.push(`/cars/add-edit/${newId}`)
    }
    const showModal = () => {
        setVisible(true);
      };

      const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
        setLoading(false);
        
    };
     
      const getUpdateStatus = async (_status_code: number,doc: Car) => {

        doc.doc_status_id = _status_code
    
        setLoading(true)
        const data = await requestUpdateStatusCar(doc, accessToken)
        console.log(data)
        //setCar(data)
        setLoading(false)
        setCarDocId(doc.car_doc_id)
        return history.push("/cars")
    }

    const getCarById = async (id: string) => {
        setLoading(true)
        const data = await requestCarById(id, accessToken)
        console.log(data)
        setCar(data)
        setLoading(false)
    }

    useEffect(() => {
        if (id) {
            getCarById(id)
        }
        // eslint-disable-next-line
    }, [id])


    const handleChangeProduct = async () => {

    }

    const handleChangeClaimdate = async () => {

    }
    const onDeleteItem = async(id: string) => {
        // const deleteConfirmed = window.confirm('Do you wanna delete this item?')
        // if(deleteConfirmed){
        //     await requestArticleById(id,accessToken)
        //     message.success('The article has been deleted')
        //     setNeedLoadArticles(true)
        // }
    }

    return (
        <>
       
            <Row justify="space-between" align="middle">
                <Col span={12} >
                <Link to={"/cars"}>Back</Link>    
                </Col>
                <Col span={12} className="text-right">
                    {/* <Button>Print</Button>&nbsp; */}
                    <ReactToPrint
                    trigger={() => <Button color="primary">Print</Button>}
                    content={() => componentRef.current} />
                <Button type="default"><Link to={`/cars/add-edit/${id}`}>Edit</Link></Button> &nbsp;
            {/* <Button type="default" onClick={() => onDeleteItem("id")}>Delete</Button>  */}
                    
                </Col>
            </Row>
            <Divider />
        
            {/* <CarStatusNavbar status_code={car?.doc_status_id!} status_name={car?.doc_status_name!}/> */}
         
            <Spin tip="Loading..." spinning={loading}>
                    {/* <Alert
                        message="Alert message title"
                        description="Further details about the context of this alert."
                        type="info"
                    /> */}
                </Spin>
            {car ? (

                <>
                {/* <ReactToPrint
                    trigger={() => <Button color="primary">Print</Button>}
                    content={() => componentRef.current} /> */}
                    <div ref={componentRef} style={{marginLeft:"2px"}}>
                       <CarDetail key={car.car_doc_id} car={car} />
                    </div>
                       
                    </>
                   

                ) : ("")}

            
            <Divider />
                        <Title level={5}>Attached File</Title>
            <DriveUpload closed={true} ref_doc_id={car?.car_doc_id}  page={"detail"} drive_name={REACT_APP_DRIVE_NAME}/>
          
           {/* <Row><Col span={12}></Col>
           <Col span={12} className="text-right">
                 
                   {car?.doc_status_id === 1 && (
                       <Button onClick={() => showModal()}>Update to Submit</Button>
                   )}
               </Col>
           </Row> */}
           <Modal
                title="Confirm"
                visible={visible}
                onOk={() => getUpdateStatus(2,car!)}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>Are you sure to submit ?</p>
              
                <Space size="middle" style={{display: loading? "block": "none"}}>
                <Spin size="large"/>Creating...
                </Space>
             
            </Modal>
           <Modal
                title="Success"
                visible={visibleSuccess}
                onOk={handleSuccess}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                  <Space size="middle">
                   
                    <p style={{ fontSize: '24px' }}> <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '50px' }} /> CAR No. {cardocId} updated to submit</p>
                </Space>
               
            </Modal>

         
        </>
    )
}

export default DomainsCarsDetail



