import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Approverejectstatus {
    id: number
    ap_status_id: string
    ap_status_name: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestApproverejectstatus = async(conditions: object = {},accessToken: string): Promise<Approverejectstatus[]> => {
    // const data:Docstatus[] = await axios
    // .get(`${REACT_APP_MASTERDATAS_API_URL}/countrys/all?access_token=${accessToken}`,{
    //     // headers: {
    //     //     Authorization: `Bearer ${accessToken}`
    //     // }
    // })
    // .then(response => response.data)
    // .catch(error => handleResponseError(error))
    let data:Approverejectstatus[] = []
    data.push({
        id: 1,
        ap_status_id: "Approve",
        ap_status_name: "Approve"
    })
    data.push({
        id: 2,
        ap_status_id: "Reject",
        ap_status_name: "Reject"
    })
    
   

    return data
}

