import { useContext, useState, useEffect } from 'react'
import { Row, Col, Space, Button, message, Upload, Progress } from 'antd'
import { Link } from 'react-router-dom'
import { Car, requestCarById } from '../../hooks/car'
// import {requestDeleteCarById} from '../../hooks/car'
import UserContext from '../../contexts/UserContext'
import './DriveUpload.css'
import CarContext from '../../contexts/CarContext'
import { Drive, requestGetFileByRefDocId, requestUploadFile } from '../../hooks/drive'
import DriveItem from '../DriveItem'
import DriveList from '../DriveList'
import { requestGetCarDrivesByCarId } from '../../hooks/cardrive'

let driveData: Drive[] = []

type CarItemProps = {
    ref_doc_id: string | undefined
    page: string | undefined
    drive_name: string | undefined
    closed: boolean | false
   
}


const DriveUpload = ({ ref_doc_id, page, drive_name,closed }: CarItemProps) => {

    const { accessToken, setDrives, drives } = useContext(UserContext)
    const { setNeedLoadCars } = useContext(CarContext)
    //Drive Api
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [progress, setProgress] = useState(0);

    

    const uploadFile = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: (event: any) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                setProgress(percent);
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
            }
        };
        fmData.append("file_input", file);
        fmData.append("drive_name", drive_name!)
        if (ref_doc_id !== "") {
            fmData.append("ref_doc_id", ref_doc_id!)
        } else {
            fmData.append("ref_doc_id", "")
        }


        try {
            const dataResult = await requestUploadFile(accessToken, fmData, config)

            dataResult.map((d) => {
                driveData.push({
                    id: d.id,
                    file_id: d.file_id,
                    orig_file_name: d.orig_file_name,
                    drive_name: d.drive_name,
                    file_name: d.file_name,
                    file_type: d.file_type,
                    file_size: d.file_size,
                    file_url: d.file_url,
                    folder_id: d.folder_id,

                    created_by: d.created_by,
                    created_datetime: d.created_datetime,
                    updated_by: d.updated_by,
                    updated_datetime: d.updated_datetime,

                    ref_id: d.ref_id,
                    ref_doc_id: d.ref_doc_id
                })
            })



            setDrives(driveData);
            setDefaultFileList([]);
            onSuccess("Ok");


        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }
        if(ref_doc_id){
            GetDrives(ref_doc_id, drive_name!)
        }
       
    };

    const handleFileOnChange = (e: any) => {
        //{ file, fileList, event }
        setDefaultFileList(e.fileList);

    };

    const GetDrives = async (carDocId: string, driveName: string) => {
        const data = await requestGetFileByRefDocId(accessToken, carDocId, driveName)
        setDrives(data)

    }

    const ChangeDriveData =  (fileId: string)=>{
        driveData = driveData.filter((d)=>{
             return d.file_id !== fileId
        })
    }
    

    useEffect(() => {



        driveData = []
        setDrives([])


        // eslint-disable-next-line
    }, [])

    useEffect(() => {


        if (ref_doc_id) {

            GetDrives(ref_doc_id, drive_name!)



        }

        // eslint-disable-next-line
    }, [ref_doc_id])





    return (
        <>
            {page !== "detail" && (
                <Row>
                    <Col span={24}>
                        <Upload disabled={closed}
                            accept="*" //image/*
                            customRequest={uploadFile}
                            onChange={handleFileOnChange}
                            listType="text"
                            maxCount={3}
                            multiple
                            defaultFileList={defaultFileList}
                            showUploadList={false}

                        >
                            <Button>+ Upload</Button>
                        </Upload>
                        {progress > 0 ? <Progress percent={progress} /> : null}



                    </Col>

                </Row>
            )}

            <Row>
                <Col span={24}>

                    {drives ? (
                        <DriveList closed={closed} ChangeDriveData={ChangeDriveData} drives={drives} page={page}></DriveList>
                    ) : ""}
                </Col>
            </Row>
        </>
    )
}

export default DriveUpload