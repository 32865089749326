import { useEffect, useContext, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Input,
  Spin,
  Modal,
  Space,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import {
  requestCars,
  Car,
  requestCarsByKeyword,
  requestCarsBySearch,
  requestCarsByCountry,
  requestCarsByDepartment,
  requestCarsByStatus,
  requestCarsByCategory,
  requestCarsByStandard,
  requestCarsByIssuerCode,
  requestCarExportByYear,
} from "../../../hooks/car";
import UserContext from "../../../contexts/UserContext";
import CarList from "../../../components/CarList";
import CarContext from "../../../contexts/CarContext";
import { Select } from "antd";
import { SearchOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Carstandard, requestCarstandards } from "../../../hooks/carstandard";
import { Carcategory, requestCarcategorys } from "../../../hooks/carcategory";
import { Department, requestDepartments } from "../../../hooks/department";
import {
  Divisiondirector,
  requestDivisiondirectors,
} from "../../../hooks/divisiondirector";
import { Country, requestCountrys } from "../../../hooks/country";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

const DomainsCarsList = () => {
  const { accessToken, user } = useContext(UserContext);
  const { needLoadCars, setNeedLoadCars } = useContext(CarContext);
  const [cars, setCars] = useState<Car[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(50);

  const [loading, setLoading] = useState(false);

  const [carstandards, setCarstandards] = useState<Carstandard[] | undefined>(
    undefined
  );
  const [carcategorys, setCarcategorys] = useState<Carcategory[] | undefined>(
    undefined
  );
  const [issuerDivisionDirectors, setIssuerDivisionDirectors] = useState<
    Divisiondirector[] | undefined
  >(undefined);
  const [
    issuerDivisionDirectorsSelectedValue,
    setIssuerDivisionDirectorsSelectedValue,
  ] = useState<Divisiondirector | undefined>(undefined);
  const [standardName, SetStandardName] = useState("");
  const [categoryName, SetCategoryName] = useState("");
  const [statusName, SetStatusName] = useState(0);
  //const [statusName, SetStatusName] = useState(0)
  const [issuerDepartments, setIssuerDepartments] = useState<
    Department[] | undefined
  >(undefined);

  const [issuerCountrys, setIssuerCountrys] = useState<Country[] | undefined>(
    undefined
  );
  const [issuerCountrysSelectedValue, setIssuerCountrysSelectedValue] =
    useState<Country | undefined>(undefined);

  const [departmentName, SetDepartmentName] = useState("");
  const [divisionDirectorName, SetDivisionDirectorName] = useState("");
  const [countryNames, SetCountryNames] = useState("");

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modelvisible, setModelVisible] = useState(false);
  const [modelloading, setModelLoading] = useState(false);
  const [modelconfirmLoading, setModelConfirmLoading] = useState(false);

  const [modelExportVisible, setModelExportVisible] = useState(false);

  const [selectExportYear, setSelectExportYear] = useState("");

  const handleModelExportCancel = () => {
    setModelExportVisible(false);
  };

  const handleModelCancel = () => {
    console.log("Clicked cancel button");
    setModelVisible(false);
    setModelLoading(false);
  };
  const handleModelOK = () => {
    alert("ok");
    setModelVisible(false);
    setModelLoading(false);
  };

  //

  const getIssuerDepartments = async () => {
    const data = await requestDepartments({}, accessToken);
    setIssuerDepartments(data);
  };

  const getCarcategorys = async () => {
    const data = await requestCarcategorys({}, accessToken);
    setCarcategorys(data);
  };
  const getCarstandards = async () => {
    const data = await requestCarstandards({}, accessToken);
    setCarstandards(data);
  };
  const getCarsByIssuerCode = async (
    _page: number,
    _pageSize: number,
    _issuerCode: string
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByIssuerCode(
        {},
        accessToken,
        _page,
        _pageSize,
        _issuerCode
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };

  const getCars = async (_page: number, _pageSize: number) => {
    try {
      setLoading(true);
      const data = await requestCars({}, accessToken, _page, _pageSize);

      console.log("data");
      console.log(data);
      setCars(data.cars);
      setTotalItems(data.totalItems);
      setTotalPages(data.totalPages);
      setCurrentPage(data.currentPage);
    } catch (err: any) {}
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };
  const getCarsByCountry = async (
    _page: number,
    _pageSize: number,
    _countryNames: string
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByCountry(
        {},
        accessToken,
        _page,
        _pageSize,
        _countryNames
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };
  const getCarsByDepartment = async (
    _page: number,
    _pageSize: number,
    _departmentName: string
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByDepartment(
        {},
        accessToken,
        _page,
        _pageSize,
        _departmentName
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };

  const getCarsByStatus = async (
    _page: number,
    _pageSize: number,
    _statusName: number
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByStatus(
        {},
        accessToken,
        _page,
        _pageSize,
        _statusName
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };
  const getCarsByCategory = async (
    _page: number,
    _pageSize: number,
    _categoryName: string
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByCategory(
        {},
        accessToken,
        _page,
        _pageSize,
        _categoryName
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };
  const getCarsByStandard = async (
    _page: number,
    _pageSize: number,
    _standardName: string
  ) => {
    try {
      setLoading(true);
      const data = await requestCarsByStandard(
        {},
        accessToken,
        _page,
        _pageSize,
        _standardName
      );

      setCars(data.cars);

      setTotalPages(data.totalPages);

      setCurrentPage(data.currentPage);

      setTotalItems(data.totalItems);

      if (data.totalPages === 1) {
        setPage(1);
      }

      setLoading(false);
    } catch (err: any) {
      setCars([]);
      setTotalItems(0);
      setTotalPages(0);
      setCurrentPage(0);
      setLoading(false);
    }
    setLoading(false);
    setModelVisible(false);
    setModelLoading(false);
  };
  useEffect(() => {
    getCars(page, pageSize);
    // if(user.car_role_id === 4){
    //     getCars(page,pageSize)

    // }else{
    //     getCarsByIssuerCode(page,pageSize,user.employee_id)
    // }

    getIssuerDivisiondirectortems();
    getCarstandards();
    getCarcategorys();
    getIssuerDepartments();
    getIssuerCountrys();
  }, []);

  // useEffect(() => {
  //     if (needLoadCars) {
  //         getCars(page,pageSize)
  //         setNeedLoadCars(false)
  //     }

  // }, [needLoadCars])

  const handleChangeSelect = async (e: any) => {
    SetStatusName(e);
  };
  const handleSelectDepartment = async (e: any) => {
    SetDepartmentName(e);
  };

  const handleChangeCarcategory = async (e: any) => {
    SetCategoryName(e);
  };
  const handleChangeCarstandard = async (e: any) => {
    SetStandardName(e);
  };
  const handleSearchButton = async () => {
    setLoading(true);
    if (countryNames !== "") {
      getCarsByCountry(page, pageSize, countryNames);
      return;
    }
    if (departmentName !== "") {
      getCarsByDepartment(page, pageSize, departmentName);
      return;
    }
    if (statusName !== 0) {
      getCarsByStatus(page, pageSize, statusName);
      return;
    }
    if (categoryName !== "") {
      getCarsByCategory(page, pageSize, categoryName);
      return;
    }

    if (standardName !== "") {
      getCarsByStandard(page, pageSize, standardName);
      return;
    }

    getCars(page, pageSize);
    // const data = await requestCarsBySearch({}, accessToken,standardName,categoryName,statusName,departmentName,divisionDirectorName,countryNames)

    // setCars(data)
  };
  const handleReloadPagination = async (_page: number, _pageSize: number) => {
    setPage(_page);
    setPageSize(_pageSize);

    if (countryNames !== "") {
      getCarsByCountry(_page, _pageSize, countryNames);
      return;
    }
    if (departmentName !== "") {
      getCarsByDepartment(page, pageSize, departmentName);
      return;
    }
    if (statusName !== 0) {
      getCarsByStatus(page, pageSize, statusName);
      return;
    }
    if (categoryName !== "") {
      getCarsByCategory(page, pageSize, categoryName);
      return;
    }

    getCars(_page, _pageSize);
  };

  //() => setModelVisible(true)
  const handleOpenModel = async (e: any) => {
    SetStandardName("");
    SetCategoryName("");
    SetStatusName(0);
    SetDepartmentName("");
    SetCountryNames("");

    setModelVisible(true);
  };
  const handleViewAll = async (e: any) => {
    SetStandardName("");
    SetCategoryName("");
    SetStatusName(0);
    SetDepartmentName("");
    SetCountryNames("");
    setPage(1);
    setPageSize(50);
    getCars(1, 50);

    //  setModelVisible(true)
  };
  const handleExportByYear = async (e: any) => {
    let dt = new Date(selectExportYear);

    await requestCarExportByYear(dt.getFullYear().toString(), accessToken);
  };
  const ShowExportByYear = () => {
    setModelExportVisible(true);
  };

  const modelExportvisible = async (e: any) => {};

  const onExportYearChange = async (e: any) => {
    setSelectExportYear(e);
  };

  const handleIssuerCode = async (e: any) => {
    SetStandardName("");
    SetCategoryName("");
    SetStatusName(0);
    SetDepartmentName("");
    SetCountryNames("");
    setPage(1);
    setPageSize(50);
    getCarsByIssuerCode(1, 50, user.employee_id);

    //  setModelVisible(true)
  };
  const handleChangeSearch = async (e: any) => {
    // if(e.toString() == ""){
    //     getCars(page,pageSize)
    //     return;
    // }
    try {
      if (e.toString() != "") {
        setLoading(true);
        const data = await requestCarsByKeyword({}, accessToken, e.toString());

        setCars(data);
        setPage(1);
        setPageSize(1000);
        setTotalItems(1000);
        setTotalPages(1);
        setCurrentPage(1);
        setLoading(false);
      }
    } catch (error: any) {}
  };

  const handleChangeIssuerDepartment = async (e: any) => {
    //formCreateEdit.setFieldsValue({ issuer_dept_name: e })
  };

  const handleChangeIssuerDivisionDirector = async (e: any) => {
    SetDivisionDirectorName(e);
  };
  const getIssuerDivisiondirectortems = async () => {
    const data = await requestDivisiondirectors({}, accessToken);
    setIssuerDivisionDirectors(data);
  };
  const handleChangeIssuerCountry = async (e: any) => {
    SetCountryNames(e.toString());
  };

  const getIssuerCountrys = async () => {
    const data = await requestCountrys({}, accessToken);
    setIssuerCountrys(data);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <Title level={5} className="no-margin">
            Corrective Action Request (CAR):
          </Title>
        </Col>
        <Col span={4} className="text-right">
          <Link to={"/cars/add-edit"}>
            <PlusCircleOutlined />
            &nbsp;Add&nbsp;New
          </Link>
        </Col>
      </Row>

      <Row>
        <Col span={24} className="text-center">
          <Search
            placeholder="CAR DOC ID"
            onSearch={handleChangeSearch}
            style={{ width: 300 }}
          />
          <Button onClick={handleOpenModel}>...</Button>
        </Col>
      </Row>
      <Divider />
      <Row style={{ marginBottom: "10px" }} className="text-right">
        <Col span={24}>
          <Button onClick={handleIssuerCode}>My Box</Button> &nbsp;
          <Button onClick={handleViewAll}>View All</Button>&nbsp;
          <Button
            onClick={ShowExportByYear}
            disabled={user.car_role_id === 4 || user.car_role_id === 6 ? false : true}
          >
            Export
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {/* <Spin tip="Loading..." spinning={loading}>
                 
                </Spin> */}
          {cars ? (
            <CarList
              cars={cars}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              page={page}
              pageSize={pageSize}
              loading={loading}
              handleReloadPagination={handleReloadPagination}
            ></CarList>
          ) : (
            "No data"
          )}
        </Col>
      </Row>

      <Modal
        title="Advance Searching"
        visible={modelvisible}
        onOk={handleSearchButton}
        confirmLoading={modelconfirmLoading}
        onCancel={handleModelCancel}
      >
        <Row>
          <Col span={24}>
            <Select
              onChange={handleChangeCarstandard}
              style={{ width: 400 }}
              allowClear={true}
              showSearch
              placeholder="Standard"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA: any, optionB: any) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {carstandards?.map((item: Carstandard, index: any) => (
                <Select.Option value={item.name} key={index}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              onChange={handleChangeCarcategory}
              allowClear={true}
              style={{ width: 400, marginTop: "10px" }}
              placeholder="Category"
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA: any, optionB: any) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {carcategorys?.map((item: Carcategory, index: any) => (
                <Select.Option value={item.name} key={index}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              showSearch
              allowClear={true}
              style={{ width: 400, marginTop: "10px" }}
              placeholder="Status"
              optionFilterProp="children"
              onChange={handleChangeSelect}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="1">Open</Option>
              <Option value="2">Resolved</Option>
              <Option value="3">Closed</Option>
              <Option value="0">-</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Select
              onChange={handleSelectDepartment}
              allowClear={true}
              style={{ width: 400, marginTop: "10px" }}
              showSearch
              placeholder="Issuer's Department"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA: any, optionB: any) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {issuerDepartments?.map((item: Department, index: any) => (
                <Select.Option value={item.department_name} key={index}>
                  {item.department_name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/* <Row>
                        <Col span={24}>
                        <Select defaultValue={issuerDivisionDirectorsSelectedValue?.divisiondirector_name} onChange={handleChangeIssuerDivisionDirector}
                                        showSearch
                                        placeholder="Division Director"
                                        style={{ width: 400,marginTop:"10px" }}
                                        optionFilterProp="children"
                                        filterOption={(input: any, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA: any, optionB: any) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }>
                                        {issuerDivisionDirectors?.map((item: Divisiondirector, index: any) => <Select.Option value={item.divisiondirector_name} key={index}>{item.divisiondirector_name}</Select.Option>)}
                                    </Select>
                        </Col>
                    </Row> */}
        <Row>
          <Col span={24}>
            <Select
              defaultValue={issuerCountrysSelectedValue?.country_name}
              onChange={handleChangeIssuerCountry}
              showSearch
              allowClear={true}
              optionFilterProp="children"
              placeholder="Issuer's Country"
              style={{ width: 400, marginTop: "10px" }}
              mode="multiple"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA: any, optionB: any) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {issuerCountrys?.map((item: Country, index: any) => (
                <Select.Option value={item.country_name} key={index}>
                  {item.country_name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={24} className="text-center">
            <Space
              size="middle"
              style={{ display: loading ? "block" : "none" }}
            >
              <Spin size="large" />
              Searching...
            </Space>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Export By Year"
        visible={modelExportVisible}
        onOk={handleExportByYear}
        //confirmLoading={modelconfirmLoading}
        onCancel={handleModelExportCancel}
      >
        <Row>
          <Col span={24}>
            Year:{" "}
            <DatePicker
              onChange={onExportYearChange}
              picker="year"
              mode="year"
              format={"YYYY"}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default DomainsCarsList;
