import React, { useState, createContext } from 'react'

type CarContextProps = {
    needLoadCars: boolean
    setNeedLoadCars: React.Dispatch<React.SetStateAction<boolean>>
    currentStep: number
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>

}

const CarContext = createContext({} as CarContextProps)

export const CarProvider: React.FC = (props) => {

    const [needLoadCars, setNeedLoadCars] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<number>(0)
    return (
        <CarContext.Provider
            value={
                {
                    needLoadCars,
                    setNeedLoadCars,
                    currentStep,
                    setCurrentStep
                }
            }
        >
            {props.children}
        </CarContext.Provider>
    )
}

export default CarContext