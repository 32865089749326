import axios from 'axios'
import { LoginResponse } from '../types/hooks/auth'
import { Drive } from './drive'
const { REACT_APP_CARS_API_URL } = process.env
axios.defaults.timeout = 300000
export interface CarStatusProp {
    status_code: number
    status_name: string
}

export interface Car {
    id: number

    //Issuer
    car_doc_id: string
    doc_status_id: number  //1.Open 2.Resolved 3.Closed
    doc_status_name: string
    wf_status_id: string
    //S1 S2 S3
    //S4 S5 S6 S7
    //S8 S9
    wf_status_name: string

    car_standard_id: number
    car_category_id: number
    car_type_id: number

    issuer_id: number

    issuer_company_id: number
    issuer_div_dir_id: number
    issuer_div_id: number
    issuer_dept_id: number
    issuer_country_id: number


    //approve dept
    issuer_dept_mgr_id: number   //mgr = manager
    issuer_dept_mgr_comment: string
    issuer_dept_mgr_comment_date: string
    issuer_dept_mgr_approve: number  //1 approve 2 reject
    //approve div
    issuer_div_mgr_id: number    //dtr = director
    issuer_div_mgr_comment: string
    issuer_div_mgr_comment_date: string
    issuer_div_mgr_approve: number  //1 approve 2 reject

    //Part 1
    cust_company_id: number
    cust_contact_id: number
    cust_class_id: number
    cust_country_id: number
    cust_contact_email: string
    cust_contact_tel: string


    //Part 2
    prob_ref_doc: string
    prob_product_name: string
    prob_receive_date: string
    prob_topic: string
    prob_detail: string
    prob_type_id: number
    prob_type_other: string
    prob_claim_amount: number
    prob_claim_currency_id: number
    prob_response_detail: string
    prob_response_date: string

    //Part 3
    pre_just_doc_id: string
    pre_just_doc_other: string
    pre_invest_date: string
    pre_dept_mgr_id: number



    //Part 4
    resolver_id: number
    resolver_company_id: number
    resolver_div_dir_id: number
    resolver_div_id: number
    resolver_dept_id: number
    resolver_country_id: number


    //Part 5
    sup_company_id: number
    sup_class_id: number
    sup_settle_detail: string
    sup_settle_amount: number
    sup_settle_currency_id: number

    //Part 6
    cause_human: string
    cause_material: string
    cause_machine: string
    cause_process: string
    cause_notperformed: string
    cause_skilltrain: string
    cause_other: string


    //Part 7
    rsv_implement_detail: string  //rsv = resolver
    //approve dept
    rsv_dept_mgr_comment: string
    rsv_dept_mgr_id: number   //mgr = manager

    rsv_dept_mgr_comment_date: string
    rsv_dept_mgr_approve: number  //1 approve 2 reject
    //approve div
    rsv_div_mgr_id: number    //dtr = director
    rsv_div_mgr_comment: string
    rsv_div_mgr_comment_date: string
    rsv_div_mgr_approve: number  //1 approve 2 reject

    //approve QMR
    rsv_qmr_id: number    //dtr = director
    rsv_qmr_comment: string
    rsv_qmr_verify_date: string
    rsv_qmr_verify: number  //1 verified 




    //Part 8 
    auditor_id: number
    //approve auditor
    auditor_within_date: string
    auditor_audit_closed: number //1 closed  //2 no closed
    auditor_audit_date: string
    auditor_audit_comment: string
    auditor_adequate_status: number
    //Part 9
    cust_rsv_approve_detail: string
    cust_rsv_approve_date: string
    cust_rsv_finish_detail: string
    cust_rsv_finish_date: string

    //Part 10
    custsat_detail: string
    custsat_comment: string
    custsat_survey_id: number


    //created&updatedby
    created_by: number
    created_datetime: string
    updated_by: number
    updated_datetime: string



    //close
    close_qmr_id: number
    close_qmr_comment: string
    close_qmr_date: string

    close_qmr_closed: number

    //workflow
    wf_approve_reject: number  //1=approve 2=reject 

    //Use for drive api
    drives: Drive[]


    //Additional
    car_standard_name: string,
    car_category_name: string,
    car_type_name: string,
    issuer_code: string,
    issuer_company_name: string,
    issuer_div_dir_name: string,
    issuer_div_name: string,
    issuer_dept_name: string,
    issuer_country_name: string,
    issuer_dept_mgr_code: string,
    issuer_div_mgr_code: string,
    cust_company_code: string,
    cust_contact_name: string,
    cust_class_name: string,
    cust_country_name: string,
    prob_type_name: string,
    prob_claim_currency_name: string,
    pre_dept_mgr_code: string,
    resolver_code: string,
    resolver_company_name: string,
    resolver_div_dir_name: string,
    resolver_div_name: string,
    resolver_dept_name: string,
    resolver_country_name: string,
    sup_company_code: string,
    sup_class_name: string,
    sup_settle_currency_name: string,
    rsv_dept_mgr_code: string,
    rsv_div_mgr_code: string,
    rsv_qmr_code: string,
    auditor_code: string,
    close_qmr_code: string,
    cust_company_name: string,

    issuer_name: string,

    nc_type_name: string,
    nc_subtype_name:string,
    root_cause_error_name:string,
    repeated_case:number
  
}
export interface CarPagination {
    cars: Car[]
    totalItems: number,
    totalPages: number,
    currentPage: number,
}

export interface Actiontoken {
    action_refid: number
    action_docid: string
    action_subject: string
    action_body: string
    action_id: string
    action_email: string

    created_by: number
    created_datetime: string
    updated_by: number
    updated_datetime: string

    sender_email: string
    sent_id: string
    action_url: string
    token: string
    expires: number

    approvereject_id: number
    email_cc: string

    wf_status_id: string
    wf_status_name: string
}

export interface CarApproverRequest {
    id: number
    token: string
    cardoc_id: string
    carapprover_comment: string
    carapprover_id: number
    status_code: number
    status_name: string
    approvereject_id: number   //1 approve 2 reject
}
const handleResponseError = (error: any) => {

    //? = protect data no value
    if (error?.response?.data?.message) {
        console.warn("Error : " + error?.response?.data?.message)
    }
    console.warn(error)
}
export const requestWorkflowUpdateById = async (doc: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/workflow/${doc.id}?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateById = async (doc: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/${doc.id}?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateAllById = async (doc: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/updateall/${doc.id}?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateWorkflowById = async (carId: string,workflowStatusId: string, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/updateworkflowbyqmr/${carId}/${workflowStatusId}?access_token=` + accessToken, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateRepeatedCaseById = async (carId: string,carData: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/updaterepeatedcasebyqmr/${carId}?access_token=` + accessToken, JSON.stringify(carData),{
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateRootCauseById = async (carId: string,carData: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .put(`${REACT_APP_CARS_API_URL}/cars/updaterootcausebyqmr/${carId}?access_token=` + accessToken, JSON.stringify(carData),{
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestCreateCar = async (doc: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .post(`${REACT_APP_CARS_API_URL}/cars?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestUpdateStatusCar = async (doc: Car, accessToken: string): Promise<Car> => {

    const data: Car = await axios
        .post(`${REACT_APP_CARS_API_URL}/cars/updatestatus?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestCarApprover = async (doc: CarApproverRequest, accessToken: string): Promise<Boolean> => {

    const data: Boolean = await axios
        .post(`${REACT_APP_CARS_API_URL}/cars/carapprover?access_token=` + accessToken, JSON.stringify(doc), {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }

        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}
export const requestCars = async (conditions: object = {}, accessToken: string, page: number, size: number): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/pagination/${page}/${size}?access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByCountry = async (conditions: object = {}, accessToken: string, page: number, size: number, countryNames: string): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbycountry/${page}/${size}?country_names=${countryNames}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByIssuerCode = async (conditions: object = {}, accessToken: string, page: number, size: number, issuerCode: string): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbyissuer/${page}/${size}?issuer_code=${issuerCode}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByDepartment = async (conditions: object = {}, accessToken: string, page: number, size: number,departmentName: string): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbydepartment/${page}/${size}?department_name=${departmentName}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByStatus = async (conditions: object = {}, accessToken: string, page: number, size: number,statusName: number): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbystatus/${page}/${size}?status_id=${statusName}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByCategory = async (conditions: object = {}, accessToken: string, page: number, size: number,categoryName: string): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbycategory/${page}/${size}?category_name=${categoryName}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsByStandard= async (conditions: object = {}, accessToken: string, page: number, size: number,standardName: string): Promise<CarPagination> => {

    const data: CarPagination = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/paginationbystandard/${page}/${size}?standard_name=${standardName}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}
export const requestCarsBySearch = async (conditions: object = {}, accessToken: string,
    standardName: string, catgoryName: string, statusName: number,
    departmentName: string, divisionDirectorName: string, country_names: string): Promise<Car[]> => {

    const data: Car[] = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/search?standard_name=${standardName}&category_name=${catgoryName}&status_id=${statusName}&department_name=${departmentName}&division_director_name=${divisionDirectorName}&country_names=${country_names}&access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}

export const requestCarsByKeyword = async (conditions: object = {}, accessToken: string, keyword: string): Promise<Car[]> => {

    const data: Car[] = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/keyword/${keyword}?access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => (
            response.data
        )
        )
        .catch(error => (
            handleResponseError(error)

        ))
    return data
}

export const requestCarById = async (id: string, accessToken: string): Promise<Car> => {
    const data: Car = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/${id}?access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => response.data)
        .catch(error => handleResponseError(error))

    return data
}

export const requestCarActionTokenByToken = async (token: string, accessToken: string): Promise<Actiontoken> => {
    const data: Actiontoken = await axios
        .get(`${REACT_APP_CARS_API_URL}/cars/actiontokens/token/${token}?access_token=${accessToken}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(response => {

            return response.data
        })
        .catch(error => {
            handleResponseError(error)
            return null

        })
    return data

}

export const requestCarExportByYear = async (yr: string, accessToken: string): Promise<any> => {
    // const data: any = await axios
    //     .get(`${REACT_APP_CARS_API_URL}/cars/exportbyyear/${yr}?access_token=${accessToken}`, {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`
    //         }
    //     })
    //     .then(response => response.data)
    //     .catch(error => handleResponseError(error))


        window.open(`${REACT_APP_CARS_API_URL}/cars/exportbyyear/${yr}?access_token=${accessToken}`)
    //return data
    
}
// export const requestUpdateArticleById = async (id: string,doc: Article,accessToken: string): Promise<boolean> =>{
//     const data:Article = await axios
//     .patch(`http://localhost:4000/articles/${id}`,doc,{
//         headers: {
//             Authorization: `Bearer ${accessToken}`
//         }
//     })
//     .then(response => response.data)
//     .catch(error => handleResponseError(error))

//     return true
// }

// export const requestDeleteArticleById = async (id: string,accessToken: string): Promise<boolean> =>{
//     const data:Article = await axios
//     .delete(`http://localhost:4000/articles/${id}`,{
//         headers: {
//             Authorization: `Bearer ${accessToken}`
//         }
//     })
//     .then(response => response.data)
//     .catch(error => handleResponseError(error))

//     return true
// }

