import { useHistory, useParams } from "react-router-dom"
import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Car, requestCarActionTokenByToken } from "../../../hooks/car"
import UserContext from '../../../contexts/UserContext';
import { Alert, Col, Divider, Row, Spin } from "antd";
import CarList from "../../../components/CarList";
import CarDetail from "../../../components/CarDetail";
import CarApprover from "../../../components/CarApprover";

interface DomainsActionTokenParams {
    actiontoken?: string
}
const DomainsActionToken = () => {
    const { actiontoken }: DomainsActionTokenParams = useParams()
    const { accessToken, user } = useContext(UserContext)
    const [car, setCar] = useState<Car>()
    const [loading, setLoading] = useState(false)

    const history = useHistory()



    const GetActionTokenByToken = async (_actiontoken: string) => {

        setLoading(true)
        const data = await requestCarActionTokenByToken(_actiontoken, accessToken)
      
        setLoading(false)
        console.log("data")
        console.log(data)
        console.log(data.wf_status_id)
        //Redirect to edit
        return history.push(`/cars/add-edit/${data.action_refid}/${data.wf_status_id}`)

    }
    useEffect(() => {
        if (actiontoken) {
            GetActionTokenByToken(actiontoken)
        }
        // eslint-disable-next-line
    }, [actiontoken])
    return (

        <>
            <Row>
                <Col span={24}>
                    <Spin tip="Loading..." spinning={loading}>
                        {/* <Alert
                        message="Alert message title"
                        description="Further details about the context of this alert."
                        type="info"
                    /> */}
                    </Spin>
                 
                   

                </Col>
            </Row>

            <Divider />
         



        </>


    )
}

export default DomainsActionToken

