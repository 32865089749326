import axios from 'axios'
import {LoginResponse} from '../types/hooks/auth'

const { REACT_APP_MASTERDATAS_API_URL } = process.env

export interface Docstatus {
    id: number
    doc_status_id: number
    doc_status_name: string
  
 
}

const handleResponseError = (error: any) => {
    //? = protect data no value
    if(error?.response?.data?.error){
        throw error.response.data.error
    }
    throw error
}

export const requestDocStatuss = async(conditions: object = {},accessToken: string): Promise<Docstatus[]> => {
    // const data:Docstatus[] = await axios
    // .get(`${REACT_APP_MASTERDATAS_API_URL}/countrys/all?access_token=${accessToken}`,{
    //     // headers: {
    //     //     Authorization: `Bearer ${accessToken}`
    //     // }
    // })
    // .then(response => response.data)
    // .catch(error => handleResponseError(error))
    let data:Docstatus[] = []
    data.push({
        id: 1,
        doc_status_id: 1,
        doc_status_name: "Open"
    })
    data.push({
        id: 2,
        doc_status_id: 2,
        doc_status_name: "Resolved"
    })
    data.push({
        id: 3,
        doc_status_id: 3,
        doc_status_name: "Closed"
    })
    data.push({
        id: 4,
        doc_status_id: 4,
        doc_status_name: "Cancel"
    })

    return data
}

