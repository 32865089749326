import axios from 'axios'
import fetch from 'node-fetch'

import { UserResponse } from '../types/hooks/user'

const { REACT_APP_OAUTH_API_URL,REACT_APP_USERS_API_URL } = process.env


const handleResponseError = (error: any) => {
    //? = protect data no value

    if (error?.response?.data?.error) {
        throw error.response.data.error
    }
    throw error
}


export const requestGetUser = async (token: string,userid: number): Promise<UserResponse> => {
    const data: UserResponse = await axios.get(`${REACT_APP_USERS_API_URL}/users/${userid}?access_token=${token}`, {
        headers: {
            'X-Public': false,
            // Authorization: `Bearer ${token}`,
        }
    })
    .then(response => response.data)
    .catch(error => handleResponseError(error))

    return data
}
