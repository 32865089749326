import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Button, Space, Divider, message, Spin } from 'antd'
import { LoginFormSubmitValues } from '../../types/domains/Login'
import { useHistory } from 'react-router'
import './Login.css'

import { requestAuthLogin, requestCreateRedirecturl, requestGetRedirecturl } from '../../hooks/auth'
import { requestGetUser } from '../../hooks/user'

import UserContext from '../../contexts/UserContext'
import { UserResponse } from '../../types/hooks/user'
import { useParams, useLocation } from 'react-router-dom'

import { useCookies } from 'react-cookie';
import { requestGetCookieToJwtdata } from '../../hooks/oauth'
import { requestEmployeeByEmployeeId } from '../../hooks/employee'




interface DomainsActionTokenParams {
    actiontoken?: string
}
const { REACT_APP_LOCAL_URL, REACT_APP_ACCESS_TOKEN_COOKIE, REACT_APP_USER_ID_COOKIE, REACT_APP_DOMAIN_NAME, REACT_APP_ENV } = process.env
const DomainsLogin: React.FC = () => {

    const location = useLocation();
    const [loading, setLoading] = useState(false)

    const [cookies, setCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE!]);

    const { actiontoken }: DomainsActionTokenParams = useParams()
    const history = useHistory()

    const { isLoggedIn, setIsLoggedIn, setAccessToken, setUser, setEmp } = useContext(UserContext)

    const [formLogin] = Form.useForm()

    const handleSubmitForm = async (values: LoginFormSubmitValues) => {
        try {
            const { username, password } = values
            const data = await requestAuthLogin(username, password)
            setAccessToken(data.access_token)
            console.log("token")
            console.log(data.access_token)
            console.log("data")
            console.log(data)

            let date = new Date();
            date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));



            //    setCookie(REACT_APP_ACCESS_TOKEN_COOKIE!, data.access_token, { path: '/', expires: date })
            //    setCookie(REACT_APP_USER_ID_COOKIE!, data.user_id, { path: '/' })

            if (data.access_token) {
                const user_data = await requestGetUser(data.access_token, data.user_id)
                setUser(user_data)
                if (user_data.employee_id) {
                    const emp_data = await requestEmployeeByEmployeeId({}, data.access_token, user_data.employee_id)
                    setEmp(emp_data)
                }

            }
            setIsLoggedIn(true)
            message.success('Login Successful')
            if (actiontoken) {
                return history.push('/cars/actiontoken/' + actiontoken)
            }
            return history.push('/cars') //user/me
        } catch (error: any) {
            setAccessToken('')

            setUser({} as UserResponse)
            setIsLoggedIn(false)
            console.error(error)

            const code = error.code ?? ''
            message.error(`${error.message} ${code ? ` (${code})` : ''}`)
        }

    }

    // const checkAccessTokenCookie = async () => {
    //     let access_token = cookies[REACT_APP_ACCESS_TOKEN_COOKIE!]
    //     let user_id = cookies[REACT_APP_USER_ID_COOKIE!]



    //     if(!isLoggedIn && access_token !== ""  && user_id !== ""){
    //         const user_data = await requestGetUser(access_token, user_id)
    //         setUser(user_data)
    //         setIsLoggedIn(true)
    //         message.success('Login Successful')
    //         if (actiontoken) {
    //             return history.push('/cars/actiontoken/' + actiontoken)
    //         }
    //         return history.push('/cars') //user/me
    //     }

    // }
    useEffect(() => {
        setLoading(true)
        CreateRedirecturl()
        GetCookie()
        setLoading(false)

    }, [])

    const CreateRedirecturl = async () => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email')
        try {
            let url = `${REACT_APP_LOCAL_URL}/cars/actiontoken/${actiontoken}`
            const data = await requestCreateRedirecturl(url, emailParam);

        }
        catch (err: any) {

        }
    }
    const GetRedirectUrl = async () => {

        try {

            const data = await requestGetRedirecturl();
            if (data.url !== "") {
                return history.push(data.url)
            }

        }
        catch (err: any) {

        }
    }
    const GetCookie = async () => {
       
        try
        {
            const data = await requestGetCookieToJwtdata();
            console.log(data)
        if (data.access_token) {
            localStorage.setItem(REACT_APP_ACCESS_TOKEN_COOKIE!, JSON.stringify(data.access_token))
            localStorage.setItem(REACT_APP_USER_ID_COOKIE!, JSON.stringify(data.user_id))
            let access_token: string = data.access_token
            let user_id: number = data.user_id
            setAccessToken(data.access_token)

            if (!isLoggedIn && access_token !== "" && user_id !== 0) {
                const user_data = await requestGetUser(access_token, user_id)
                setUser(user_data)
                if (user_data.employee_id !== "") {
                    const emp_data = await requestEmployeeByEmployeeId({}, data.access_token, user_data.employee_id)
                    setEmp(emp_data)
                }
                setIsLoggedIn(true)
                message.success('Login Successful')
                if (actiontoken) {
                    return history.push('/cars/actiontoken/' + actiontoken)
                }
                //GetRedirectUrl();
                return history.push('/cars') //user/me
            }
        } else {
            if (REACT_APP_ENV === "prod") {
                //  alert("Please login on ispacezone.com before and click link on email again!")
                window.open("https://apidemo.ispacezone.com/api/v1/signon", "_self")
            }

        }
        }catch(err:any){
            console.log("error cookie"+err)
            console.log(err)
            if (REACT_APP_ENV === "prod") {
                //  alert("Please login on ispacezone.com before and click link on email again!")
                window.open("https://apidemo.ispacezone.com/api/v1/signon", "_self")
            }
            
        }
    }

    return (
        <>
            <Spin tip="Loading..." spinning={loading}>

            </Spin>

            <Form hidden={REACT_APP_ENV === "prod" ? true : false}
                className="login-form"
                form={formLogin}
                name="formLogin"
                layout="vertical"
                onFinish={handleSubmitForm}
                onReset={() => formLogin.resetFields()}
            >

                <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your username'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter your password'
                        }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item className="text-right">

                    <Space>
                        <Button type="primary" htmlType="submit">
                            Sign In
                        </Button>
                        <Button
                            htmlType="reset">
                            Reset
                        </Button>


                    </Space>
                </Form.Item>

            </Form>


        </>
    )
}

export default DomainsLogin