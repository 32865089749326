import React from 'react'
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";

import { Layout, Menu, Typography, Breadcrumb } from 'antd'
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import './App.css'
import 'antd/dist/antd.css';
import './App.scss'
import ContextProvider from '../../contexts/ContextProvider';
import Navbar from '../Navbar'
import PrivateRoute from '../PrivateRoute';

import Logo from '../../assets/logo.png'
import LogoDark from '../../assets/logo-dark.png'

import DomainsHome from '../../domains/Home/Home';
import DomainsAbout from '../../domains/About/About';
import DomainsLogin from '../../domains/Login/Login';
import DomainsLogout from '../../domains/Logout';

import DomainsArticlesList from '../../domains/Articles/List'
import DomainsArticlesAddEdit from '../../domains/Articles/AddEdit'


import { createBrowserHistory } from 'history';
import MainLayout from '../../components/MainLayout';
function App() {
  const { Header, Content, Footer, Sider } = Layout
  const { SubMenu } = Menu;
  return (
    <ContextProvider>
      <BrowserRouter>
        <Switch>
          <MainLayout />
        </Switch>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
