import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../../domains/Dashboard';
import ShowCustomers from '../../domains/Customers/List';
// import ShowCustomers from '../page/customer/ShowCustomers';
import ShowProducts from '../../domains/Products/List';
import ShowCompanys from '../../domains/Companys/List';
import ShowCars from '../../domains/Cars/List';
import ShowCarDetail from '../../domains/Cars/Detail';
import ShowEmployees from '../../domains/Employees/List';
import DomainsCarsAddEdit from '../../domains/Cars/AddEdit'
import PrivateRoute from '../PrivateRoute';

import DomainSiglesignon from '../../domains/Singlesignon';
import DomainsActionToken from '../../domains/Cars/ActionToken';
import DomainsLogin from '../../domains/Login';
import DomainsAdminLogin from '../../domains/Admin/Login';


const routes = [
   
      {
        path: '/',
        component: DomainsLogin,
        key: '/',
        private: false
      },
      {
        path: '/cars',
        component: DomainsLogin,
        key: '/cars',
        private: true
      },
      {
        path: '/cars/actiontoken/:actiontoken',
        component: DomainsLogin,
        key: '/cars/actiontoken/:actiontoken',
        private: false
      },
      {
        path: '/cars/detail/:id',
        component: DomainsLogin,
        key: '/cars/detail/:id',
        private: false
      },
      {
        path: '/login',
        component: DomainsLogin,
        key: '/login',
        private: false
      },
      {
        path: '/admin/login',
        component: DomainsAdminLogin,
        key: '/admin/login',
        private: false
      },
];
//function RoutingList(): JSX.Element {
 
const PublicRoutingList = () => {
  return <>
  {
    routes.map(item => {
      if (item.path.split('/').length === 2) {
        if(item.private === true){
          return (
            <PrivateRoute
              exact
              path={item.path}
              component={item.component}
              key={item.key}
            />
          );
        }else{
          return (
            <Route
              exact
              path={item.path}
              component={item.component}
              key={item.key}
            />
          );
        }
      }
      return <Route path={item.path} component={item.component} key={item.key} />;
    })
  }
  </>
}

export default PublicRoutingList;
